import { FC } from 'react';

import { PALETTE } from '@/util/palette';

import {
  AboveText,
  Ball,
  BottomText,
  Line,
  Step,
  TextInBall,
  WrapperStep,
  WrapperText
} from './styles';

interface StepperProps {
  numberStep: number;
}

export const Stepper: FC<StepperProps> = ({ numberStep }) => {
  const aboveWord = 'Create new Employee';

  const changeColor = (currentStep: number) => {
    return numberStep === currentStep ? PALETTE.link : PALETTE.grey;
  };

  return (
    <WrapperStep>
      <Step>
        <Ball isGray={changeColor(1)}>
          <TextInBall>1</TextInBall>
        </Ball>
        <WrapperText>
          <AboveText isGray={changeColor(1)}>{aboveWord}</AboveText>
          <BottomText isGray={changeColor(1)}>Base Info</BottomText>
        </WrapperText>
      </Step>
      <Line />
      <Step>
        <Ball isGray={changeColor(2)}>
          <TextInBall>2</TextInBall>
        </Ball>
        <WrapperText>
          <AboveText isGray={changeColor(2)}>{aboveWord}</AboveText>
          <BottomText isGray={changeColor(2)}>Specialization</BottomText>
        </WrapperText>
      </Step>
      <Line />
      <Step>
        <Ball isGray={changeColor(3)}>
          <TextInBall>3</TextInBall>
        </Ball>
        <WrapperText>
          <AboveText isGray={changeColor(3)}>{aboveWord}</AboveText>
          <BottomText isGray={changeColor(3)}>Status & Price</BottomText>
        </WrapperText>
      </Step>
      <Line />
      <Step>
        <Ball isGray={changeColor(4)}>
          <TextInBall>4</TextInBall>
        </Ball>
        <WrapperText>
          <AboveText isGray={changeColor(4)}>{aboveWord}</AboveText>
          <BottomText isGray={changeColor(4)}>Skills & Techs</BottomText>
        </WrapperText>
      </Step>
    </WrapperStep>
  );
};

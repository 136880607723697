import { useState, FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OrFrame } from '@/assets/images/icon';
import { Button, HelperText, GoogleLoginButton } from '@/components';
import { Pages, signUpPasswordValidationSchema } from '@/constants';
import { useSignUpFormState } from '@/context/formContext';
import { useAuth } from '@/hooks';
import {
  WrapperBtnLogin,
  SignInForm,
  OrStroke,
  ButtonNextWrapper,
  AgreementWrapper,
  StyledLink
} from '@/page/(auth)/signUp/styles';
import {
  useSignUpGoogleMutation,
  useSignUpMutation
} from '@/redux/api/auth/authApi';
import { DEFAULT_COOKIE_KEY, setDefaultAuthCookieKey } from '@/service/api';

interface FormData {
  password: string;
  confirmPassword: string;
}

interface PasswordStepProps {
  _test_fn?: () => void;
}

export const PasswordStep: FC<PasswordStepProps> = ({ _test_fn }) => {
  const navigate = useNavigate();

  const { setToken } = useAuth();

  const [signUpGoogle] = useSignUpGoogleMutation();
  const [signUp] = useSignUpMutation();

  const { onHandleNext, formData } = useSignUpFormState();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(signUpPasswordValidationSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);

  const googleSignUpHandler = ({ credential }: CredentialResponse) => {
    if (credential) {
      signUpGoogle({
        idToken: credential
      })
        .then(() => {
          setDefaultAuthCookieKey();
          setToken(DEFAULT_COOKIE_KEY);
          navigate(Pages.HOME);
          _test_fn?.();
        })
        .catch((error) => {
          setError('password', { message: error?.data?.Message });
        });
    }
  };

  const onSubmit = async (data: FormData) => {
    signUp({
      email: formData.email ?? '',
      password: data.password,
      firstName: formData.firstName ?? '',
      lastName: formData.firstName ?? ''
    })
      .unwrap()
      .then(() => onHandleNext())
      .catch((error) => {
        setError('password', { message: error?.data?.Message });
        return;
      });
  };

  return (
    <SignInForm onSubmit={handleSubmit(onSubmit)}>
      <Box mt='32px' width='100%'>
        <TextField
          autoComplete='off'
          fullWidth
          label='Password'
          type={showPassword ? 'text' : 'password'}
          variant='outlined'
          {...register('password')}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {isPasswordFocused && (
                  <IconButton
                    aria-label='toggle password visibility'
                    edge='end'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
          error={!!errors.password}
          onFocus={() => setIsPasswordFocused(true)}
        />
        <HelperText text={errors.password?.message} />
      </Box>

      <Box mt='26px' width='100%'>
        <TextField
          autoComplete='off'
          fullWidth
          label='Confirm Password'
          type={showConfirmPassword ? 'text' : 'password'}
          variant='outlined'
          {...register('confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {isConfirmPasswordFocused && (
                  <IconButton
                    aria-label='toggle confirm password visibility'
                    edge='end'
                    onClick={(e) => {
                      e.preventDefault();
                      setShowConfirmPassword(!showConfirmPassword);
                    }}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffOutlinedIcon />
                    ) : (
                      <VisibilityOutlinedIcon />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            )
          }}
          error={!!errors.confirmPassword}
          onFocus={() => setIsConfirmPasswordFocused(true)}
        />
        <HelperText text={errors.confirmPassword?.message} />
      </Box>

      <AgreementWrapper>
        By clicking Create account, I agree that I have read and accepted the
        <StyledLink to={Pages.HOME}>Terms of Use</StyledLink>
        and
        <StyledLink to={Pages.HOME}>Privacy Policy</StyledLink>.
      </AgreementWrapper>
      <ButtonNextWrapper>
        <Button fullWidth size='large' text='Create an Account' type='submit' />
      </ButtonNextWrapper>
      <OrStroke>
        <OrFrame />
      </OrStroke>

      <Box mt='24px' width='100%'>
        <Button
          fullWidth
          size='large'
          text='Continue with Google'
          variant='google'
        />
        <WrapperBtnLogin>
          <GoogleLoginButton onSuccess={googleSignUpHandler} />
        </WrapperBtnLogin>
      </Box>
    </SignInForm>
  );
};

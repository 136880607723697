import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, HelperText } from '@/components';
import { emailValidationSchema, Pages } from '@/constants';
import { useSignUpFormState } from '@/context/formContext';
import { Form } from '@/page/(auth)/forgot-password/styles';
import { useResetPasswordGetMutation } from '@/redux/api/auth/authApi';

interface ForgotFormProps {
  onFormSubmit: () => void;
}

interface FormData {
  email: string;
}

export const ForgotForm: FC<ForgotFormProps> = ({ onFormSubmit }) => {
  const navigate = useNavigate();

  const [resetPasswordGet] = useResetPasswordGetMutation();
  const { onHandleNext, setFormData } = useSignUpFormState();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(emailValidationSchema)
  });

  const onSubmit = async (data: FormData) => {
    resetPasswordGet({ email: data.email })
      .unwrap()
      .then(() => {
        onHandleNext();
        setFormData((prevFormData) => ({ ...prevFormData, ...data }));
        onFormSubmit();
      })
      .catch((error) => {
        setError('email', error?.data?.Message);
        if (error?.data?.status === 404) {
          setError('email', {
            message:
              'Sorry, the email you entered is not registered in our system'
          });
        }
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box mt='32px' width='100%'>
          <TextField
            autoComplete='off'
            fullWidth
            label='Email'
            type='email'
            variant='outlined'
            {...register('email')}
            error={!!errors.email}
          />
          <HelperText text={errors.email?.message} />
        </Box>

        <Box mt='40px' width='100%'>
          <Button fullWidth size='large' text='Submit' type='submit' />
        </Box>
      </Form>

      <Box mt='24px' width='100%'>
        <Button
          fullWidth
          onClick={() => navigate(Pages.SIGN_IN)}
          size='large'
          text='Back to Sign In'
          variant='clear'
        />
      </Box>
    </>
  );
};

import { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { Logo } from '@/assets/images/icon';
import { Pages } from '@/constants';

// eslint-disable-next-line
import {
  Container,
  Description,
  DescriptionContainer,
  DescriptionLink,
  HeadBlock,
  Title,
  Wrapper
} from './styles';

interface HeaderProps {
  title?: string;
  description?: string;
  descriptionLink?: string;
  children?: ReactNode;
  link?: string;
  onDescriptionLinkClick?: () => void;
}

export const Header: FC<HeaderProps> = ({
  title,
  description,
  descriptionLink,
  children,
  link,
  onDescriptionLinkClick
}) => (
  <Wrapper>
    <Container>
      <Link to={Pages.HOME}>
        <HeadBlock>
          <Logo />
        </HeadBlock>
      </Link>
      <Title>{title}</Title>
      <DescriptionContainer>
        <Description>{description}</Description>
        {link && (
          <DescriptionLink onClick={() => onDescriptionLinkClick?.()} to={link}>
            {descriptionLink}
          </DescriptionLink>
        )}
      </DescriptionContainer>
      {children}
    </Container>
  </Wrapper>
);

import { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  CredWrapper,
  SalaryLanguage,
  Typography,
  UserAvatar
} from '@/components';
import { Pages, getPageById } from '@/constants';
import { ThemedContainer } from '@/providers';
import { useGetEmployeeByIdQuery } from '@/redux/api/employee/employeeApi';

import {
  Container,
  MainContent,
  HeadWrapper,
  HeadWrapperRight,
  Name,
  Row,
  Border,
  Description,
  HeaderCardWrapper,
  SkillsWrapper,
  SkillsOrange
} from './styles';

type DictionaryItem = {
  id: number;
  name: string;
};

export const CandidateProfilePage: FC = () => {
  const { id } = useParams();

  const { data: employeeData } = useGetEmployeeByIdQuery(id ?? '');

  const navigate = useNavigate();

  return (
    <ThemedContainer>
      <Container>
        <div>
          <Button
            onClick={() => history.back()}
            size='small'
            text='Back'
            variant='backButton'
          />
          <MainContent>
            <HeadWrapper>
              <UserAvatar
                canChange={false}
                firstName={employeeData?.firstName ?? ''}
                imageUrl={employeeData?.imageUrl}
                key='unique-key'
                lastName={employeeData?.lastName ?? ''}
                size='logo'
              />
              {employeeData && employeeData.firstName && (
                <HeadWrapperRight>
                  <Name>
                    {employeeData.firstName}&nbsp;
                    {employeeData.lastName}
                  </Name>
                  <CredWrapper
                    mainInfo={{
                      specialization:
                        employeeData.employeeSpecializations[0].name,
                      seniority: employeeData.seniority.name,
                      location: employeeData.employeeCountries[0].name
                    }}
                  />
                  <SalaryLanguage
                    mainData={{
                      price: employeeData.price,
                      language:
                        employeeData.employeeLanguageLevels[0].language.name,
                      level:
                        employeeData.employeeLanguageLevels[0].languageLevel
                          .name
                    }}
                    variant='titleSmallSemi'
                  />
                  <Row>
                    <Button
                      size='small'
                      text='Download CV'
                      variant='downloadCV'
                    />
                    <Button
                      onClick={() =>
                        navigate(getPageById(Pages.INTERVIEW_ID, id as string))
                      }
                      size='small'
                      text='Interview Request'
                      variant='interview'
                    />
                  </Row>
                </HeadWrapperRight>
              )}
            </HeadWrapper>
          </MainContent>
          <Border>
            <HeaderCardWrapper>
              <Typography variant='headlineMedium'>Skills & Techs</Typography>
            </HeaderCardWrapper>
            {employeeData && employeeData.firstName && (
              <SkillsWrapper>
                {employeeData.employeeSkills.map((skills: DictionaryItem) => (
                  <SkillsOrange key={skills.id}>
                    <p>{skills.name}</p>
                  </SkillsOrange>
                ))}
              </SkillsWrapper>
            )}
          </Border>
          {employeeData && employeeData.about && (
            <Border>
              <HeaderCardWrapper>
                <Typography variant='headlineMedium'>About</Typography>
              </HeaderCardWrapper>
              <Description>
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: employeeData.about }}
                  />
                }
              </Description>
            </Border>
          )}
        </div>
      </Container>
    </ThemedContainer>
  );
};

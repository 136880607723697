import { useEffect, useState } from 'react';

export const useInternetStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleInternetStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleInternetStatusChange);
    window.addEventListener('offline', handleInternetStatusChange);

    return () => {
      window.removeEventListener('online', handleInternetStatusChange);
      window.removeEventListener('offline', handleInternetStatusChange);
    };
  }, []);

  return isOnline;
};

import { useState, useMemo, FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import { TrashBucketIcon } from '@/assets/images/icon';
import {
  Button,
  Calendar,
  CardCandidate,
  HelperText,
  TimeRange,
  Typography
} from '@/components';
import { StorageKey, Pages } from '@/constants';
import { useDictionary } from '@/hooks';
import {
  getRequirementList,
  getTimeRanges,
  IDateTimePair
} from '@/page/interview/id/interview.helpers';
import { useGetEmployeeByIdQuery } from '@/redux/api/employee/employeeApi';
import { useCreateInterviewRequestMutation } from '@/redux/api/interview/interviewApi';
import { getPathInterview } from '@/util';

import {
  initialFormData,
  interviewDatesValidationSchema
} from './interview.config';
import {
  DateTimeWrapper,
  DateTimeControl,
  Container,
  HeadWrapper,
  Content,
  Title,
  SubTitle,
  Border,
  InterviewTextarea,
  ButtonSection,
  SkillsWrapper,
  SkillsPurple,
  InterviewTextareaWrapper
} from './styles';

interface DictionaryItem {
  id: number;
  name: string;
}

interface ILanguageItem {
  language: DictionaryItem;
  languageLevel: DictionaryItem;
}

interface FormData {
  contractDescription?: string;
  intervals: IDateTimePair[];
}

interface SkillsProps<T> {
  skills: T[];
}

const Skills = <T extends DictionaryItem>({ skills }: SkillsProps<T>) => (
  <>
    {skills.map((skill) => (
      <SkillsPurple key={skill.id}>
        <p>{skill.name}</p>
      </SkillsPurple>
    ))}
  </>
);

interface InterviewRequestProps {
  _test_fn?: () => void;
}

export const InterviewRequest: FC<InterviewRequestProps> = ({ _test_fn }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const pathOfSearch = useParams().id!;
  const [link] = useState(search);

  const { data: employeeData } = useGetEmployeeByIdQuery(pathOfSearch);
  const [createInterviewRequest] = useCreateInterviewRequestMutation();
  const { dictionaries } = useDictionary();
  const [intervals, setIntervals] = useState<IDateTimePair[]>([
    {
      selectedDate: null as unknown as Date,
      selectedTime: ''
    }
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: initialFormData,
    resolver: yupResolver(interviewDatesValidationSchema)
  });

  const paramsToRetrieve = [
    'countries',
    'specializations',
    'skillsandtechnologies',
    'seniority',
    'languages',
    'languagelevels',
    'employeestatus'
  ];

  const searchParamsObject: any = {};

  paramsToRetrieve.forEach((param) => {
    searchParamsObject[param] = searchParams.get(param);
  });

  const {
    countries,
    specializations,
    skillsandtechnologies,
    seniority,
    languages,
    languagelevels,
    employeestatus
  } = searchParamsObject;

  const skills = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.SkillsAndTechnologies && skillsandtechnologies) {
      return getRequirementList(
        skillsandtechnologies,
        dictionaries.SkillsAndTechnologies
      );
    }

    return [];
  }, [dictionaries, skillsandtechnologies]);

  const seniorityList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.Seniority && seniority) {
      return getRequirementList(seniority, dictionaries.Seniority);
    }

    return [];
  }, [dictionaries, seniority]);

  const specializationsList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.Specializations && specializations) {
      return getRequirementList(specializations, dictionaries.Specializations);
    }

    return [];
  }, [dictionaries, specializations]);

  const countriesList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.Countries && countries) {
      return getRequirementList(countries, dictionaries.Countries);
    }

    return [];
  }, [dictionaries, countries]);

  const languagesList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.Languages && languages) {
      return getRequirementList(languages, dictionaries.Languages);
    }

    return [];
  }, [dictionaries, languages]);

  const languageLevelsList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.LanguageLevels && languagelevels) {
      return getRequirementList(languagelevels, dictionaries.LanguageLevels);
    }

    return [];
  }, [dictionaries, languagelevels]);

  const employeeStatusList = useMemo<DictionaryItem[]>(() => {
    if (dictionaries.EmployeeStatus && employeestatus) {
      return getRequirementList(employeestatus, dictionaries.EmployeeStatus);
    }

    return [];
  }, [dictionaries, employeestatus]);

  const setTimeToInterval = (index: number, time: string) => {
    const list = [...intervals];
    list[index].selectedTime = time;
    setIntervals(list);
  };

  const setDateToInterval = (index: number, date: Date) => {
    const list = [...intervals];
    list[index].selectedDate = date;
    setIntervals(list);
  };

  const addTimeslot = () => {
    if (intervals.length <= 5) {
      const list = [
        ...intervals,
        {
          selectedDate: null as unknown as Date,
          selectedTime: ''
        }
      ];
      setValue('intervals', list);
      setIntervals(list);
    }
  };

  const removeTimeslot = (index: number) => {
    if (intervals.length > 1) {
      const list = [...intervals];
      list.splice(index, 1);
      setValue('intervals', list);
      setIntervals(list);
    }
  };

  const getLanguages = () => {
    const result: ILanguageItem[] = [];
    languagesList.forEach((language) => {
      languageLevelsList.forEach((level) => {
        result.push({
          language,
          languageLevel: level
        });
      });
    });

    return result;
  };

  const onSubmit = async (data: FormData) => {
    const interviewData = {
      employeeId: pathOfSearch,
      description: data.contractDescription,
      createdAt: new Date().toISOString(),
      filters: {
        seniority: seniorityList,
        employeeSkills: skills,
        employeeSpecializations: specializationsList,
        employeeCountries: countriesList,
        employeeLanguageLevels: getLanguages()
      },
      interviewRequestTimes: getTimeRanges(intervals)
    };
    createInterviewRequest(interviewData)
      .unwrap()
      .then(() => {
        localStorage.setItem(
          StorageKey.CandidateList,
          `?${decodeURIComponent(link.substring(1)!.toString())}`
        );
        navigate(Pages.INTERVIEW_SUCCESS);
      })
      .catch(() => {
        enqueueSnackbar('Oops, something went wrong', {
          variant: 'error'
        });
      });
  };

  if (getPathInterview()) {
    localStorage.removeItem(StorageKey.ToRedirect);
  }

  const constructSearchParams = (): string => {
    const paramsMap = new Map<string, string>();
    if (seniority) {
      paramsMap.set('seniority', seniority);
    }

    if (employeestatus) {
      paramsMap.set('employeestatus', employeestatus);
    }

    const handleListParameter = (
      paramName: string,
      items: DictionaryItem[]
    ): void => {
      if (items.length > 0) {
        const itemIds = items.map((item) => item.id).join(',');
        paramsMap.set(paramName, itemIds);
      }
    };

    handleListParameter('countries', countriesList);
    handleListParameter('specializations', specializationsList);
    handleListParameter('skillsandtechnologies', skills);
    handleListParameter('languages', languagesList);
    handleListParameter('languagelevels', languageLevelsList);
    handleListParameter('employeestatus', employeeStatusList);
    return Array.from(paramsMap.entries())
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
  };

  return (
    <Container>
      <Content>
        <HeadWrapper>
          <Button
            onClick={() =>
              navigate(`${Pages.CANDIDATE}?${constructSearchParams()}`)
            }
            size='small'
            text='Back'
            variant='backButton'
          />

          <Typography mb='24px' variant='displayMedium'>
            Interview Request
          </Typography>

          <Title>To Candidate</Title>
          {employeeData && employeeData.firstName && (
            <CardCandidate dataInfo={{ ...employeeData, id: +pathOfSearch }} />
          )}

          <Border>
            <Title>Requirements for a candidate</Title>
            <SkillsWrapper>
              <Skills skills={skills} />
              <Skills skills={specializationsList} />
              <Skills skills={seniorityList} />
              <Skills skills={countriesList} />
              <Skills skills={languagesList} />
              <Skills skills={languageLevelsList} />
              <Skills skills={employeeStatusList} />
            </SkillsWrapper>
          </Border>

          <Box component='form' onSubmit={handleSubmit(onSubmit)} width='100%'>
            <Border>
              <Title>Contract Description</Title>
              <SubTitle>Tell us more about your project, tasks, etc.</SubTitle>
              <InterviewTextareaWrapper>
                <Controller
                  control={control}
                  name='contractDescription'
                  render={({ field }) => (
                    <>
                      <InterviewTextarea
                        $error={!!errors['contractDescription']}
                        data-testid='interview-textbox'
                        maxRows={20}
                        minRows={3}
                        name='description'
                        onBlur={field.onBlur}
                        onChange={(e) => field.onChange(e.target.value)}
                        placeholder='Add Description'
                      />

                      <HelperText
                        text={errors['contractDescription']?.message}
                      />
                    </>
                  )}
                />
              </InterviewTextareaWrapper>
            </Border>

            <Border>
              <Title>Propose data & time to interview call</Title>
              <SubTitle>
                The proposed time will be reviewed by the candidate and you will
                receive a confirmation email
              </SubTitle>
              <DateTimeControl>
                {intervals.map((element, index) => (
                  <DateTimeWrapper key={index + 'datetime'}>
                    <Box
                      alignItems='flex-start'
                      display='flex'
                      gap='16px'
                      justifyContent='space-between'
                    >
                      <Box>
                        <Calendar
                          control={control}
                          errors={!!errors['intervals']?.[index]?.selectedDate}
                          label='Select date'
                          name={`intervals[${index}].selectedDate`}
                          setValue={(date: Date) =>
                            setDateToInterval(index, date)
                          }
                          value={(element.selectedDate as any) ?? undefined}
                        />
                        <HelperText
                          text={
                            errors['intervals']?.[index]?.selectedDate?.message
                          }
                        />
                      </Box>
                      <Box>
                        <TimeRange
                          control={control}
                          errors={!!errors['intervals']?.[index]?.selectedTime}
                          name={`intervals[${index}].selectedTime`}
                          setTime={(val: string) =>
                            setTimeToInterval(index, val)
                          }
                          time={element.selectedTime}
                        />
                        <HelperText
                          text={
                            errors['intervals']?.[index]?.selectedTime?.message
                          }
                        />
                      </Box>
                    </Box>
                    {intervals.length > 1 && (
                      <Button
                        onClick={() => removeTimeslot(index)}
                        variant='iconButton'
                      >
                        <TrashBucketIcon />
                      </Button>
                    )}
                  </DateTimeWrapper>
                ))}

                {intervals.length < 5 && (
                  <Button
                    onClick={() => addTimeslot()}
                    size='medium'
                    text='Add time'
                    variant='addPhoto'
                  />
                )}
              </DateTimeControl>

              <ButtonSection>
                <Button
                  onClick={() => {
                    navigate(`${Pages.CANDIDATE}?${constructSearchParams()}`);
                    _test_fn?.();
                  }}
                  text='Cancel'
                  variant='link'
                />
                <Button
                  text='Send Interview Request'
                  type='submit'
                  variant='basic'
                />
              </ButtonSection>
            </Border>
          </Box>
        </HeadWrapper>
      </Content>
    </Container>
  );
};

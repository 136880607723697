export const defaultTypography = {
  fontFamily: 'Juana Alt, FS Albert Pro, sans-serif', // Default for most text
  displayLarge: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '600',
    fontSize: '36px',
    lineHeight: '39.6px'
  },
  displayMedium: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '33.6px'
  },
  headlineLarge: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px'
  },
  headlineMedium: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21.6px'
  },
  titleLarge: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '27px'
  },
  titleMedium: {
    fontFamily: 'Juana Alt, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px'
  },
  titleSmallSemi: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: 'normal'
  },
  titleSmall: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px'
  },
  bodyXLarge: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21.6px'
  },
  bodyLarge: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px'
  },
  bodyLargeSemi: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '150%'
  },
  bodyMedium: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px'
  },
  bodySmall: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16.2px'
  },
  bodyXSmall: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: 'normal'
  },
  labelLargeSemi: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19.2px'
  },
  labelXLarge: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '30px'
  },
  labelLarge: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '25.2px'
  },
  labelMedium: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px'
  },
  labelMediumRegular: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '23.2px'
  },
  labelSmallMedium: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '19.67px'
  },
  labelSmallRegular: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19.67px'
  },
  linkMedium: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19.2px'
  },
  linkSmall: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '19.67px'
  },
  overline: {
    fontFamily: 'FS Albert Pro, sans-serif',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '18.2px',
    letterSpacing: '14.5%'
  }
};

import { FC } from 'react';

import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button, CredWrapper, Typography, UserAvatar } from '@/components';

import { Employee } from '@/types/employee';

import { InfoLabel, InfoContainer, WrapperCard, Photo } from './styles';

interface ICardCandidate {
  dataInfo: Employee;
  _test_fn?: () => void;
}

export const CardCandidate: FC<ICardCandidate> = ({ dataInfo, _test_fn }) => {
  const navigate = useNavigate();

  const getUser = async (id: number) => {
    navigate(`/candidate/${id}`);
    _test_fn?.();
  };

  return (
    <WrapperCard>
      <InfoContainer>
        <Photo>
          <UserAvatar
            firstName={dataInfo.firstName}
            imageUrl={dataInfo.imageUrl}
            lastName={dataInfo.lastName}
            size='xl'
          />
        </Photo>
        <Box pl='5px'>
          <InfoLabel>
            <Typography color='text.primary' variant='headlineLarge'>
              {dataInfo.firstName} {dataInfo.lastName}
            </Typography>
          </InfoLabel>
          <CredWrapper
            mainInfo={{
              specialization: dataInfo.employeeSpecializations[0].name,
              seniority: dataInfo.seniority.name,
              location: dataInfo.employeeCountries[0].name,
              price: dataInfo.price
            }}
          />
        </Box>
      </InfoContainer>
      <Box pl='5px'>
        <Button
          onClick={() => getUser(dataInfo.id)}
          text='View Details'
          variant='link'
        />
      </Box>
    </WrapperCard>
  );
};

import { useMemo } from 'react';

import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { authActions } from '@/redux/api/auth/authSlice';

export const useActions = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators({ ...authActions }, dispatch);
  }, [dispatch]);
};

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';

import { Employee, EmployeeList, EmployeePerfectMatch } from '@/types/employee';

import {
  EmployeeResponse,
  GetAllEmployeesDataPayload
} from './employeeApi.types';

export const employeeApi = createApi({
  reducerPath: 'api/employee',
  baseQuery: baseQuery,
  tagTypes: ['Employees', 'Employee'],
  endpoints: (build) => ({
    getAllEmployees: build.query<EmployeeList, GetAllEmployeesDataPayload>({
      query: (data) => ({
        method: 'GET',
        url: `${Endpoints.EMPLOYEES}?PageIndex=${data.pageIndex}&PageSize=${data.pageSize}`
      }),
      providesTags: ['Employees']
    }),
    getEmployeeById: build.query<Employee, string>({
      query: (id) => ({
        method: 'GET',
        url: `${Endpoints.GET_EMPLOYEE}?id=${id}`
      }),
      providesTags: ['Employee']
    }),
    createEmployee: build.mutation<EmployeeResponse, Employee>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.CREATE_EMPLOYEE,
        body: data
      }),
      invalidatesTags: ['Employees']
    }),
    updateEmployee: build.mutation<EmployeeResponse, Employee>({
      query: (data) => ({
        method: 'PUT',
        url: Endpoints.UPDATE_EMPLOYEE,
        body: data
      }),
      invalidatesTags: ['Employee', 'Employees']
    }),
    getMatch: build.mutation<EmployeeList, EmployeePerfectMatch>({
      query: (data) => ({
        method: 'POST',
        url: `${Endpoints.MATCH}`,
        body: data
      })
    }),
    getPerfectMatch: build.mutation<EmployeeList, EmployeePerfectMatch>({
      query: (data) => ({
        method: 'POST',
        url: `${Endpoints.PERFECT_MATCH}`,
        body: data
      })
    }),
    removeEmployeeAvatar: build.mutation<EmployeeResponse, number>({
      query: (id) => ({
        method: 'DELETE',
        url: `${Endpoints.REMOVE_EMPLOYEE_AVATAR}?employeeId=${id}`
      }),
      invalidatesTags: ['Employee', 'Employees']
    })
  })
});

export const {
  useGetAllEmployeesQuery,
  useLazyGetAllEmployeesQuery,
  useGetEmployeeByIdQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useGetMatchMutation,
  useGetPerfectMatchMutation,
  useRemoveEmployeeAvatarMutation
} = employeeApi;

import { useMemo } from 'react';

import { useGetAllDictionariesQuery } from '@/redux/api/dictionary/dictionaryApi';

type DictionaryItem = {
  name: string;
  id: number;
};

type DictionaryData = {
  [key: string]: DictionaryItem[];
};

type DictionaryGroups = string[];

type DictionaryResult = {
  dictionaries: DictionaryData;
  groups: DictionaryGroups;
};

const initialData: DictionaryResult = {
  dictionaries: {
    Countries: [],
    Specializations: [],
    SkillsAndTechnologies: [],
    Seniority: [],
    EmployeeStatus: [],
    Languages: [],
    LanguageLevels: []
  },
  groups: []
};

export function useDictionary(): DictionaryResult {
  const { data } = useGetAllDictionariesQuery();

  return useMemo<DictionaryResult>(() => {
    if (Array.isArray(data) && data.length > 0) {
      const dictionaries: DictionaryData = {};
      const groups: DictionaryGroups = [];
      data.forEach((item) => {
        const dictionaryName = item.name;
        const dictionaryItems = item.items || [];
        dictionaries[dictionaryName] = dictionaryItems.map((item) => ({
          name: item.name,
          id: item.id
        }));
        groups.push(dictionaryName);
      });

      return {
        dictionaries,
        groups
      };
    }

    return initialData;
  }, [data]);
}

import { FC, Dispatch, SetStateAction } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { Button, Modal, RichTextEditor } from '@/components';

import { MAX_CHARTERS, validationSchema } from './about.config';
import { ButtonWrapper, FormWrapper, ModalContainer } from './styles';

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { about: string }) => void;
  aboutText: string | undefined;
  setAboutText: Dispatch<SetStateAction<string | undefined>>;
}

const AboutModal: FC<AboutModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  aboutText,
  setAboutText
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      about: aboutText || ''
    }
  });

  return (
    <Modal active={isOpen} modalTitle='About' onClose={onClose}>
      <ModalContainer>
        <FormWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name='about'
              render={({ field }) => (
                <RichTextEditor
                  defaultValue={aboutText}
                  error={!!errors.about}
                  errorMessage={errors.about?.message}
                  maxLength={MAX_CHARTERS}
                  onChange={(value) => {
                    field.onChange(value);
                    setAboutText(value);
                  }}
                />
              )}
            />
            <ButtonWrapper>
              <Button size='medium' text='Save' type='submit' variant='next' />
            </ButtonWrapper>
          </form>
        </FormWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default AboutModal;

/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  Button,
  SelectSpecialization,
  HelperText,
  Typography
} from '@/components';
import { useEmployeeFormState } from '@/context/formContext';
import { useDictionary } from '@/hooks';
import {
  Container,
  Decsription,
  ButtonWrapperSB,
  HeadWrapper,
  HeadWrapperRight,
  Name,
  Location,
  Subtitle,
  Logo,
  ButtonSeniority,
  Form
} from '@/page/employee/create/styles';
import { ThemedContainer } from '@/providers';

import {
  employeeDefaultValues,
  specializationsValidateSchema
} from './specialization.config';
import { Seniority } from './style';

interface SeniorityOption {
  name: string;
  id: number;
}

interface FormData {
  seniority: {
    id: number;
    name: string;
  };
  employeeSpecializations: {
    id: number;
    name: string;
  };
}

const Specialization = () => {
  const { dictionaries } = useDictionary();
  const { onHandleNext, onHandleBack, setEmployeeData, employeeData } =
    useEmployeeFormState();

  const [selectedSeniority, setSelectedSeniority] = useState(
    employeeData.seniority || ''
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: employeeDefaultValues,
    resolver: yupResolver(specializationsValidateSchema)
  });

  const specializations: any = watch('employeeSpecializations');

  const specializationDictionary: SeniorityOption[] =
    dictionaries.Specializations;
  const seniorityDictionary: SeniorityOption[] = dictionaries.Seniority;

  const specializationValue = {
    id: specializations?.id ?? '',
    name: specializations?.name ?? ''
  };

  useEffect(() => {
    reset(employeeData);
  }, [employeeData, reset]);

  const handleSetSeniority = (seniority: SeniorityOption) => {
    clearErrors('seniority');
    setSelectedSeniority(seniority);

    setEmployeeData((prevEmployeeData: any) => ({
      ...prevEmployeeData,
      employeeSpecializations: specializations,
      seniority
    }));
  };

  const isSenioritySelected = (seniority: any) => {
    return seniority.id === selectedSeniority.id;
  };

  const onSubmit = (data: FormData) => {
    onHandleNext();
    setEmployeeData((prevEmployeeData: any) => ({
      ...prevEmployeeData,
      ...data,
      seniority: selectedSeniority
    }));
  };

  useEffect(() => {
    setSelectedSeniority(employeeData.seniority || []);
  }, [employeeData.seniority]);

  return (
    <ThemedContainer>
      <Container data-testid={'Specialization component'}>
        <Typography variant='displayMedium'>Specialization</Typography>
        <Decsription>
          Define your employee specialization and seniority to showcase
          expertise accurately.
        </Decsription>
        <HeadWrapper>
          <div>
            <Logo>
              {employeeData.firstName?.charAt(0)}
              {employeeData.lastName?.charAt(0)}
            </Logo>
          </div>
          <HeadWrapperRight>
            <Name>
              {employeeData.firstName} {employeeData.lastName}
            </Name>
            <Location>{employeeData.employeeCountries?.name}</Location>
          </HeadWrapperRight>
        </HeadWrapper>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Subtitle>Specialization</Subtitle>
          <Box mt='15px' width='100%'>
            <SelectSpecialization
              control={control}
              errors={!!errors['employeeSpecializations']}
              label='Select Specialization'
              name='employeeSpecializations'
              options={specializationDictionary}
              value={specializationValue}
            />
            <HelperText
              text={
                errors['employeeSpecializations']?.name?.message ??
                errors['employeeSpecializations']?.message
              }
            />
          </Box>

          <Subtitle>Seniority</Subtitle>
          <ButtonSeniority>
            {seniorityDictionary.map((seniority: SeniorityOption) => (
              <Seniority
                error={!!errors['seniority']}
                key={seniority.id}
                onClick={() => handleSetSeniority(seniority)}
                selected={isSenioritySelected(seniority)}
              >
                {seniority.name}
              </Seniority>
            ))}
          </ButtonSeniority>
          {errors['seniority']?.name?.message && !selectedSeniority?.id && (
            <HelperText text={errors['seniority'].name.message} />
          )}

          <ButtonWrapperSB>
            <Button
              onClick={onHandleBack}
              text='Previous Step'
              variant='prev'
            />
            <Button
              size='medium'
              text='Next Step'
              type='submit'
              variant='next'
            />
          </ButtonWrapperSB>
        </Form>
      </Container>
    </ThemedContainer>
  );
};

export default Specialization;

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const WrapperBtnLogin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding-top: 8px;
  height: 56px;
`;

export const OrStroke = styled.div`
  margin-top: 24px;
  color: #9a9aa4;
`;

export const ButtonNextWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`;

export const AgreementWrapper = styled.div`
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.8;
`;

export const SignInForm = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export const StyledLink = styled(Link)`
  margin-top: 24px;
  margin-right: 4px;
  margin-left: 4px;
  color: #2146af;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
`;

export const DescriptionSent = styled.div`
  text-align: center;
  margin-top: 32px;
`;

export const ButtonResend = styled.p`
  margin-top: 24px;
`;

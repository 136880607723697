import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';

import { ItemsType, Types } from './dictionaryApi.types';

const allDictionaryTypes = [1, 2, 3, 4, 5, 6, 7].join('&dictionaryType=');

export const dictionaryApi = createApi({
  reducerPath: 'api/dictionary',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getAllDictionaries: build.query<ItemsType[] | null, void>({
      query: () => ({
        method: 'GET',
        url: `${Endpoints.DICTIONARY}?dictionaryType=${allDictionaryTypes}`
      })
    }),
    getDictionaryByType: build.query({
      query: (dictionaryType) => ({
        method: 'GET',
        url: `${Endpoints.DICTIONARY}?dictionaryType=${dictionaryType}`
      })
    }),
    getTypeItems: build.query<ItemsType[] | null, void>({
      query: () => ({ method: 'GET', url: Endpoints.DICTIONARY })
    }),
    getTypes: build.query<Types[] | null, void>({
      query: () => ({ method: 'GET', url: Endpoints.DICTIONARY_TYPES })
    })
  })
});

export const {
  useGetAllDictionariesQuery,
  useGetDictionaryByTypeQuery,
  useGetTypeItemsQuery,
  useGetTypesQuery
} = dictionaryApi;

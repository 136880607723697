import { FC } from 'react';

import { Box } from '@mui/material';

import { Typography, TypographyVariant } from '@/components';

import { Dollar, Row, Separator, Translate } from './styles';

export interface MainData {
  price: number;
  language: string | null;
  level: string | null;
}

export interface SalaryLanguageProps {
  mainData: MainData;
  variant?: TypographyVariant;
  addSymbol?: boolean;
  addSeparator?: boolean;
}

export const SalaryLanguage: FC<SalaryLanguageProps> = ({
  mainData,
  variant = 'labelMediumRegular',
  addSymbol = true,
  addSeparator = false
}) => (
  <Row isGap={addSymbol || addSeparator}>
    <Box alignItems='center' columnGap='4px' display='flex'>
      <Dollar />
      <Typography variant={variant}>
        ${mainData.price}/h{addSymbol && 'r'}
      </Typography>
    </Box>
    {addSymbol && <Separator />}
    {addSeparator && <Separator />}
    <Box alignItems='center' columnGap='4px' display='flex'>
      <Translate />
      <Typography variant={variant}>
        {`${mainData.language} (${mainData.level})`}
      </Typography>
    </Box>
  </Row>
);

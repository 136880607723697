import { Quill } from 'react-quill';

const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];

Quill.register(Size, true);

function undoChange() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.quill.history.undo();
}

function redoChange() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  this.quill.history.redo();
}

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const sizeOptions = [
  { name: 'Heading', value: '1' },
  { name: 'Subheading', value: '2' },
  { name: 'Normal', value: '3' }
];

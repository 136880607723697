import { FC } from 'react';

import { GoogleLogin, GoogleLoginProps } from '@react-oauth/google';

export const GoogleLoginButton: FC<GoogleLoginProps> = ({
  locale = 'English',
  logo_alignment = 'left',
  text = 'continue_with',
  width = '380px',
  ...restProps
}) => (
  <GoogleLogin
    data-testid='googleBtn'
    locale={locale}
    logo_alignment={logo_alignment}
    text={text}
    width={width}
    {...restProps}
  />
);

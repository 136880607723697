import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

export const WrapperCred = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.div`
  font-family: 'FS Albert Pro Latin', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #4f6a6c;
`;

export const Separator = muiStyled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: '4px',
  height: '4px',
  margin: '0 8px',
  backgroundColor: theme.palette.grey[100]
}));

export const HeadTitle = muiStyled('div')(({ theme }) => ({
  ...theme.typography.labelSmallMedium,
  color: theme.palette.primaryRoot?.greyDark,
  width: '74px'
}));

import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import { styled as muiStyled } from '@mui/material';

export const CustomSearchIcon = styled(SearchIcon)`
  font-size: 28px;
  color: #70a500;

  &:hover {
    color: #2b5659;
  }
  &:focus {
    color: #2b5659;
  }
`;

export const ClearIndicatorStyles = muiStyled('p')(({ theme }) => ({
  ...theme.typography.labelSmallMedium,
  cursor: 'pointer',
  color: theme.palette.text.link,
  marginRight: '12px'
}));

const CustomClearIndicator = ({ innerProps, selectProps }: any) => {
  return (
    <div {...innerProps} onClick={selectProps.clearValue}>
      <ClearIndicatorStyles>Clear All</ClearIndicatorStyles>
    </div>
  );
};

export default CustomClearIndicator;

export enum Pages {
  HOME = '/',
  TEST_PRIVATE = '/private_test',
  CANDIDATE = '/candidate',
  CANDIDATE_ID = '/candidate/:id',
  CHANGE_PASSWORD = '/change-password',
  CHANGE_PASSWORD_SUCCESS = '/change-password/success',
  FORGOT_PASSWORD = '/forgot-password',
  SIGN_IN = '/signIn',
  SIGN_UP = '/signUp',
  EMPLOYEE = '/employee',
  EMPLOYEE_ID = '/employee/:id',
  EMPLOYEE_CREATE = '/employee/create',
  INTERVIEW_SUCCESS = '/interview/success',
  INTERVIEW_ID = '/interview/:id'
}

export const getPageById = (path: string, id: string) => {
  return path.replace(':id', id);
};

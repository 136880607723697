import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ModalContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const ModalFormInput = css`
  margin-top: 15px;
`;

export const Bottom = styled.div`
  margin-bottom: 36px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
`;

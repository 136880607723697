/** @jsxImportSource @emotion/react */

import { useState, useEffect, FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider, Stack } from '@mui/material';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  Button,
  CredWrapper,
  LanguageLevels,
  PriceInput,
  SelectInput,
  SelectSpecialization,
  TextInput,
  Modal,
  SalaryLanguage,
  HelperText,
  UserAvatar,
  RichTextInterpretator
} from '@/components';
import { useDictionary } from '@/hooks';
import AboutModal from '@/page/employee/_components/aboutModal';
import SkillsTechsModal from '@/page/employee/_components/skillsModal';
import {
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation
} from '@/redux/api/employee/employeeApi';

import { Employee } from '@/types/employee';

import { employeeDefaultValues, schema } from './employee.config';
import {
  AddButton,
  ButtonSeniorityBlock,
  Card,
  CardSubtitle,
  CardTitle,
  CardsWrapper,
  CardsWrapperLeftCard,
  CardsWrapperRightCard,
  Container,
  Description,
  EditButton,
  HeadWrapper,
  HeadWrapperRight,
  HeaderCardWrapper,
  Input,
  ModalButton,
  ModalContainer,
  ModalFormInput,
  ModalFormInputName,
  ModalFormScroll,
  ModalFormWrapper,
  Name,
  SeniorityBlock,
  Skills,
  SkillsWrapper,
  SubTitle,
  FlexWrapper
} from './styles';

interface ItemValue {
  id: number;
  name: string;
}

export const EmployeeProfile: FC = () => {
  const [updateEmployee] = useUpdateEmployeeMutation();

  const {
    control,
    reset,
    getValues,
    handleSubmit,
    watch,
    trigger,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: employeeDefaultValues,
    resolver: yupResolver(schema)
  });

  const { dictionaries } = useDictionary();
  const { id: linkId } = useParams<{ id: string }>();
  const { data: employeeData, refetch: refetchEmployee } =
    useGetEmployeeByIdQuery(linkId || '');

  useEffect(() => {
    reset(employeeData as any); // TODO: should provide correct types to the useForm
  }, [employeeData, reset]);

  const locationDictionary = dictionaries.Countries;
  const specializationDictionary = dictionaries.Specializations;
  const skillsAndTechnologiesDictionary = dictionaries.SkillsAndTechnologies;
  const seniorityDictionary: ItemValue[] = dictionaries.Seniority;
  const languagesDictionary = dictionaries.Languages;
  const languageLevelsDictionary = dictionaries.LanguageLevels;
  const statusDictionary = dictionaries.EmployeeStatus;

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAboutModalOpen, setAboutModalOpen] = useState(false);
  const [aboutText, setAboutText] = useState<string | undefined>(undefined);
  const [isSkillsTechsModalOpen, setSkillsTechsModalOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<ItemValue[]>([]);

  const location: any = watch('employeeCountries');
  const specializations: any = watch('employeeSpecializations');
  const seniority: any = watch('seniority');
  const status: any = watch('employeeStatus');
  const employeeLanguageLevels: any = watch('employeeLanguageLevels');

  const getValueLocation = {
    name: location[0]?.name ?? '',
    id: location[0]?.id ?? ''
  };
  const getValueSpecializations = {
    name: specializations[0]?.name ?? '',
    id: specializations[0]?.id ?? ''
  };

  const handleSkillSelection = (newValues: ItemValue[]) => {
    const updatedSkills = [...selectedSkills];

    newValues.forEach((skill) => {
      const index = updatedSkills.findIndex((s) => s.id === skill.id);
      if (index === -1) {
        updatedSkills.push(skill);
      }
    });

    updatedSkills.forEach((skill, index) => {
      const stillSelected = newValues.some((s) => s.id === skill.id);
      if (!stillSelected) {
        updatedSkills.splice(index, 1);
      }
    });

    setSelectedSkills(updatedSkills);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    reset(employeeData as any);
    setEditModalOpen(false);
  };

  const openSkillsTechsModal = () => {
    const value = getValues('employeeSkills');
    setSelectedSkills(value as any);
    setSkillsTechsModalOpen(true);
  };

  const openAboutModal = () => {
    setAboutText(getValues('about') || '');
    setAboutModalOpen(true);
  };

  const onSubmitMainForm = async (
    mainFormData: SubmitHandler<{ [v: string]: any }> | any
  ) => {
    closeEditModal();
    updateEmployee({
      id: linkId,
      ...mainFormData,
      seniorityId: mainFormData.seniority.id,
      employeeStatusId: mainFormData.employeeStatus.id
    })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Oops, something went wrong', {
          variant: 'error'
        });
      });
  };

  const onSubmitAbout = async (aboutData: { about: string }) => {
    updateEmployee({
      ...(employeeData as Employee),
      id: Number(linkId),
      about: aboutData.about
    })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Oops, something went wrong', {
          variant: 'error'
        });
      });
    setAboutModalOpen(false);
  };

  const onSubmitSkills = async (
    skillsData: SubmitHandler<{ [v: string]: any }> | any
  ) => {
    updateEmployee({ ...skillsData, employeeSkills: [...selectedSkills] })
      .unwrap()
      .catch(() => {
        enqueueSnackbar('Oops, something went wrong', {
          variant: 'error'
        });
      });
    setSkillsTechsModalOpen(false);
  };

  const isSenioritySelected = (senioritySelected: ItemValue) => {
    return seniority.id === senioritySelected.id;
  };

  const handleLocationChange = async (newValue: ItemValue) => {
    setValue('employeeCountries', [newValue]);
    await trigger('employeeCountries');
  };

  const handleSpecializationChange = async (newValue: ItemValue) => {
    setValue('employeeSpecializations', [newValue]);
    await trigger('employeeSpecializations');
  };

  const handleSeniorityChange = (selectedSeniority: ItemValue) => {
    setValue('seniority', selectedSeniority);
  };

  const isActivateSelected = status.id === statusDictionary[1]?.id;
  const isHasContractSelected = status.id === statusDictionary[0]?.id;

  return (
    <Container>
      <FlexWrapper>
        <HeadWrapper>
          <UserAvatar
            employeeId={employeeData?.id}
            firstName={getValues('firstName')}
            imageUrl={employeeData?.imageUrl}
            key='unique-key'
            lastName={getValues('lastName')}
            onSuccess={refetchEmployee}
            size='logo'
          />
          <HeadWrapperRight>
            <Stack direction='row' spacing='12px'>
              <Name>{`${getValues('firstName')} ${getValues(
                'lastName'
              )}`}</Name>
              {/* TODO: Should create chip component for all statuses */}
              <Button size='small' text={status.name} variant='hire' />
            </Stack>
            <CredWrapper
              mainInfo={{
                specialization: specializations[0]?.name,
                seniority: (getValues('seniority') as any).name,
                location: location[0]?.name
              }}
            />
            <SalaryLanguage
              mainData={{
                price: +getValues('priceWithMarkup'),
                language: employeeLanguageLevels[0].language.name,
                level: employeeLanguageLevels[0].languageLevel.name
              }}
              variant='titleSmallSemi'
            />
            <Button size='small' text='Upload CV' variant='cv' />
          </HeadWrapperRight>
        </HeadWrapper>
        <FlexWrapper>
          <EditButton onClick={openEditModal} />
        </FlexWrapper>
      </FlexWrapper>

      <Divider sx={{ mt: '48px' }} />
      <HeaderCardWrapper>
        <SubTitle>Skills & Techs</SubTitle>
        <AddButton onClick={openSkillsTechsModal} />
      </HeaderCardWrapper>
      <SkillsWrapper>
        {getValues('employeeSkills').map((skills: any) => (
          <Skills key={skills.id}>
            <p>{skills.name}</p>
          </Skills>
        ))}
      </SkillsWrapper>

      <Divider sx={{ mt: '40px' }} />
      <HeaderCardWrapper>
        <SubTitle>About</SubTitle>
        <EditButton onClick={openAboutModal} />
      </HeaderCardWrapper>
      {employeeData?.about ? (
        <Box mt='16px'>
          <RichTextInterpretator text={employeeData.about} />
        </Box>
      ) : (
        <Description>
          You can write about years of experience, industry, or skills. People
          also talk about achievements or previous work experience.
        </Description>
      )}

      <Divider sx={{ mt: '40px' }} />
      <HeaderCardWrapper>
        <SubTitle>Experience</SubTitle>
      </HeaderCardWrapper>
      <Description>
        You can write about years of experience, industry, or skills. People
        also talk about achievements or previous work experience.
      </Description>

      {isEditModalOpen && (
        <Modal
          active={isEditModalOpen}
          modalTitle='Edit main information'
          onClose={closeEditModal}
        >
          <ModalContainer>
            <ModalFormWrapper>
              <ModalFormScroll>
                <form onSubmit={handleSubmit(onSubmitMainForm)}>
                  <ModalFormInputName>
                    First Name & Second Name
                  </ModalFormInputName>
                  <TextInput
                    css={[ModalFormInput]}
                    errors={errors}
                    label='First Name'
                    name='firstName'
                    register={control.register}
                  />
                  <HelperText text={errors.firstName?.message} />

                  <Input>
                    <TextInput
                      css={[ModalFormInput]}
                      errors={errors}
                      label='Last Name'
                      name='lastName'
                      register={control.register}
                    />
                    <HelperText text={errors.lastName?.message} />
                  </Input>

                  <ModalFormInputName>Location</ModalFormInputName>
                  <SelectInput
                    control={control}
                    css={[ModalFormInput]}
                    errors={!!errors['employeeCountries']}
                    label='Select Location'
                    name='employeeCountries'
                    onChange={handleLocationChange}
                    options={locationDictionary || []}
                    value={getValueLocation}
                  />
                  <HelperText
                    text={
                      errors['employeeCountries']?.[0]?.message ??
                      errors['employeeCountries']?.message
                    }
                  />

                  <ModalFormInputName>Specialization</ModalFormInputName>
                  <SelectSpecialization
                    control={control}
                    css={[ModalFormInput]}
                    errors={!!errors['employeeSpecializations']}
                    label='Select Specialization'
                    name='employeeSpecializations[0].name'
                    onChange={handleSpecializationChange}
                    options={specializationDictionary}
                    value={getValueSpecializations}
                  />
                  <HelperText
                    text={
                      errors['employeeSpecializations']?.[0]?.message ??
                      errors['employeeSpecializations']?.message
                    }
                  />

                  <ModalFormInputName>Price</ModalFormInputName>
                  <PriceInput
                    css={[ModalFormInput]}
                    errors={!!errors['price']}
                    label='Price, USD per Hour'
                    name='price'
                    register={control.register}
                  />
                  <HelperText text={errors.price?.message} />

                  <ModalFormInputName>Seniority</ModalFormInputName>
                  <ButtonSeniorityBlock>
                    {seniorityDictionary.map((seniority: ItemValue) => (
                      <SeniorityBlock
                        className={classNames({
                          selected: isSenioritySelected(seniority)
                        })}
                        key={seniority.id}
                        onClick={() => handleSeniorityChange(seniority)}
                      >
                        <p>{seniority.name}</p>
                      </SeniorityBlock>
                    ))}
                  </ButtonSeniorityBlock>

                  <ModalFormInputName>Languages</ModalFormInputName>
                  <LanguageLevels
                    addNewLanguage={() => {
                      setValue('employeeLanguageLevels', [
                        ...getValues('employeeLanguageLevels'),
                        {
                          language: { id: 0, name: '' },
                          languageLevel: { id: 0, name: '' }
                        }
                      ]);
                    }}
                    control={control}
                    deleteNewLanguage={(idx: number) => {
                      const list = getValues('employeeLanguageLevels');
                      list.splice(idx, 1);
                      setValue('employeeLanguageLevels', list);
                    }}
                    errors={errors['employeeLanguageLevels']}
                    languageLevels={employeeLanguageLevels}
                    optionsLanguage={languagesDictionary}
                    optionsLevel={languageLevelsDictionary}
                  />

                  <ModalFormInputName>Availability Status</ModalFormInputName>
                  <CardsWrapper>
                    <CardsWrapperLeftCard>
                      <Card
                        onClick={() =>
                          setValue('employeeStatus', {
                            id: statusDictionary[1].id,
                            name: statusDictionary[1].name
                          })
                        }
                        selected={isActivateSelected}
                      >
                        <CardTitle selected={isActivateSelected}>
                          Active search for new contract
                        </CardTitle>
                        <CardSubtitle selected={isActivateSelected}>
                          Your candidate will appear in the search for a client
                          and can be hired. As soon as he is hired, the status
                          changes.
                        </CardSubtitle>
                      </Card>
                    </CardsWrapperLeftCard>
                    <CardsWrapperRightCard>
                      <Card
                        onClick={() =>
                          setValue('employeeStatus', {
                            id: statusDictionary[0].id,
                            name: statusDictionary[0].name
                          })
                        }
                        selected={isHasContractSelected}
                      >
                        <CardTitle selected={isHasContractSelected}>
                          Has Contract
                        </CardTitle>
                        <CardSubtitle selected={isHasContractSelected}>
                          Your candidate will not appear in the client&#39;s
                          search results, but you can always transfer them to
                          the bench.
                        </CardSubtitle>
                      </Card>
                    </CardsWrapperRightCard>
                  </CardsWrapper>

                  <Box mb='32px' />
                </form>
              </ModalFormScroll>
            </ModalFormWrapper>
          </ModalContainer>
          <ModalButton>
            <Button
              onClick={handleSubmit(onSubmitMainForm)}
              size='medium'
              text='Save'
              type='submit'
              variant='next'
            />
          </ModalButton>
        </Modal>
      )}
      <SkillsTechsModal
        control={control}
        editedSkillsTechs={selectedSkills}
        errors={errors}
        handleSkillSelection={handleSkillSelection}
        isOpen={isSkillsTechsModalOpen}
        onClose={() => setSkillsTechsModalOpen(false)}
        onSubmit={handleSubmit(onSubmitSkills)}
        skillsAndTechnologiesDictionary={skillsAndTechnologiesDictionary}
        value={selectedSkills.map((skillId) => {
          const skill = skillsAndTechnologiesDictionary.find(
            (item: any) => item.id === skillId.id
          );
          return {
            name: skill ? skill.name : '',
            id: skill?.id || 0
          };
        })}
      />
      <AboutModal
        aboutText={aboutText}
        isOpen={isAboutModalOpen}
        onClose={() => setAboutModalOpen(false)}
        onSubmit={onSubmitAbout}
        setAboutText={setAboutText}
      />
    </Container>
  );
};

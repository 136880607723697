import { FC, useRef, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { Button, Modal, Typography } from '@/components';
import { useRemoveEmployeeAvatarMutation } from '@/redux/api/employee/employeeApi';
import { useUploadImageMutation } from '@/redux/api/file/fileApi';

import { Avatar, AvatarOverlay, AvatarWrapper } from './styles';
import {
  DELETE_MESSAGE,
  INITIAL_MESSAGE,
  Size,
  SUCCESS_DELETED_MESSAGE,
  SUCCESS_MESSAGE,
  Variant
} from './user-avatar.config';

export interface AvatarBase {
  size: Size;
  variant: Variant;
  canChange?: boolean;
}

type UpdatedAvatarBase = Omit<AvatarBase, 'variant'> & { variant?: Variant };

interface UserAvatarProps extends UpdatedAvatarBase {
  imageUrl?: string | null;
  firstName: string;
  lastName: string;
  employeeId?: string | number;
  onSuccess?: () => void;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  size,
  variant = 'authorized',
  imageUrl,
  firstName,
  lastName,
  employeeId,
  canChange = true,
  onSuccess
}) => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [uploadImage, { isLoading }] = useUploadImageMutation();
  const [removeEmployeeAvatar] = useRemoveEmployeeAvatarMutation();

  const [open, setOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [hasDeleteState, setHasDeleteState] = useState(false);

  const handleFileInputChange = (file: File) => {
    if (file && employeeId) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('employeeId', employeeId.toString());

      uploadImage(formData)
        .unwrap()
        .then((response) => {
          if (response?.url) {
            enqueueSnackbar(response.message, {
              variant: 'success'
            });
            onSuccess?.();
            setInfoMessage(SUCCESS_MESSAGE);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const closeHandler = () => {
    setOpen(false);
    setInfoMessage('');
  };

  const deleteStateHandler = () => {
    setInfoMessage(DELETE_MESSAGE);
    setHasDeleteState(true);
  };

  const deleteImageHandler = () => {
    if (employeeId) {
      removeEmployeeAvatar(+employeeId)
        .unwrap()
        .then(() => {
          setInfoMessage(INITIAL_MESSAGE);
          setHasDeleteState(false);
          enqueueSnackbar(SUCCESS_DELETED_MESSAGE, {
            variant: 'success'
          });
        })
        .catch((error) => console.error(error));
    }
  };

  const cancelHandler = () => {
    setHasDeleteState(false);
    setInfoMessage('');
  };

  const UploadInput = (
    <input
      accept='image/png, image/jpeg'
      hidden
      onChange={(e) =>
        e.target.files && handleFileInputChange(e.target.files[0])
      }
      ref={uploadInputRef}
      type='file'
    />
  );

  return (
    <>
      <AvatarWrapper
        canChange={canChange}
        position='relative'
        size={size}
        variant={variant}
        width='fit-content'
      >
        <Avatar size={size} variant={variant}>
          {imageUrl ? (
            <img
              alt="User's Photo"
              src={`${imageUrl}?now-cache=${new Date()}`}
            />
          ) : (
            `${firstName.charAt(0)}${lastName!.charAt(0)}`
          )}
        </Avatar>
        <AvatarOverlay
          className='AvatarOverlay'
          onClick={() => setOpen(true)}
          size={size}
          variant={variant}
        >
          <EditOutlinedIcon fontSize='large' />
        </AvatarOverlay>
      </AvatarWrapper>

      <Modal
        active={open}
        modalTitle='Profile photo'
        onClose={closeHandler}
        variant='small'
      >
        <Box
          alignItems='center'
          display='flex'
          flexDirection='column'
          maxWidth='368px'
          px='22px'
          py='32px'
        >
          <Avatar size='logo' variant={variant}>
            {imageUrl && !isLoading ? (
              <img alt='photo' src={`${imageUrl}?now-cache=${new Date()}`} />
            ) : (
              `${firstName.charAt(0)}${lastName!.charAt(0)}`
            )}
          </Avatar>

          <Typography
            mb='32px'
            mt='24px'
            textAlign='center'
            variant='bodyMedium'
            width='auto'
          >
            {infoMessage && infoMessage}
            {!imageUrl && !infoMessage && INITIAL_MESSAGE}
          </Typography>

          {!imageUrl && !hasDeleteState && (
            <Button
              fullWidth
              onClick={() =>
                uploadInputRef.current && uploadInputRef.current.click()
              }
              text='Upload photo'
            >
              {UploadInput}
            </Button>
          )}

          {imageUrl && !hasDeleteState && (
            <Box display='flex' gap='16px' width='100%'>
              <Button
                fullWidth
                onClick={deleteStateHandler}
                text='Delete Photo'
                variant='clearBorder'
              />
              <Button
                fullWidth
                onClick={() =>
                  uploadInputRef.current && uploadInputRef.current.click()
                }
                text='Change photo'
              >
                {UploadInput}
              </Button>
            </Box>
          )}

          {hasDeleteState && (
            <Box display='flex' gap='16px' width='100%'>
              <Button
                fullWidth
                onClick={cancelHandler}
                text='Cancel'
                variant='clearBorder'
              />
              <Button fullWidth onClick={deleteImageHandler} text='Delete' />
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

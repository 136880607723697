import { Box, styled } from '@mui/material';

import { AvatarBase } from '@/components';

import {
  AVATAR_COLOR,
  AVATAR_SIZE
} from '@/components/user-avatar/user-avatar.config';

export const AvatarWrapper = styled(Box)<AvatarBase>(
  ({ variant, size, canChange }) => ({
    borderRadius: '50%',
    overflow: 'hidden',

    ...(variant === 'authorized' &&
      size === 'logo' &&
      canChange && {
        ':hover': {
          cursor: 'pointer',
          '.AvatarOverlay': {
            display: 'flex'
          }
        }
      })
  })
);

export const AvatarOverlay = styled(Box)<AvatarBase>(({ theme, size }) => ({
  width: AVATAR_SIZE[size].width,
  height: AVATAR_SIZE[size].height,
  backgroundColor: theme.palette.overlay?.dark?.[800],
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  color: '#AEE3F8',
  fontSize: AVATAR_SIZE[size].fontSize,
  position: 'absolute',
  top: 0
}));

export const Avatar = styled(Box)<AvatarBase>(({ variant, size }) => ({
  width: AVATAR_SIZE[size].width,
  height: AVATAR_SIZE[size].height,
  backgroundColor: AVATAR_COLOR[variant].backgroundColor,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  textTransform: 'uppercase',
  color: AVATAR_COLOR[variant].color,
  fontSize: AVATAR_SIZE[size].fontSize,
  fontWeight: 600,
  overflow: 'hidden'
}));

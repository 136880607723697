import * as Yup from 'yup';

import { NO_NUMBERS_REGEX, NO_SPECIAL_CHARS_REGEX } from '@/constants';

export const employeeDefaultValues = {
  firstName: '',
  lastName: '',
  price: '',
  priceWithMarkup: '',
  employeeStatusId: 0,
  seniorityId: 0,
  about: '',
  imageUrl: '',
  employeeStatus: {
    id: 0,
    name: ''
  },
  seniority: {
    id: 0,
    name: ''
  },
  employeeSkills: [
    {
      id: 0,
      name: ''
    }
  ],
  employeeSpecializations: [
    {
      id: 0,
      name: ''
    }
  ],
  employeeCountries: [
    {
      id: 0,
      name: ''
    }
  ],
  employeeLanguageLevels: [
    {
      language: {
        id: 0,
        name: ''
      },
      languageLevel: {
        id: 0,
        name: ''
      }
    }
  ],
  employeeExperiences: [
    {
      id: 0,
      employeeId: 0,
      companyName: '',
      companyLocationId: 0,
      specializationId: 0,
      additionalInfo: '',
      employeeExperienceSkills: [
        {
          id: 0,
          name: ''
        }
      ]
    }
  ]
};

const minMaxFirstName =
  'Sorry, first name must be between 2 and 32 characters long';
const minMaxLastName =
  'Sorry, last name must be between 2 and 32 characters long';
const validMessage = 'Are you sure you entered your name correctly?';

export const schema = Yup.object().shape({
  firstName: Yup.string()
    .label('First Name')
    .trim()
    .required()
    .min(2, minMaxFirstName)
    .max(32, minMaxFirstName)
    .matches(NO_SPECIAL_CHARS_REGEX, validMessage),
  lastName: Yup.string()
    .label('Last Name')
    .trim()
    .required()
    .min(2, minMaxLastName)
    .max(32, minMaxLastName)
    .matches(NO_SPECIAL_CHARS_REGEX, validMessage),
  price: Yup.string()
    .label('Price')
    .required()
    .matches(NO_NUMBERS_REGEX, 'Invalid value')
    .test('minPrice', 'The lowest price for the system is 5$', (value) => {
      return parseFloat(value) >= 5;
    })
    .test('maxPrice', 'The highest price for the system is 500$', (value) => {
      return parseFloat(value) <= 500;
    }),
  priceWithMarkup: Yup.string().label('PriceWithMarkup').required(),
  employeeStatusId: Yup.number().label('EmployeeStatusId').required(),
  seniorityId: Yup.number().label('Seniority').required(),
  about: Yup.string().nullable(),
  imageUrl: Yup.string().nullable(),
  employeeStatus: Yup.mixed().label('Status').required(),
  seniority: Yup.mixed().label('Seniority').required(),
  employeeSkills: Yup.array().of(Yup.mixed()).label('Skills').required(),
  employeeCountries: Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.number().required(),
          name: Yup.string().required()
        })
        .required('Location is a required field')
    )
    .required(),
  employeeSpecializations: Yup.array()
    .of(
      Yup.object()
        .shape({
          id: Yup.number().required(),
          name: Yup.string().required()
        })
        .required('Specialization is a required field')
    )
    .required(),
  employeeLanguageLevels: Yup.array()
    .of(
      Yup.object()
        .shape({
          language: Yup.object().shape({
            id: Yup.number().required('Language is a required field'),
            name: Yup.string().required('Language is a required field')
          }),
          languageLevel: Yup.object().shape({
            id: Yup.number().required('Level is a required field'),
            name: Yup.string().required('Level is a required field')
          })
        })
        .required()
    )
    .required(),
  employeeExperiences: Yup.array()
    .of(Yup.mixed())
    .label('Experiences')
    .required()
});

import { FC } from 'react';

import classNames from 'classnames';

interface SenioritySelectorProps {
  options: string[];
  selectedSeniority: string;
  onSelectSeniority: (seniority: string) => void;
  classNameButton?: string;
  className?: string;
}

export const SenioritySelector: FC<SenioritySelectorProps> = ({
  options,
  onSelectSeniority,
  classNameButton,
  className
}) => (
  <div className={classNameButton}>
    {options.map((seniority) => (
      <div
        className={classNames(className, 'jestTest-class')}
        key={seniority}
        onClick={() => onSelectSeniority(seniority)}
      >
        <p>{seniority}</p>
      </div>
    ))}
  </div>
);

import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';
import { Link } from 'react-router-dom';

import LineHeader from '@/assets/images/icon/LineHeader.svg';

interface HeaderWrapperProps {
  isDefaultPage: boolean;
}

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: ${({ isDefaultPage }) =>
    isDefaultPage ? '#EAFFE4' : '#ffffff'};
  height: 64px;
  border-bottom: 1px solid #e7eaf6;
`;

export const HeadBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

// export const HeadTitle = muiStyled('div')(({ theme }) => ({
//   ...theme.typography.labelSmallMedium,
//   color: '#222222',
//   fontSize: '26px',
//   width: '74px'
// }));

export const RightLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const UserLogo = muiStyled('div')(({ theme }) => ({
  ...theme.typography.bodyLargeSemi,
  width: '32px',
  height: '32px',
  backgroundColor: theme.palette.main.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.neutral.white,
  borderRadius: '24px',
  textTransform: 'uppercase'
}));

export const HeaderLine = styled.div`
  background-image: url(${LineHeader});
  height: 20px;
  padding: 1px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #2b5659;
  transition: color 0.3s ease;
`;

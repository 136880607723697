import { FC } from 'react';

import { Box } from '@mui/material';

import { createMockedArray } from '@/util/misc';

import { EmployeeCardSkeleton } from './EmployeeCardSkeleton';

interface EmployeeListLoaderProps {
  size?: number;
}

export const EmployeeListLoader: FC<EmployeeListLoaderProps> = ({
  size = 5
}) => (
  <>
    {createMockedArray(size).map((_, index) => (
      <Box key={index} mb='22px'>
        <EmployeeCardSkeleton />
      </Box>
    ))}
  </>
);

import styled from '@emotion/styled';
// import { styled as muiStyled } from '@mui/material';

export const CardWrapper = styled.div`
  padding: 60px;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 500px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 50px 0 rgba(0, 13, 79, 0.1);
`;

export const CardHeader = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  gap: 12px;
`;

export const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  gap: 32px;
`;

export const Title = styled.div`
  margin-top: 8px;
  color: #061a51;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
`;

export const Text = styled.div`
  margin-bottom: 8px;
  color: #27262b;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 130%;
`;

export const HeadBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

// export const HeadTitle = muiStyled('div')(({ theme }) => ({
//   ...theme.typography.labelSmallMedium,
//   color: '#222222',
//   fontSize: '26px',
//   width: '74px'
// }));

import { useNavigate } from 'react-router-dom';

import { ReactComponent as SuccessBg } from '@/assets/images/backgrounds/success.svg';
import { FinalCard } from '@/components';
import { Pages } from '@/constants';

export const SuccessChangePasswordPage = () => {
  const navigate = useNavigate();

  return (
    <FinalCard
      img={<SuccessBg />}
      onSecondButtonClick={() => navigate(Pages.SIGN_IN)}
      secondButtonName='Back to Sign In'
      text='Your password has been changed successfully, and you can now use your new password to Sign in.'
      title='Password changed!'
    />
  );
};

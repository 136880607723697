import * as Yup from 'yup';

export const employeeDefaultValues = {
  seniority: {
    id: 0,
    name: ''
  },
  employeeSpecializations: {
    id: 0,
    name: ''
  }
};

export const specializationsValidateSchema = Yup.object({
  employeeSpecializations: Yup.object()
    .shape({
      id: Yup.number().required('Please select specialization'),
      name: Yup.string().required('Please select specialization')
    })
    .required('Please select specialization'),
  seniority: Yup.object()
    .shape({
      id: Yup.number().required('Please select seniority'),
      name: Yup.string().required('Please select seniority')
    })
    .required('Please select seniority')
}).required();

import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { MainSearchStyles } from '@/components';
import { Pages } from '@/constants';
import { useGroupedOptions } from '@/hooks/groupOptions';
import { ThemedContainer } from '@/providers';

import { DictionaryOption } from '@/types/dictionary';

import MainSearch from './mainSearch';
import { Wrapper, Container, Tab, Title } from './styles';
import { TabPanel } from './tabPanel';
import TabsComponent from './tabsComponent';

interface IMainPage {
  _test_fn?: () => void;
}

const tabLabels: string[] = ['Search for Candidates', 'Search for Contracts'];

export const MainPage: FC<IMainPage> = ({ _test_fn }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();
  const groupedOptions = useGroupedOptions();

  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleOptionSelect = (selectedValue: DictionaryOption) => {
    navigate(
      `${Pages.CANDIDATE}?${selectedValue.group.toLocaleLowerCase()}=${
        selectedValue.value
      }`
    );
    _test_fn?.();
  };

  return (
    <ThemedContainer theme='default'>
      <Wrapper>
        <Container>
          <TabsComponent
            disabled={[false, true]}
            handleChangeTab={handleChangeTab}
            labels={tabLabels}
            selectedTab={selectedTab}
          />
          <Tab>
            <TabPanel index={0} value={selectedTab}>
              <Title variant='displayLarge'>
                Find a specialist for your next project
              </Title>
              <MainSearch
                onOptionSelect={handleOptionSelect}
                options={groupedOptions}
                styles={MainSearchStyles}
              />
            </TabPanel>
            <TabPanel index={1} value={selectedTab}>
              {/* Content for the second tab */}
            </TabPanel>
          </Tab>
        </Container>
      </Wrapper>
    </ThemedContainer>
  );
};

import styled from '@emotion/styled';
import {
  styled as muiStyled,
  TextareaAutosize,
  FormControl
} from '@mui/material';

import { Typography } from '@/components';
import { transientOptions } from '@/util/emotion';
import { PALETTE } from '@/util/palette';

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const DateTimeControl = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

export const Container = styled.div`
  margin: 65px auto 103px;
  padding-bottom: 103px;
  width: 680px;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
`;

export const Title = muiStyled(Typography)(({ theme }) => ({
  ...theme.typography.titleLarge
}));

export const SubTitle = muiStyled(Typography)(({ theme }) => ({
  ...theme.typography.bodyMedium,
  color: theme.palette.text.tertiary
}));

export const Border = styled.div`
  width: 100%;
  border-top: 1px solid var(--grey-border);
  margin-top: 15px;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;

interface InterviewTextareaProps {
  $error?: boolean;
}

export const InterviewTextarea = styled(
  TextareaAutosize,
  transientOptions
)<InterviewTextareaProps>`
  border-radius: 4px;
  border: 1px solid
    ${({ $error }) => ($error ? PALETTE.errorRed : PALETTE.border)};
  width: 100%;
  max-width: 680px;
  height: 56px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 16px;
  resize: none;

  &:hover {
    border-color: #537A00};
  }

  &:focus {
    outline: none;
    border-color: #A7F582;
  }

  &::placeholder {
    color: var(--3-d-3-d-4-e, #3d3d4e);
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  }
`;

export const InterviewTextareaWrapper = styled(FormControl)`
  width: 100%;
`;

export const ButtonSection = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;

export const SkillsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SkillsPurple = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  background: ${PALETTE.purple};
  border-radius: 2px;
  text-transform: none;
  box-shadow: none;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid ${PALETTE.purple};

  & p {
    font-size: 16px;
    line-height: 130%;
    font-style: normal;
    font-weight: 500;
    color: ${PALETTE.dark};
    display: flex;
    align-items: center;
  }
`;

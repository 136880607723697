import { FC } from 'react';

import { BrowserRouter, Route, Routes as DomRoutes } from 'react-router-dom';

import { RequireAuth } from '@/router/guards/AuthGuard';
import { Layout } from '@/router/layouts';

import { PRIVATE, PUBLIC } from './routes';

export const Routes: FC = () => (
  <BrowserRouter>
    <DomRoutes>
      <Route element={<Layout />} path='/'>
        {/* INFO: ALL PRIVATE PATHS */}
        <Route element={<RequireAuth />}>
          {PRIVATE.map(({ path, element }, index) => (
            <Route element={element} key={index} path={path} />
          ))}
        </Route>

        {/* INFO: ALL PUBLIC PATHS */}
        {PUBLIC.map(({ path, element }, index) => (
          <Route element={element} key={index} path={path} />
        ))}
      </Route>
    </DomRoutes>
  </BrowserRouter>
);

import { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';

import { BackToTop, CardEmployee, Typography } from '@/components';
import { Pages } from '@/constants';
import { useAuth } from '@/hooks';
import { TitleSkeleton } from '@/page/candidate/_components/CandidatesLoader';
import { useLazyGetAllEmployeesQuery } from '@/redux/api/employee/employeeApi';
import { PALETTE } from '@/util/palette';

import { EmployeeList } from '@/types/employee';

import { EmployeeListLoader } from './_components/EmployeeLoader/EmployeeListLoader';
import { CreateEmp, HeaderList, MainWrapper, WrapperEmpty } from './style';

const PAGE_SIZE = 5;

export const Employees = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const hasMore = useRef(true);

  const [getInitEmployee, { isLoading }] = useLazyGetAllEmployeesQuery();
  const [getNewEmployee, { isLoading: isMoreLoading }] =
    useLazyGetAllEmployeesQuery();

  const [showBackToTop, setShowBackToTop] = useState(true);

  const [employee, setEmployee] = useState<EmployeeList>({
    hasNextPage: false,
    hasPreviousPage: false,
    pageIndex: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
    items: []
  });

  useEffect(() => {
    getInitEmployee({
      pageIndex: 1,
      pageSize: PAGE_SIZE
    }).then(({ data }) => {
      setEmployee((prev) => ({ ...prev, ...data }));
    });
  }, [getInitEmployee]);

  const loadMore = (page: number) => {
    if (hasMore.current) {
      hasMore.current = false;

      getNewEmployee({
        pageIndex: page,
        pageSize: PAGE_SIZE
      }).then(({ data }) => {
        setEmployee(
          (prev) =>
            ({
              ...data,
              items: data?.items ? [...prev.items, ...data.items] : prev.items
            } as EmployeeList)
        );
        hasMore.current = true;
      });
    }
  };

  const employees = employee?.items ?? [];

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setShowBackToTop(scrollY > 0);
  }, []);

  useEffect(() => {
    let prevScrollY = 0;
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const isScrollingUp = scrollY < prevScrollY;
      prevScrollY = scrollY;
      if (isScrollingUp) {
        handleScroll();
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <MainWrapper>
      {!isLoading ? (
        <>
          <HeaderList>
            <Typography variant='displayMedium'>
              Employees {employee?.totalCount}
            </Typography>
            {token && (
              <CreateEmp onClick={() => navigate(`${Pages.EMPLOYEE_CREATE}`)}>
                Create Employee
              </CreateEmp>
            )}
          </HeaderList>

          {employees.length === 0 && (
            <WrapperEmpty borderColor={PALETTE.lightGrey} variant='titleMedium'>
              <p>Employee list is empty</p>
            </WrapperEmpty>
          )}

          <InfiniteScroll
            hasMore={employee?.hasNextPage && hasMore.current}
            loadMore={loadMore}
            pageStart={1}
          >
            <>
              {employees?.map((item, index) => (
                <CardEmployee
                  dataInfo={item}
                  key={index}
                  text='Profile Information'
                />
              ))}
              {isMoreLoading && <EmployeeListLoader size={2} />}
            </>
          </InfiniteScroll>
        </>
      ) : (
        <>
          <Box mb='26px'>
            <TitleSkeleton />
          </Box>
          <EmployeeListLoader />
        </>
      )}
      {showBackToTop && <BackToTop />}
    </MainWrapper>
  );
};

import { FC } from 'react';

import { styled, Box, Skeleton } from '@mui/material';

import { createMockedArray } from '@/util/misc';

const StyledSkeleton = styled(Skeleton)`
  transform: none;
`;

const DarkSkeleton = styled(StyledSkeleton)(({ theme }) => ({
  display: 'block',
  background: theme.palette.stroke['20'],
  borderRadius: '2px'
}));

export const EmployeeCardSkeleton: FC = () => (
  <Box
    borderRadius='4px'
    display='flex'
    gap='16px'
    height='204px'
    padding='28px'
    sx={{ background: (theme) => theme.palette.stroke['10'] }}
    width='100%'
  >
    <Box>
      <StyledSkeleton
        animation='wave'
        sx={{ background: (theme) => theme.palette.stroke['20'] }}
        variant='circular'
      >
        <Box height='96px' width='96px' />
      </StyledSkeleton>
    </Box>

    <Box display='flex' flexDirection='column' width='100%'>
      <Box
        align-items='flex-start'
        display='flex'
        flex='1 0 0'
        gap='16px'
        justifyContent='space-between'
      >
        <Box display='flex' flexDirection='column'>
          <Box mb='22px'>
            <DarkSkeleton
              animation='wave'
              height='18px'
              variant='circular'
              width='126px'
            />
          </Box>
          <Box columnGap='8px' display='flex' mb='19px'>
            <DarkSkeleton animation='wave' height='18px' width='81px' />
            <DarkSkeleton animation='wave' height='18px' width='109px' />
            <DarkSkeleton animation='wave' height='18px' width='72px' />
          </Box>
        </Box>
        <DarkSkeleton animation='wave' height='32px' width='127px' />
      </Box>

      <Box display='flex' gap='8px' mb='24px'>
        {createMockedArray(2).map((_, index) => (
          <DarkSkeleton
            animation='wave'
            height='18px'
            key={index}
            width='105px'
          />
        ))}
      </Box>

      <Box display='flex' gap='8px'>
        {createMockedArray(6).map((_, index) => (
          <DarkSkeleton
            animation='wave'
            height='32px'
            key={index}
            width='81px'
          />
        ))}
      </Box>
    </Box>
  </Box>
);

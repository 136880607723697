import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';

import { ISignUpFields } from '@/types/signupFields';

interface TFormData {
  email: string;
  firstName: string;
  password: string;
}

interface EFormData {
  firstName: string;
  lastName: string;
  price: string;
  priceWithMarkup: string;
  employeeStatusId: number;
  seniorityId: number;
  seniorityName: string;
  about: string;
  imageUrl: string;
  employeeStatus: {
    id: number;
    name: string;
  };
  seniority: {
    id: number;
    name: string;
  };
  employeeSkills: {
    id: number;
    name: string;
  }[];
  employeeSpecializations: {
    id: number;
    name: string;
  };

  employeeCountries: {
    id: number;
    name: string;
  };
  employeeLanguageLevels: {
    name?: string;
    language: {
      id: number;
      name: string;
    };
    languageLevel: {
      id: number;
      name: string;
    };
  }[];
}

interface IFormContext {
  step: number;
  setStep?: any;
  onHandleNext: () => void;
  onHandleBack: () => void;
  formData: TFormData;
  employeeData: any;
  resetEmployeeData?: () => void;
  setFormData: Dispatch<SetStateAction<TFormData>>;
  setEmployeeData: Dispatch<SetStateAction<any>>;
}

interface ISignUpFormContext {
  step: number;
  formData: ISignUpFields;
  setStep?: Dispatch<SetStateAction<StepsSignUp>>;
  setFormData: Dispatch<SetStateAction<ISignUpFields>>;
  onHandleNext: () => void;
  onHandleBack: () => void;
  resetFormData: () => void;
}

interface IProps {
  children: ReactNode;
  value?: {
    onHandleNext: () => void;
  };
}

export enum StepsSignUp {
  EMAIL = 1,
  NAME,
  PASSWORD,
  RESEND_EMAIL
}

export enum StepsEmployee {
  BASEINFO = 1,
  SPECIALIZATION,
  STATUS,
  SKILLS
}

export const SignUpFormContext = createContext<ISignUpFormContext>({
  formData: {
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  },
  step: StepsSignUp.EMAIL,
  onHandleNext: () => {},
  onHandleBack: () => {},
  setFormData: () => {},
  setStep: () => {},
  resetFormData: () => {}
});

const initialEmployeeData = {
  firstName: '',
  lastName: '',
  price: '',
  priceWithMarkup: '',
  employeeStatusId: 0,
  seniorityId: 0,
  seniorityName: '',
  about: '',
  imageUrl: '',
  employeeStatus: {
    id: 0,
    name: ''
  },
  seniority: {
    id: 0,
    name: ''
  },
  employeeSkills: [],
  employeeSpecializations: {
    id: 0,
    name: ''
  },
  employeeCountries: {
    id: 0,
    name: ''
  },
  employeeLanguageLevels: [
    {
      language: { id: 0, name: '' },
      languageLevel: { id: 0, name: '' }
    }
  ]
};

export const EmployeeFormContext = createContext<IFormContext>({
  step: StepsEmployee.BASEINFO,
  onHandleNext: () => {},
  onHandleBack: () => {},
  formData: {
    email: '',
    firstName: '',
    password: ''
  },
  employeeData: initialEmployeeData,
  resetEmployeeData: () => {},
  setFormData: () => {},
  setEmployeeData: () => {}
});

export function SignUpFormProvider({ children }: IProps) {
  const initialSignUpFormData = {
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  };
  const [step, setStep] = useState<StepsSignUp>(StepsSignUp.EMAIL);
  const [formData, setFormData] = useState<ISignUpFields>(
    initialSignUpFormData
  );

  function onHandleNext() {
    setStep((prevValue) => prevValue + 1);
  }

  function onHandleBack() {
    setStep((prevValue) => prevValue - 1);
  }

  function resetFormData() {
    setFormData(initialSignUpFormData);
    setStep(StepsSignUp.EMAIL);
  }

  return (
    <SignUpFormContext.Provider
      value={{
        step,
        formData,
        setStep,
        onHandleNext,
        onHandleBack,
        setFormData,
        resetFormData
      }}
    >
      {children}
    </SignUpFormContext.Provider>
  );
}

export const EmployeeFormProvider = ({ children }: IProps) => {
  const [step, setStep] = useState<StepsEmployee>(StepsEmployee.BASEINFO);
  const [employeeData, setEmployeeData] = useState<EFormData>({
    firstName: '',
    lastName: '',
    price: '',
    priceWithMarkup: '',
    employeeStatusId: 0,
    seniorityId: 0,
    seniorityName: '',
    about: '',
    imageUrl: '',
    employeeStatus: {
      id: 0,
      name: ''
    },
    seniority: {
      id: 0,
      name: ''
    },
    employeeSkills: [],
    employeeSpecializations: {
      id: 0,
      name: ''
    },
    employeeCountries: {
      id: 0,
      name: ''
    },
    employeeLanguageLevels: [
      {
        language: { id: 0, name: '' },
        languageLevel: { id: 0, name: '' }
      }
    ]
  });

  function resetEmployeeData() {
    setEmployeeData(initialEmployeeData);
    setStep(1);
  }

  function onHandleNext(): void {
    setStep((prevValue) => prevValue + 1);
  }

  function onHandleBack() {
    setStep((prevValue) => prevValue - 1);
  }

  return (
    <EmployeeFormContext.Provider
      value={{
        step,
        formData: {
          email: '',
          password: '',
          firstName: ''
        },
        employeeData,
        setFormData: () => {},
        onHandleNext,
        onHandleBack,
        setEmployeeData,
        resetEmployeeData
      }}
    >
      {children}
    </EmployeeFormContext.Provider>
  );
};

export const useSignUpFormState = () => useContext(SignUpFormContext);

export const useEmployeeFormState = () => useContext(EmployeeFormContext);

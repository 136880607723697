export const getRequirementList = <T extends { id: number; name: string }>(
  searchParam: string,
  requirements: T[]
): T[] => {
  const list: T[] = [];
  searchParam.split(',').forEach((el: string) => {
    requirements.forEach((spec) => {
      if (spec.id === Number.parseInt(el, 10)) {
        list.push({
          id: spec.id,
          name: spec.name
        } as T);
      }
    });
  });

  return list;
};

export interface IDateTimePair {
  selectedDate: Date;
  selectedTime: string;
}

interface IInterviewTimeRange {
  interviewTimeStart: string;
  interviewTimeEnd: string;
  timeZone: string;
}

export const getTimeRanges = (intervals: IDateTimePair[]) => {
  const result: IInterviewTimeRange[] = [];
  intervals.forEach((item) => {
    if (item.selectedDate) {
      const timeStart = new Date(item.selectedDate);
      const timeEnd = new Date(item.selectedDate);
      timeStart.setHours(
        Number.parseInt(item.selectedTime.split(' - ')[0].split(':')[0], 10)
      );
      timeStart.setMinutes(
        Number.parseInt(item.selectedTime.split(' - ')[0].split(':')[1], 10)
      );
      timeStart.setSeconds(0);
      timeEnd.setHours(
        Number.parseInt(item.selectedTime.split(' - ')[1].split(':')[0], 10)
      );
      timeEnd.setMinutes(
        Number.parseInt(item.selectedTime.split(' - ')[1].split(':')[1], 10)
      );
      timeEnd.setSeconds(0);
      result.push({
        interviewTimeStart: timeStart.toISOString(),
        interviewTimeEnd: timeEnd.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
    }
  });

  return result;
};

/** @jsxImportSource @emotion/react */

import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OrFrame } from '@/assets/images/icon';
import { Button, HelperText, GoogleLoginButton } from '@/components';
import { Pages, emailValidationSchema } from '@/constants';
import { useSignUpFormState } from '@/context/formContext';
import { useAuth } from '@/hooks';
import {
  WrapperBtnLogin,
  SignInForm,
  OrStroke,
  ButtonNextWrapper
} from '@/page/(auth)/signUp/styles';
import {
  useIsEmailAlreadyRegisteredMutation,
  useSignUpGoogleMutation
} from '@/redux/api/auth/authApi';
import { DEFAULT_COOKIE_KEY, setDefaultAuthCookieKey } from '@/service/api';

import { ISignUpFields } from '@/types/signupFields';

interface EmailStepProps {
  _test_fn?: () => void;
}

export const EmailStep: FC<EmailStepProps> = ({ _test_fn }) => {
  const navigate = useNavigate();

  const { setToken } = useAuth();
  const [isEmailAlreadyRegistered] = useIsEmailAlreadyRegisteredMutation();
  const [signUpGoogle] = useSignUpGoogleMutation();

  const { onHandleNext, setFormData, formData } = useSignUpFormState();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ISignUpFields & { email: string }>({
    mode: 'all',
    defaultValues: formData as { email: string },
    resolver: yupResolver(emailValidationSchema)
  });

  const onSubmit = async (data: { email: string }) => {
    isEmailAlreadyRegistered({ email: data.email })
      .unwrap()
      .then((response) => {
        if (!response.isEmailAlreadyRegistered) {
          setFormData((prevFormData) => ({ ...prevFormData, ...data }));
          onHandleNext();
          _test_fn?.();
          return;
        }

        setError('email', { message: 'Email is already registered' });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const googleSignUpHandler = ({ credential }: CredentialResponse) => {
    if (credential) {
      signUpGoogle({
        idToken: credential
      })
        .then(() => {
          setToken(DEFAULT_COOKIE_KEY);
          setDefaultAuthCookieKey();
          navigate(Pages.HOME);
        })
        .catch((error) => {
          setError('email', { message: error?.data?.Message });
        });
    }
  };

  return (
    <SignInForm onSubmit={handleSubmit(onSubmit)}>
      <Box mt='32px' width='100%'>
        <TextField
          autoComplete='off'
          fullWidth
          label='Email'
          type='email'
          variant='outlined'
          {...register('email')}
          error={!!errors.email}
        />
        <HelperText text={errors.email?.message} />
      </Box>
      <ButtonNextWrapper>
        <Button fullWidth size='large' text='Next' type='submit' />
      </ButtonNextWrapper>
      <OrStroke>
        <OrFrame />
      </OrStroke>

      <Box mt='24px' width='100%'>
        <Button
          fullWidth
          size='large'
          text='Continue with Google'
          variant='google'
        />
        <WrapperBtnLogin>
          <GoogleLoginButton onSuccess={googleSignUpHandler} />
        </WrapperBtnLogin>
      </Box>
    </SignInForm>
  );
};

import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const MainWrapper = styled.div`
  position: relative;
`;

export const WrapperBtnLogin = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  padding-top: 8px;
  height: 56px;
`;

export const OrStroke = styled.div`
  margin-top: 24px;
  color: #9a9aa4;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export const ForgotLink = styled(Link)`
  margin-top: 12px;
  color: #2146af;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

import styled from '@emotion/styled';
import { Button } from '@mui/material';

import GoogleSvg from '@/assets/images/icon/Google.svg';
import LeftArrowSvg from '@/assets/images/icon/LeftArrow.svg';
import AddPhotoSvg from '@/assets/images/icon/addPhoto.svg';
import {
  button,
  buttonCardEmployee,
  buttonCrEmp,
  buttonNext
} from '@/style/mixin';

import { Size } from '..';

const SIZE: Record<Size, string> = {
  small: '32px',
  medium: '40px',
  large: '52px'
};

export const DefaultBtn = styled(Button)<{ size: Size; fullWidth?: boolean }>`
  height: ${({ size }) => SIZE[size]};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  padding: 10px 14px;
  background: #c8f860;
  border: 1px solid #70a500;
  border-radius: 4px;
  text-transform: none;
  box-shadow: none;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background: #aeea2e;
    filter: brightness(0.85);
    box-shadow: none;
  }
  & p {
    ${button};
    color: #2b5659;
    display: flex;
    align-items: center;
  }
`;

export const GoogleBtn = styled(DefaultBtn)`
  background: var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    line-height: initial;
    color: #2b5659;
  }
  & p::before {
    content: '';
    background: url(${GoogleSvg});
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export const IconButton = styled(DefaultBtn)`
  height: 56px;
  padding: 6px 16px;
  background: var(--white);
  border-radius: 3px;

  &:hover {
    background: var(--white);
  }
  & p {
    color: #2b5659;
  }
`;

export const Cv = styled(DefaultBtn)`
  & p {
    ${buttonCardEmployee};
  }
`;

export const Clear = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    color: #2b5659;
  }
`;

export const ClearBorder = styled(DefaultBtn)`
  background: var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    color: #2b5659;
  }
`;

export const Next = styled(DefaultBtn)`
  background: #c8f860;
  border: 1px solid #70a500;

  &:hover {
    background: #aeea2e;
    border: 1px solid #537a00;
  }

  & p {
    ${buttonNext};
    color: #2b5659;
  }
`;

export const Prev = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid #70a500;

  &:hover {
    background: var(--white);
    border: 1px solid #537a00;
  }
  & p {
    ${buttonNext};
    color: #2b5659;
  }
`;

export const Hire = styled(DefaultBtn)`
  background: var(--orange-light);
  border: 1px solid var(--orange-light);

  &:hover {
    background: var(--orange-light);
  }
  & p {
    ${buttonCardEmployee};
    color: var(--black);
  }
`;

export const Employees = styled(DefaultBtn)`
  background: initial;
  border: 1px solid #70a500;

  &:hover {
    background: #eaffe4;
  }

  & p {
    ${buttonCardEmployee};
    color: #2b5659;
  }
`;

export const AddPhoto = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    line-height: initial;
    color: #2b5659;
    justify-content: center;
    flex-direction: row;
    letter-spacing: -0.7px;
  }
  & p::before {
    content: '';
    background: url(${AddPhotoSvg});
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export const CreateEmployee = styled(DefaultBtn)`
  background: initial;
  border: 1px solid #70a500;

  &:hover {
    background: #eaffe4;
  }
  & p {
    ${buttonCrEmp};
    color: #2b5659;
  }
`;

export const BackButton = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    line-height: initial;

    color: #2b5659;
    justify-content: center;
    flex-direction: row;
    letter-spacing: -0.7px;
  }

  & p::before {
    content: '';
    background: url(${LeftArrowSvg});
    background-size: cover;
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`;

export const Link = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid var(--white);

  &:hover {
    background: var(--white);
  }
  & p {
    color: #2b5659;
  }
`;

export const DownloadCV = styled(DefaultBtn)`
  background: var(--white);
  border: 1px solid var(--blue-light-middle);

  &:hover {
    background: var(--white);
  }
  & p {
    ${buttonNext};
    color: #2b5659;
  }
`;

export const Interview = styled(DefaultBtn)`
  background: initial;
  border: 1px solid #70a500;

  &:hover {
    background: #eaffe4;
  }

  & p {
    ${buttonNext};
    color: #2b5659;
  }
`;

export const Basic = styled(DefaultBtn)`
  padding: 6px 16px;
  background: #c8f860;
  border: 1px solid #70a500;

  &:hover {
    background: #aeea2e;
    border: 1px solid #537a00;
  }

  & p {
    color: #2b5659;
  }

  &.disabled {
    border: 1px solid var(--light-grey);
    background: var(--light-grey);
  }
`;

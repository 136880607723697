import { FC } from 'react';

import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CredWrapper,
  SalaryLanguage,
  Typography,
  UserAvatar
} from '@/components';
import { StorageKey, Pages } from '@/constants';
import { useAuth, useDictionary } from '@/hooks';

import { Employee } from '@/types/employee';

import {
  AboveWrapper,
  BtnEdit,
  InfoBlock,
  InfoLabel,
  Photo,
  Skills,
  SkillsWrapper,
  WrapperCard,
  WrapperAboveBottom,
  LabelContract,
  LabelActive
} from './styles';

interface ICardEmployee {
  dataInfo: Employee;
  isLabel?: boolean;
  text: string;
  additionalParams?: string;
  isEmployee?: boolean;
  onCardButtonClick?: (params?: string) => void;
}

const CardInterview: FC<ICardEmployee> = ({
  dataInfo,
  isLabel = true,
  text,
  additionalParams = '',
  isEmployee = true,
  onCardButtonClick
}) => {
  const { dictionaries } = useDictionary();
  const statusDictionary = dictionaries.EmployeeStatus;

  const isStatus = dataInfo.employeeStatus.name === statusDictionary[0]?.name;

  const navigate = useNavigate();

  let isVisibleBtn = 0;
  let countHideSkills = 0;

  const elementsArray = dataInfo.employeeSkills.slice();
  elementsArray.splice(0, 2);
  isVisibleBtn = elementsArray.length;
  countHideSkills = elementsArray.length;

  const { token } = useAuth();

  const getUser = async (id: number) => {
    navigate(
      isEmployee ? `${Pages.EMPLOYEE}/${id}` : `${Pages.CANDIDATE}/${id}`
    );
  };

  const handleCardButtonClick = (e: Event) => {
    e.stopPropagation();

    localStorage.setItem(
      StorageKey.ToRedirect,
      `${dataInfo.id + additionalParams}`
    );
    if (onCardButtonClick && token) {
      onCardButtonClick(additionalParams);
    } else {
      navigate(Pages.SIGN_IN);
    }
  };

  return (
    <WrapperCard onClick={() => getUser(dataInfo.id)}>
      <Photo>
        <UserAvatar
          firstName={dataInfo.firstName}
          imageUrl={dataInfo.imageUrl}
          lastName={dataInfo.lastName}
          size='xl'
        />
      </Photo>
      <InfoBlock>
        <InfoLabel>
          <WrapperAboveBottom>
            <AboveWrapper>
              <Typography
                color='text.primary'
                mr='12px'
                variant='headlineLarge'
              >
                {dataInfo.firstName} {dataInfo.lastName}
              </Typography>
              {isLabel && isStatus && (
                <LabelContract color='stroke.30' variant='labelMediumRegular'>
                  {dataInfo.employeeStatus.name}
                </LabelContract>
              )}
              {isLabel && !isStatus && (
                <LabelActive color='green.400' variant='labelMediumRegular'>
                  {dataInfo.employeeStatus.name}
                </LabelActive>
              )}
            </AboveWrapper>
            <CredWrapper
              mainInfo={{
                specialization: dataInfo.employeeSpecializations[0].name,
                seniority: dataInfo.seniority.name,
                location: dataInfo.employeeCountries[0].name
              }}
            />
          </WrapperAboveBottom>
          <BtnEdit>
            <Button
              onClick={handleCardButtonClick}
              size='small'
              text={text}
              variant='employees'
            />
          </BtnEdit>
        </InfoLabel>

        <Box my='20px'>
          <SalaryLanguage
            mainData={{
              price: dataInfo.price,
              language: dataInfo.employeeLanguageLevels[0]?.language.name,
              level: dataInfo.employeeLanguageLevels[0]?.languageLevel.name
            }}
          />
        </Box>

        <SkillsWrapper>
          {dataInfo.employeeSkills.slice(0, 2).map((skills: any) => (
            <Skills key={skills.id}>{skills.name}</Skills>
          ))}
          {!!isVisibleBtn && <Skills>+{countHideSkills} Skills</Skills>}
        </SkillsWrapper>
      </InfoBlock>
    </WrapperCard>
  );
};

export { CardInterview };

import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { Sent } from '@/assets/images/photo';
import { Button, Header } from '@/components';
import { Pages } from '@/constants';

import { ForgotForm } from './forgotForm';
import { ImgSent, TitleSent } from './styles';

export const ForgotPassword: FC = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleFormSubmit = () => {
    setSubmitted(true);
  };

  return (
    <>
      {submitted ? (
        <Header>
          <ImgSent>
            <Sent />
          </ImgSent>
          <TitleSent mt='24px'>Link has been sent</TitleSent>
          <Box mt='32px' textAlign='center'>
            A link to reset password has been sent to your email.
          </Box>
          <Box mt='24px' width='100%'>
            <Link data-testid='link-to-home' to={Pages.HOME}>
              <Button
                fullWidth
                size='large'
                text='Resend an email'
                variant='clearBorder'
              />
            </Link>
          </Box>
          <Box mt='32px'>
            <Link to={Pages.SIGN_IN}>
              <Button
                fullWidth
                size='large'
                text='Back to Sign In'
                variant='clear'
              />
            </Link>
          </Box>
        </Header>
      ) : (
        <Header
          description='Enter your email. We will email instructions on how to reset your password.'
          title='Forgot Password?'
        >
          {!submitted && <ForgotForm onFormSubmit={handleFormSubmit} />}
        </Header>
      )}
    </>
  );
};

import styled from '@emotion/styled';

import BackToTopImg from '@/assets/images/photo/BackToTop.svg';

const StyledImage = styled.img`
  position: fixed;
  bottom: 50px;
  right: 70px;
  z-index: 3;
  cursor: pointer;
`;

export const BackToTop = () => {
  const toTop = async () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  return (
    <StyledImage
      alt='top'
      height={65}
      onClick={toTop}
      src={BackToTopImg}
      width={84}
    />
  );
};

import { createTheme, Theme } from '@mui/material';

import { defaultPalette } from '@/providers/themeProvider/palettes/default';
import { defaultTypography } from '@/providers/themeProvider/typography/typography';

const customTheme = (outerTheme: Theme) =>
  createTheme({
    typography: defaultTypography,
    palette: {
      mode: outerTheme.palette.mode,

      ...defaultPalette
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            displayLarge: 'h1',
            displayMedium: 'h2',
            headlineLarge: 'h3',
            headlineMedium: 'h4',
            titleLarge: 'p',
            titleMedium: 'p',
            titleSmallSemi: 'p',
            titleSmall: 'p',
            bodyXLarge: 'p',
            bodyLarge: 'p',
            bodyLargeSemi: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
            bodyXSmall: 'p',
            labelLargeSemi: 'p',
            labelXLarge: 'p',
            labelLarge: 'p',
            labelMedium: 'p',
            labelMediumRegular: 'p',
            labelSmallRegular: 'p',
            linkMedium: 'p',
            linkSmall: 'p',
            overline: 'p'
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&:before': {
              borderBottom: '2px solid var(--TextField-brandBorderColor)'
            },
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderBottom: '2px solid var(--TextField-brandBorderHoverColor)'
            },
            '&.Mui-focused:after': {
              borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)'
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              borderColor: 'rgba(0, 0, 0, 0.12)'
            }
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': '#70A500',
            '--TextField-brandBorderHoverColor': '#537A00',
            '--TextField-brandBorderFocusedColor': 'A7F582',
            '& label': {
              color: '#3D3D4E'
            },
            '& label.Mui-focused': {
              color: '#031F6F'
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#3D3D4E'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)'
          },
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--TextField-brandBorderHoverColor)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--TextField-brandBorderFocusedColor)'
            }
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            '&::-webkit-scrollbar': {
              width: '5px' /* ширина всей полосы прокрутки */
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent' /* цвет зоны отслеживания */
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#DCDCDE' /* цвет бегунка */,
              borderRadius: '5px' /* округлось бегунка */,
              width: '3px'
            }
          }
        }
      }
    }
  });

export default customTheme;

import * as Yup from 'yup';

import { NO_SPECIAL_CHARS_REGEX } from '@/constants';

export const employeeDefaultValues = {
  firstName: '',
  lastName: '',
  employeeCountries: {
    id: 0,
    name: ''
  }
};
const minMaxFirstName =
  'Sorry, first name must be between 2 and 32 characters long';
const minMaxLastName =
  'Sorry, last name must be between 2 and 32 characters long';
const validMessage = 'Are you sure you entered your name correctly?';
export const baseValidateSchema = Yup.object({
  firstName: Yup.string()
    .label('First Name')
    .trim()
    .required('Please enter a first name')
    .min(2, minMaxFirstName)
    .max(32, minMaxFirstName)
    .matches(NO_SPECIAL_CHARS_REGEX, validMessage),
  lastName: Yup.string()
    .label('Last Name')
    .trim()
    .required('Please enter a last name')
    .min(2, minMaxLastName)
    .max(32, minMaxLastName)
    .matches(NO_SPECIAL_CHARS_REGEX, validMessage),
  employeeCountries: Yup.object()
    .shape({
      id: Yup.number().required('Please add a location'),
      name: Yup.string().required('Please add a location')
    })
    .required('Please add a location')
}).required();

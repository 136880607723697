import { styled } from '@mui/material';

import CloseBtn from '@/assets/images/icon/CloseButton.svg';
import { ModalSize } from '@/components';

interface ModalStyleProps {
  active: boolean;
  variant?: ModalSize;
}

export const ModalContent = styled('div')<ModalStyleProps>(
  ({ theme, variant, active }) => ({
    minWidth: '368px',
    width: variant === 'big' ? '567px' : 'auto',
    backgroundColor: theme.palette.neutral?.white,
    borderRadius: '4px',
    transition: 'all 0.3s',
    transform: active ? 'scale(1)' : 'scale(0.5)',
    maxHeight: '90vh',
    overflow: 'auto'
  })
);

export const ModalHead = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '28px 22px',
  borderBottom: `1px solid ${theme.palette.stroke?.[10]}`
}));

export const ModalTitle = styled('div')(({ theme }) => ({
  ...theme.typography.titleLarge,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
}));

export const ModalIcon = styled('div')<Pick<ModalStyleProps, 'variant'>>`
  background-image: url(${CloseBtn});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: all 0.3s;
  width: ${({ variant }) => (variant === 'big' ? '24px' : '14px')};
  height: ${({ variant }) => (variant === 'big' ? '24px' : '14px')};

  :hover {
    transform: scale(1.1);
  }
`;

import { StylesConfig } from 'react-select';

export const MainSearchStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #70A500',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '8px 4px 8px 18px',
    minHeight: '66px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    ':hover': {
      border: '1px solid #537A00'
    }
  }),
  input: (provided) => ({ ...provided, color: '#2B5659' }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    backgroundColor: isSelected ? '#EAFFE4' : isFocused ? '#D9F4FF' : 'white',
    color: '#2B5659',
    padding: '17px 64px 17px 58px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    ':hover': {
      backgroundColor: '#D9F4FF',
      color: '#2B5659'
    }
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'blue',
    clearIndicatorLabel: 'Clear All'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'black',
    border: '1px solid #F2D8BA',
    borderRadius: '2px',
    display: 'flex'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#27262B',
    padding: '6px 12px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '120%'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#AFB6B7',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%'
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: '16px 64px 16px 58px',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '135%',
    letterSpacing: '0.91px'
  })
};

export const CandidateSearchStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    border: '1px solid #70A500',
    borderRadius: '4px',
    boxShadow: 'none',
    padding: '8px 4px 8px 18px',
    minHeight: '48px',
    color: '#2B5659',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '125%',
    ':hover': {
      border: '1px solid #537A00'
    }
  }),
  input: (provided) => ({ ...provided, color: '#2B5659' }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,

    backgroundColor: isSelected ? '#EAFFE4' : isFocused ? '#D9F4FF' : 'white',
    color: '#2B5659',
    padding: '17px 64px 17px 58px',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '120%',
    ':hover': {
      backgroundColor: '#D9F4FF',
      color: '#2B5659'
    }
  }),
  menu: (provided) => ({
    ...provided,
    width: '488px',
    left: '18%'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'blue',
    clearIndicatorLabel: 'Clear All'
  }),
  multiValue: (provided) => ({
    ...provided,
    background: '#EAFFE4',
    border: '1px solid #70A500',
    borderRadius: '2px',
    padding: '2px 6px 2px 12px',
    display: 'flex',
    color: '#2B5659'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    paddingRight: '6px !important',
    paddingLeft: '0 !important',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '145%'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#70A500',
    backgroundColor: 'transparent',
    ':hover': {
      color: '#2B5659',
      backgroundColor: 'transparent'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgba(25, 29, 46, 0.50)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '145%'
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: '16px 64px 16px 58px',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '135%',
    letterSpacing: '0.91px'
  })
};

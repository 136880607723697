import { IconButton, styled } from '@mui/material';
import ReactQuill from 'react-quill';

import Bullet from '@/assets/images/icon/text-editor/bullet.svg';
import Order from '@/assets/images/icon/text-editor/order.svg';
import { Typography } from '@/components';

export const StyledIconButton = styled(IconButton)(() => ({
  width: 'auto !important',
  height: 'auto !important',
  borderRadius: '2px !important'
}));

export const StyledOrderedIconButton = styled(StyledIconButton)(() => ({
  width: '28px !important',
  padding: 'none !important',
  height: '28px !important',
  background: `url(${Order}) no-repeat !important`,
  backgroundSize: 'contain',

  svg: {
    display: 'none'
  }
}));

export const StyledBulletIconButton = styled(StyledIconButton)(() => ({
  width: '28px !important',
  padding: 'none !important',
  height: '28px !important',
  background: `url(${Bullet}) no-repeat !important`,
  backgroundSize: 'contain',

  svg: {
    display: 'none'
  }
}));

export const FakePlaceholder = styled(Typography)<{
  focus: boolean;
  error: boolean;
  count: number;
}>(({ theme, focus, error, count }) => ({
  ...theme.typography[focus ? 'bodySmall' : 'bodyLarge'],
  background: theme.palette.neutral?.white,
  color: focus ? theme.palette.main.primary : theme.palette.text.tertiary,
  position: 'absolute',
  top: focus ? '-2px' : '21px',
  left: '16px',
  padding: '0 2px',
  transition: '0.25s ease',

  ...(count > 0 &&
    !focus && {
      display: 'none'
    }),

  ...(error && {
    color: theme.palette.main.wrong
  })
}));

export const ReactQuillWrapper = styled('div')<{
  focus: boolean;
  error: boolean;
}>(({ theme, focus, error }) => ({
  minHeight: '128px',
  maxHeight: '570px',
  border: `1px solid ${
    focus ? theme.palette.main.primary : theme.palette.stroke?.[20]
  } !important`,
  borderRadius: '4px',
  transition: 'all .25s ease',

  ...(error && {
    border: `1px solid ${theme.palette.main?.wrong}`
  }),

  '.ql-toolbar': {
    borderBottom: 'none !important',
    borderRight: 'none !important',
    borderLeft: 'none !important',
    borderTop: '1px solid inherit !important'
  },

  '.ql-editor': {
    minHeight: '85px'
  }
}));

export const StyledReactQuill = styled(ReactQuill)<{ focus: boolean }>(() => ({
  minHeight: '80px',

  '.ql-container': {
    maxHeight: '60vh',
    overflow: 'auto',
    minHeight: '85px',
    borderTop: '1px solid inherit !important',
    border: 'none !important',
    fontSize: '16px'
  },

  '.ql-editor ol, .ql-editor ul': {
    paddingLeft: '0px !important'
  }
}));

export const Count = styled(Typography)<{ error: boolean }>(
  ({ theme, error }) => ({
    ...theme.typography.bodySmall,
    color: error ? theme.palette.main.wrong : theme.palette.text.tertiary
  })
);

export const ToolbarBlock = styled('span')`
  margin-right: 8px;
`;

export const ReactQuillReadOnly = styled(ReactQuill)(() => ({
  '.ql-container': {
    border: 'none !important',
    fontSize: '16px'
  },

  '.ql-toolbar': {
    display: 'none'
  },

  '.ql-editor': {
    padding: '0px !important'
  },

  '.ql-editor ol, .ql-editor ul': {
    paddingLeft: '0px !important'
  }
}));

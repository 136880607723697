import React, { Dispatch, FC, SetStateAction } from 'react';

import { FiltersLoader } from '@/page/candidate/_components';
import {
  useGetTypeItemsQuery,
  useGetTypesQuery
} from '@/redux/api/dictionary/dictionaryApi';

import {
  FilterWrapper,
  MuiAccordionSummary,
  MuiAccordionDetails,
  MuiAccordion,
  Title,
  MuiCheckbox,
  AccordionText,
  MuiExpandMoreIcon
} from './styles';

export interface FilterProps {
  setFilter: Dispatch<SetStateAction<Record<string, number[]>>>;
  filter: any;
}

export const Filter: FC<FilterProps> = (props) => {
  const { setFilter, filter } = props;
  const { data: items, isLoading: isItemsLoading } = useGetTypeItemsQuery();
  const { data: types, isLoading: isTypesLoading } = useGetTypesQuery();

  const handleCheckboxChange = (
    typeName: string,
    id: number,
    isChecked: boolean
  ) => {
    setFilter((prevFilter: any) => {
      const updatedFilter = { ...prevFilter };
      const typeNameLower = typeName.toLocaleLowerCase();

      if (isChecked) {
        if (!updatedFilter[typeNameLower]) {
          updatedFilter[typeNameLower] = [];
        }

        if (!updatedFilter[typeNameLower].includes(id)) {
          updatedFilter[typeNameLower].push(id);
        }
      } else {
        if (updatedFilter[typeNameLower]) {
          updatedFilter[typeNameLower] = updatedFilter[typeNameLower].filter(
            (item: number) => item !== id
          );

          if (updatedFilter[typeNameLower].length === 0) {
            delete updatedFilter[typeNameLower];
          }
        }
      }

      return updatedFilter;
    });
  };

  return (
    <FilterWrapper>
      {!isItemsLoading && !isTypesLoading && types?.length ? (
        <>
          <Title>Filter by</Title>

          {types?.map((type) => (
            <MuiAccordion key={type.value}>
              <MuiAccordionSummary
                aria-controls='panel1a-content'
                expandIcon={<MuiExpandMoreIcon />}
                id='panel1a-header'
              >
                {type.label}
              </MuiAccordionSummary>
              {items?.[type.value - 1]?.items.map((item) => (
                <MuiAccordionDetails key={item.id}>
                  <MuiCheckbox
                    checked={
                      !!filter[type.name.toLocaleLowerCase()]?.includes(item.id)
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleCheckboxChange(
                        type.name,
                        item.id,
                        event.target.checked
                      );
                    }}
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fill: '#D6FA89'
                      }
                    }}
                  />
                  <AccordionText>{item.name}</AccordionText>
                </MuiAccordionDetails>
              ))}
            </MuiAccordion>
          ))}
        </>
      ) : (
        <FiltersLoader />
      )}
    </FilterWrapper>
  );
};

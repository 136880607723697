export const defaultPalette = {
  main: {
    primary: '#C8F860',
    accent: '#480AAC',
    wrong: '#FF1C0D',
    success: '#A7F582',
    black: '#2B5659'
  },
  neutral: {
    white: '#ffffff',
    black: '#000000'
  },
  primaryRoot: {
    greyDark: '#A6B3BA',
    greyBlock: '#F4F4F5',
    greyBorder: '#26283029',
    greySubtitle: '#71717a',
    lightGrey: '#D2D9DC',
    black: '#27262B',
    blackTitle: '#28262B',
    white: '#fff'
  },
  text: {
    primary: '#2B5659',
    secondary: '#4F6A6C',
    tertiary: '#7F8788',
    quaternary: '#AFB6B7',
    link: '#70A500'
  },
  green: {
    '20': '#F1F9F1',
    '50': '#E6F3E9',
    '100': '#B0D9BC',
    '200': '#8AC79B',
    '300': '#54AD6E',
    '400': '#339D51',
    '500': '#008526',
    '600': '#007923',
    '700': '#005E1B'
  },
  blue: {
    '50': '#F1F5FF',
    '100': '#BAC6E6',
    '200': '#99AADA',
    '300': '#6A83C9',
    '400': '#4D6BBF',
    '500': '#2146AF',
    '600': '#1E409F',
    '700': '#17327C',
    '800': '#122760',
    '900': '#0E1D4A'
  },
  red: {
    '50': '#FEEAEA',
    '100': '#FCBEBE',
    '200': '#FA9F9F',
    '300': '#F87373',
    '400': '#F75858',
    '500': '#F52E2E',
    '600': '#DF2A2A',
    '700': '#AE2121',
    '800': '#871919',
    '900': '#671313'
  },
  yellow: {
    '50': '#FDF7E6',
    '100': '#F8E7B2',
    '200': '#F5DC8D',
    '300': '#F1CC5A',
    '400': '#EEC239',
    '500': '#EAB308',
    '600': '#D5A307'
  },
  grey: {
    '20': '#F4F4F5',
    '50': '#E8E8EA',
    '100': '#B8B9BE',
    '200': '#95979F',
    '300': '#656873',
    '400': '#474A58',
    '500': '#191D2E',
    '600': '#171A2A',
    '700': '#121521',
    '800': '#0E1019',
    '900': '#0B0C13'
  },
  stroke: {
    '10': '#DCDCDE',
    '20': '#B8B9BE',
    '30': '#95979F',
    '40': '#656873',
    '50': '#474A58',
    '60': '#2146AF'
  },
  button: {
    '50': '#F1F3FB',
    '100': '#E9EDF7',
    '200': '#DBE7FF',
    '300': '#BBC6E6',
    '400': '#98AADA',
    '500': '#768DCE',
    '600': '#5471C1',
    '700': '#2146AF',
    '800': '#1E41A2',
    '900': '#193687',
    hover: '#5471C1',
    selected: '#1E41A2',
    disabled: '#E9EDF7'
  },
  overlay: {
    light: {
      '50': 'rgba(255, 255, 255, 0.05)',
      '100': 'rgba(255, 255, 255, 0.10)',
      '200': 'rgba(255, 255, 255, 0.20)',
      '300': 'rgba(255, 255, 255, 0.30)',
      '400': 'rgba(255, 255, 255, 0.40)',
      '500': 'rgba(255, 255, 255, 0.50)',
      '600': 'rgba(255, 255, 255, 0.60)',
      '700': 'rgba(255, 255, 255, 0.70)',
      '800': 'rgba(255, 255, 255, 0.80)',
      '950': 'rgba(255, 255, 255, 0.95)'
    },
    dark: {
      '50': 'rgba(27, 26, 29, 0.05)',
      '100': 'rgba(27, 26, 29, 0.10)',
      '200': 'rgba(27, 26, 29, 0.20)',
      '300': 'rgba(27, 26, 29, 0.30)',
      '400': 'rgba(27, 26, 29, 0.40)',
      '500': 'rgba(27, 26, 29, 0.50)',
      '600': 'rgba(27, 26, 29, 0.60)',
      '700': 'rgba(27, 26, 29, 0.70)',
      '800': 'rgba(27, 26, 29, 0.80)',
      '950': '#1B1A1D'
    }
  }
};

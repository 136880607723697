import { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Button,
  CredWrapper,
  SalaryLanguage,
  Typography,
  UserAvatar
} from '@/components';
import { useDictionary } from '@/hooks';

import { Employee } from '@/types/employee';

import {
  AboveWrapper,
  BtnEdit,
  InfoBlock,
  InfoLabel,
  Photo,
  Skills,
  SkillsWrapper,
  WrapperSalaryLanguage,
  WrapperAboveBottom,
  LabelContract,
  LabelActive,
  WrapperCard
} from './styles';

interface ICardEmployee {
  dataInfo: Employee;
  isLabel?: boolean;
  text: string;
  isEmployee?: boolean;
  _test_fn?: () => void;
}

export const CardEmployee: FC<ICardEmployee> = ({
  dataInfo,
  isLabel = true,
  text,
  isEmployee = true,
  _test_fn
}) => {
  const { dictionaries } = useDictionary();
  const statusDictionary = dictionaries.EmployeeStatus;

  const isStatus = dataInfo.employeeStatus.name === statusDictionary[0]?.name;

  let isVisibleBtn = 0;
  let countHideSkills = 0;

  const elementsArray = dataInfo.employeeSkills.slice();
  elementsArray.splice(0, 2);
  isVisibleBtn = elementsArray.length;
  countHideSkills = elementsArray.length;

  const navigate = useNavigate();
  const getUser = async (id: number) => {
    navigate(isEmployee ? `/employee/${id}` : `/candidate/${id}`);
    if (_test_fn) _test_fn();
  };

  const onCardButtonClick = (e: Event) => {
    e.stopPropagation();
    getUser(dataInfo.id);
  };

  return (
    <WrapperCard>
      <Photo>
        <UserAvatar
          firstName={dataInfo.firstName}
          imageUrl={dataInfo.imageUrl}
          lastName={dataInfo.lastName}
          size='xl'
        />
      </Photo>
      <InfoBlock>
        <InfoLabel>
          <WrapperAboveBottom>
            <AboveWrapper>
              <Typography color='blue.500' mr='12px' variant='headlineLarge'>
                {dataInfo.firstName} {dataInfo.lastName}
              </Typography>

              {isLabel && isStatus && (
                <LabelContract color='stroke.30' variant='labelMediumRegular'>
                  {dataInfo.employeeStatus.name}
                </LabelContract>
              )}
              {isLabel && !isStatus && (
                <LabelActive color='green.400' variant='labelMediumRegular'>
                  {dataInfo.employeeStatus.name}
                </LabelActive>
              )}
            </AboveWrapper>

            <CredWrapper
              mainInfo={{
                specialization: dataInfo.employeeSpecializations[0].name,
                seniority: dataInfo.seniority.name,
                location: dataInfo.employeeCountries[0].name
              }}
            />
          </WrapperAboveBottom>
          <BtnEdit>
            <Button
              onClick={onCardButtonClick}
              size='small'
              text={text}
              variant='employees'
            />
          </BtnEdit>
        </InfoLabel>

        <WrapperSalaryLanguage>
          <SalaryLanguage
            mainData={{
              price: dataInfo.price,
              language: dataInfo.employeeLanguageLevels[0]?.language.name,
              level: dataInfo.employeeLanguageLevels[0]?.languageLevel.name
            }}
          />
        </WrapperSalaryLanguage>
        <SkillsWrapper>
          {dataInfo.employeeSkills.slice(0, 2).map((skills: any) => (
            <Skills key={skills.id}>{skills.name}</Skills>
          ))}
          {!!isVisibleBtn && <Skills>+{countHideSkills} Skills</Skills>}
        </SkillsWrapper>
      </InfoBlock>
    </WrapperCard>
  );
};

export const PALETTE = {
  primaryBlue: '#2146af',
  primary: '#C8F860',
  dark: '#2B5659',
  grey: '#A1A1AA',
  secondaryGrey: '#bdbdbd',
  lightGrey: '#dcdcde',
  logoGrey: '#e7eaf6',
  blueLight: '#f2f8fd',
  blueLightMiddle: '#bbc6e6',
  greyDark: '#3d3d4e',
  greyBlock: '#f4f4f5',
  stroke1: '#B8B9BE',
  greyBorder: '#26283029',
  black: '#27262b',
  darkTitle: '#2B5659',
  darkText: '#4F6A6C',
  orangeLight: '#fff7ee',
  orangeLightMiddle: '#f2d8ba',
  white: '#fff',
  errorRed: '#F87171',
  mainWrong: '#AE2121',
  link: '#70A500',
  purple: '#E8D9FF',
  border: '#70A500'
};

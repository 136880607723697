import { FC } from 'react';

import styled from '@emotion/styled';
import { Box, Skeleton } from '@mui/material';

import { PALETTE } from '@/util/palette';

import { CandidateCardSkeleton } from './CandidateCardSkeleton';

const cards: null[] = new Array(5).fill(null);

export const TitleSkeleton = styled(Skeleton)`
  width: 100%;
  max-width: 310px;
  background-color: ${PALETTE.stroke1};
  border-radius: 2px;
  transform: none;
`;

export const CandidatesLoader: FC = () => (
  <>
    {cards.map((_, index) => (
      <Box key={index} mb='22px'>
        <CandidateCardSkeleton />
      </Box>
    ))}
  </>
);

import { FC } from 'react';

import { TextField } from '@mui/material';
import { UseFormRegister, FieldErrors } from 'react-hook-form';

interface TextInputProps {
  name: string;
  label: string;
  register: UseFormRegister<any>;
  errors: FieldErrors;
  className?: string;
}

export const TextInput: FC<TextInputProps> = ({
  name,
  label,
  register,
  errors,
  className
}) => (
  <TextField
    autoComplete='off'
    fullWidth
    label={label}
    type='text'
    variant='outlined'
    {...register(name, {
      required: 'Field is required'
    })}
    className={className ?? ''}
    error={!!errors[name]}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1px solid #70A500'
        },
        '&:hover fieldset': {
          border: '1px solid #537A00'
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #A7F582'
        }
      },
      '& .MuiInputLabel-root': {
        color: '#2B5659',
        '&.Mui-focused': {
          color: '#2B5659'
        }
      },
      '& .MuiInputBase-input': {
        color: '#2B5659'
      }
    }}
  />
);

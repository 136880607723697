import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';

import { InterviewRequest } from '@/types/interview-request';

import { InterviewRequestResponse } from './interviewApi.types';

export const interviewApi = createApi({
  reducerPath: 'api/interview',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    createInterviewRequest: build.mutation<
      InterviewRequestResponse,
      InterviewRequest
    >({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.CREATE_INTERVIEW_REQUEST,
        body: data
      })
    })
  })
});

export const { useCreateInterviewRequestMutation } = interviewApi;

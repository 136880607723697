import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { enqueueSnackbar } from 'notistack';

import { CookieKey } from '@/constants';

export type BaseQueryError = { status?: number; data?: any };

export const API_URL = process.env.REACT_APP_API_URL;

export const baseConfiguredQuery = fetchBaseQuery({
  baseUrl: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'include'
});

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  try {
    return await baseConfiguredQuery(args, api, extraOptions);
  } catch (err) {
    const error = err as BaseQueryError;
    if (error && error.status === 401) {
      Cookies.remove(CookieKey.Auth);
      window.location.replace('/');
    }

    if (error) {
      enqueueSnackbar('Oops, something went wrong', {
        variant: 'error'
      });

      throw error;
    }

    return {
      error: { status: error['status'], data: error['data'] }
    };
  }
};

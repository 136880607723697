import { CSSProperties } from 'react';

export const SUCCESS_MESSAGE = 'Your photo has been successfully uploaded';
export const SUCCESS_DELETED_MESSAGE = 'User avatar has been deleted';
export const INITIAL_MESSAGE =
  'On our platform, we encourage members to use their real identities. Please upload a photo of specialist.';
export const DELETE_MESSAGE = 'You sure you want to delete this photo?';

export type Size = 'logo' | 'xl' | 'l' | 'm' | 's' | 'header' | 'xs';

export type Variant = 'unauthorized' | 'authorized';

type AvatarColor = Record<Variant, CSSProperties>;

type AvatarSize = Record<Size, CSSProperties>;

export const AVATAR_COLOR: AvatarColor = {
  unauthorized: {
    backgroundColor: '#EAFFE4',
    color: '#2B5659'
  },
  authorized: {
    backgroundColor: '#EAFFE4',
    color: '#2B5659'
  }
};

export const AVATAR_SIZE: AvatarSize = {
  logo: {
    width: 152,
    height: 152,
    fontSize: '66px'
  },
  xl: {
    width: 96,
    height: 96,
    fontSize: '48px'
  },
  l: {
    width: 80,
    height: 80,
    fontSize: '40px'
  },
  m: {
    width: 64,
    height: 64,
    fontSize: '32px'
  },
  s: {
    width: 48,
    height: 48,
    fontSize: '24px'
  },
  header: {
    width: 32,
    height: 32,
    fontSize: '16px'
  },
  xs: {
    width: 16,
    height: 16,
    fontSize: '8px'
  }
};

import { createTheme, Theme } from '@mui/material';

import { defaultPalette } from '@/providers/themeProvider/palettes/default';
import { defaultTypography } from '@/providers/themeProvider/typography/typography';

const TabsTheme = (outerTheme: Theme) =>
  createTheme({
    typography: defaultTypography,
    palette: {
      mode: outerTheme.palette.mode,

      ...defaultPalette
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            displayLarge: 'h1',
            displayMedium: 'h2',
            headlineLarge: 'h3',
            headlineMedium: 'h4',
            titleLarge: 'p',
            titleMedium: 'p',
            titleSmallSemi: 'p',
            titleSmall: 'p',
            bodyXLarge: 'p',
            bodyLarge: 'p',
            bodyLargeSemi: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
            bodyXSmall: 'p',
            labelLargeSemi: 'p',
            labelXLarge: 'p',
            labelLarge: 'p',
            labelMedium: 'p',
            labelMediumRegular: 'p',
            labelSmallRegular: 'p',
            linkMedium: 'p',
            linkSmall: 'p',
            overline: 'p'
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {},
          indicator: {
            backgroundColor: '#70A500'
          }
        }
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: '#4F6A6C',
            '&:hover': {
              color: '#4F6A6C'
            },
            '&.Mui-selected': {
              color: '#2B5659'
            }
          },
          labelIcon: {
            padding: '0'
          },
          textColorInherit: {
            color: '#70A500',
            fontSize: '1.2rem'
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: '10px'
          }
        }
      },
      MuiPaper: {
        styleOverrides: {}
      }
    }
  });

export default TabsTheme;

import { createTheme, Theme } from '@mui/material';

import { defaultPalette } from '@/providers/themeProvider/palettes/default';
import { defaultTypography } from '@/providers/themeProvider/typography/typography';

const SearchTheme = (outerTheme: Theme) =>
  createTheme({
    typography: defaultTypography,
    palette: {
      mode: outerTheme.palette.mode,

      ...defaultPalette
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            displayLarge: 'h1',
            displayMedium: 'h2',
            headlineLarge: 'h3',
            headlineMedium: 'h4',
            titleLarge: 'p',
            titleMedium: 'p',
            titleSmallSemi: 'p',
            titleSmall: 'p',
            bodyXLarge: 'p',
            bodyLarge: 'p',
            bodyLargeSemi: 'p',
            bodyMedium: 'p',
            bodySmall: 'p',
            bodyXSmall: 'p',
            labelLargeSemi: 'p',
            labelXLarge: 'p',
            labelLarge: 'p',
            labelMedium: 'p',
            labelMediumRegular: 'p',
            labelSmallRegular: 'p',
            linkMedium: 'p',
            linkSmall: 'p',
            overline: 'p'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderColor: '#a2EAF6',
            '& .MuiOutlinedInput-input': {
              fontSize: '1rem',
              color: 'black',
              backgroundColor: '#fff',
              '&::placeholder': {
                color: '#71717A'
              }
            }
          }
        }
      },

      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff'
            }
          }
        }
      }
    }
  });

export default SearchTheme;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, styled as muiStyled } from '@mui/material';

import EditBtn from '@/assets/images/icon/EditButton.svg';
import PlusBtn from '@/assets/images/icon/PlusButton.svg';
import { Typography } from '@/components';
import { buttonSeniority } from '@/style/mixin';

export const Container = styled.div`
  max-width: 856px;
  width: 100%;
  margin-top: 72px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const HeadWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21.5px;
`;

export const Name = muiStyled('div')(({ theme }) => ({
  ...theme.typography.displayMedium
}));

export const EditButton = styled.div`
  background-image: url(${EditBtn});
  width: 32px;
  height: 32px;
  padding: 10px;
  cursor: pointer;
`;

export const HeaderCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

export const SubTitle = muiStyled(Typography)(({ theme }) => ({
  ...theme.typography.headlineMedium
}));

export const AddButton = styled.div`
  background-image: url(${PlusBtn});
  width: 32px;
  height: 32px;
  padding: 10px;
  cursor: pointer;
`;

export const SkillsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Skills = styled.div`
  display: flex;
  justify-content: center;
  background: var(--grey-block);
  border-radius: 2px;
  text-transform: none;
  box-shadow: none;
  height: 32px;
  padding: 6px 12px;
  border: 1px solid var(--grey-block);

  & p {
    ${buttonSeniority};
    color: var(--black);
    display: flex;
    align-items: center;
  }
`;

export const ButtonSeniorityBlock = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const SeniorityBlock = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: var(--grey-block);
  border-radius: 2px;
  text-transform: none;
  box-shadow: none;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid var(--grey-block);

  &:hover,
  &:focus {
    background: var(--orange-light);
    border: 1px solid #f2d8ba;
    filter: brightness(1);
  }

  & p {
    ${buttonSeniority};
    color: var(--black);
    display: flex;
    align-items: center;
  }

  &.selected {
    background: var(--orange-light);
    border: 1px solid #f2d8ba;
    filter: brightness(1);
  }
`;

export const Description = muiStyled(Typography)(({ theme }) => ({
  ...theme.typography.bodyLarge,
  maxWidth: '600px',
  marginTop: '16px',
  marginBottom: '32px'
}));

export const ModalContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ModalButton = styled.div`
  display: flex;
  justify-content: end;
  padding: 32px;
`;

export const ModalFormWrapper = styled.div`
  flex: 1 1 auto;
  max-height: 660px;
  padding: 0 4px;
`;
export const ModalFormScroll = styled.div`
  flex: 1 1 auto;
  padding: 0 32px;
  max-height: 470px;
  overflow-y: auto;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #79747e;
    border-radius: 50px;
  }
`;

export const Input = styled.div`
  margin-top: 16px;
`;

export const ModalFormInput = css`
  margin-top: 16px;
`;

export const ModalFormInputName = muiStyled('div')(({ theme }) => ({
  ...theme.typography.headlineMedium,
  marginTop: '35px'
}));

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const CardsWrapperLeftCard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardsWrapperRightCard = CardsWrapperLeftCard;

interface CardProps {
  selected?: boolean;
}

export const Card = muiStyled(Box)<CardProps>(({ theme, selected }) => ({
  width: '242px',
  height: '145px',
  border: `1px solid ${theme.palette.primaryRoot.lightGrey}`,
  marginTop: '15px',
  padding: '20px',
  cursor: 'pointer',

  '&:focus': {
    backgroundColor: theme.palette.blue[500]
  },

  ...(selected && {
    border: `2px solid ${theme.palette.blue[500]}`,
    backgroundColor: theme.palette.blue[500]
  })
}));

export const CardTitle = muiStyled(Typography)<CardProps>(
  ({ theme, selected }) => ({
    ...theme.typography.bodyLargeSemi,
    letterSpacing: '-0.8px', // TODO: should update regarding a new design
    color: selected ? theme.palette.common.white : theme.palette.blue[500]
  })
);

export const CardSubtitle = muiStyled(Typography)<CardProps>(
  ({ theme, selected }) => ({
    ...theme.typography.labelSmallRegular,
    marginTop: '10px',
    color: selected ? theme.palette.common.white : theme.palette.common.black,
    whiteSpace: 'pre-line'
  })
);

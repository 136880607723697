import * as Yup from 'yup';

export const employeeDefaultValues = {
  employeeSkills: [],
  employeeLanguageLevels: [
    {
      language: {
        id: 0,
        name: ''
      },
      languageLevel: {
        id: 0,
        name: ''
      }
    }
  ]
};

export const employeeValidationSchema = Yup.object().shape({
  employeeSkills: Yup.array()
    .min(1, 'Please select skills and technologies')
    .of(
      Yup.object()
        .shape({
          id: Yup.number().required(),
          name: Yup.string().required()
        })
        .required('Please select skills and technologies')
    )
    .required(),
  employeeLanguageLevels: Yup.array()
    .of(
      Yup.object()
        .shape({
          language: Yup.object().shape({
            id: Yup.number().required('Please select language'),
            name: Yup.string().required('Please select language')
          }),
          languageLevel: Yup.object().shape({
            id: Yup.number().required('Please select level of language'),
            name: Yup.string().required('Please select level of language')
          })
        })
        .required()
    )
    .required()
});

import { FC } from 'react';

import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SuccessBg } from '@/assets/images/backgrounds';
import { Button, Typography } from '@/components';
import { Pages } from '@/constants';
import { useSignUpFormState } from '@/context/formContext';
import { useResendEmailConfirmationMutation } from '@/redux/api/auth/authApi';

interface EmailVerificationProps {
  _test_fn?: () => void;
}

export const EmailVerification: FC<EmailVerificationProps> = ({ _test_fn }) => {
  const navigate = useNavigate();

  const [resendEmailConfirmation] = useResendEmailConfirmationMutation();
  const { formData } = useSignUpFormState();

  return (
    <>
      <div>
        <Box mt='-15px'>
          <SuccessBg />
        </Box>
        <Typography mt='40px' textAlign='center' variant='displayLarge'>
          You’ve got an Email
        </Typography>
        <Box mt='24px' textAlign='center'>
          <Typography variant='bodyLarge'>
            An email has been sent to you at{' '}
            <Typography component='span' variant='bodyLargeSemi'>
              {formData.email}
            </Typography>
          </Typography>
          <Typography variant='bodyLarge'>
            Click on the link to access your account.
          </Typography>
        </Box>
      </div>

      <Stack mt='32px' spacing='32px'>
        <Button
          fullWidth
          onClick={() => {
            resendEmailConfirmation({ email: formData.email as string });
            _test_fn?.();
          }}
          size='large'
          text='Resend an email'
          variant='clearBorder'
        />
        <Button
          fullWidth
          onClick={() => {
            navigate(Pages.HOME);
            _test_fn?.();
          }}
          size='large'
          text='Back to Sign In'
          variant='clear'
        />
      </Stack>
    </>
  );
};

import { configureStore, Store } from '@reduxjs/toolkit';

import { authApi } from '@/redux/api/auth/authApi';
import { authReducer } from '@/redux/api/auth/authSlice';
import { dictionaryApi } from '@/redux/api/dictionary/dictionaryApi';
import { employeeApi } from '@/redux/api/employee/employeeApi';
import { fileApi } from '@/redux/api/file/fileApi';
import { interviewApi } from '@/redux/api/interview/interviewApi';
import { settingsApi } from '@/redux/api/settings/settingsApi';
import { userApi } from '@/redux/api/user/userApi';

export const store: Store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
    [interviewApi.reducerPath]: interviewApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    auth: authReducer
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      dictionaryApi.middleware,
      employeeApi.middleware,
      interviewApi.middleware,
      settingsApi.middleware,
      fileApi.middleware
    ])
});

export type RootState = ReturnType<typeof store.getState>;

import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { SignUpFormProvider } from '@/context/formContext';

import { SignUpLayoutContainer } from './styles';

interface FormLayoutProps {
  children: ReactNode;
}

export const FormLayout: FC<FormLayoutProps> = ({ children }) => (
  <SignUpFormProvider>
    <SignUpLayoutContainer>
      <Box width='100%'>{children}</Box>
    </SignUpLayoutContainer>
  </SignUpFormProvider>
);

import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: var(--white);
  width: 500px;
  padding: 60px;
  box-shadow: 0 4px 50px 0 rgba(0, 13, 79, 0.1);
`;

export const HeadBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const HeadTitle = muiStyled('div')(({ theme }) => ({
  ...theme.typography.labelSmallMedium,
  color: '#222222',
  fontSize: '26px',
  width: '74px'
}));

export const Title = styled.p`
  display: flex;
  align-items: center;
  margin-top: 60px;
  color: var(--blackText);
  font-size: 32px;
  line-height: normal;
  font-style: normal;
  font-weight: 600;
`;

export const DescriptionContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Description = styled.p`
  color: var(--grey-dark);
  font-size: 16px;
  line-height: normal;
  font-style: normal;
  font-weight: 400;
`;

export const DescriptionLink = styled(Link)`
  color: var(--blue);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-style: normal;
  font-weight: 500;
`;

import { FC } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { Controller, FieldErrors } from 'react-hook-form';

interface SelectSkillsProps {
  name: string;
  label: string;
  control: any;
  errors: FieldErrors;
  options: { name: string; id: number }[];
  selectedSkills: any;
  onSkillSelection: any;
  className?: string;
  value: any;
}

export const SelectSkills: FC<SelectSkillsProps> = ({
  name,
  label,
  control,
  options,
  errors,
  selectedSkills,
  onSkillSelection,
  value,
  className
}) => (
  <Controller
    control={control}
    defaultValue={selectedSkills}
    name={name}
    render={({ field }) => (
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 200, overflowY: 'auto' } }}
        getOptionLabel={(option) => option.name}
        multiple
        onChange={(_, newValue) =>
          onSkillSelection(newValue.map((skill) => skill || {}))
        }
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: null
            }}
            autoComplete='off'
            className={className}
            error={!!errors[name]}
            fullWidth
            label={label}
            onBlur={field.onBlur}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #70A500'
                },
                '&:hover fieldset': {
                  border: '1px solid #537A00'
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #A7F582'
                }
              },
              '& .MuiInputLabel-root': {
                color: '#2B5659',
                '&.Mui-focused': {
                  color: '#2B5659'
                }
              },
              '& .MuiInputBase-input': {
                color: '#2B5659'
              }
            }}
            variant='outlined'
          />
        )}
        renderOption={(props, option) => (
          <li {...props} style={{ padding: '12px 23px' }}>
            {option.name}
          </li>
        )}
        value={value}
      />
    )}
  />
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { CookieKey } from '@/constants';

interface InitialState {
  token: string;
}

const initialState: InitialState = {
  token: Cookies.get(CookieKey.Auth) || ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state: InitialState, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  }
});

export const authActions = authSlice.actions;
export const { reducer: authReducer } = authSlice;

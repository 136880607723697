import { FC, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { UnprivilegedEditor } from 'react-quill';

import { modules } from '@/components/RichTextEditor/editor.config';
import { HelperText } from '@/components/helperText';

import { QuillToolbar } from './EditorToolbar';
import {
  StyledReactQuill,
  ReactQuillWrapper,
  Count,
  FakePlaceholder,
  ReactQuillReadOnly
} from './styles';

interface RichTextEditorProps {
  defaultValue?: string;
  maxLength?: number;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  onChange: (value: string) => void;
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  defaultValue = '',
  maxLength = 2500,
  placeholder = 'Add your info',
  error = false,
  errorMessage = '',
  onChange
}) => {
  const [value, setValue] = useState(defaultValue);
  const [focus, setFocus] = useState(false);
  const [count, setCount] = useState(0);

  const handleOnChange = (
    value: string,
    _delta: unknown,
    _source: unknown,
    editor: UnprivilegedEditor
  ) => {
    setValue(value);
    onChange(value);
    const characterCount = editor.getLength() - 1;
    setCount(characterCount);
  };

  return (
    <Stack
      data-testid='editor'
      direction='column'
      position='relative'
      spacing='6px'
    >
      <FakePlaceholder
        count={count || defaultValue?.length}
        error={error}
        focus={focus}
      >
        {placeholder}
      </FakePlaceholder>
      <ReactQuillWrapper error={error} focus={focus}>
        <StyledReactQuill
          focus={focus}
          modules={modules}
          onBlur={() => setFocus(false)}
          onChange={handleOnChange}
          onFocus={() => setFocus(true)}
          value={value}
        />
        <QuillToolbar />
      </ReactQuillWrapper>

      <Box alignItems='center' display='flex' justifyContent='space-between'>
        <HelperText text={errorMessage} />
        <Count data-testid='count' error={error}>
          {count} / {maxLength}
        </Count>
      </Box>
    </Stack>
  );
};

interface RichTextInterpretatorProps {
  text: string;
}

export const RichTextInterpretator: FC<RichTextInterpretatorProps> = ({
  text = ''
}) => <ReactQuillReadOnly readOnly value={text} />;

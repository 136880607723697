import styled from '@emotion/styled';

import Bg from '@/assets/images/photo/Auth_BG.webp';

export const SignUpLayoutContainer = styled.div`
  background-image: url(${Bg});
  background-size: cover;

  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 40px 24px;
`;

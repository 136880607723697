import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

import { Typography } from '@/components';

export const MainWrapper = styled.div`
  max-width: 1032px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 22px;
  padding: 16px;
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  justify-content: space-between;
`;

export const CreateEmp = muiStyled('button')(({ theme }) => ({
  ...theme.typography.labelSmallMedium,
  height: '40px',
  padding: '10px 13px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.link,
  background: theme.palette.neutral.white,
  border: '1px solid #70A500',
  borderRadius: '4px',
  cursor: 'pointer'
}));

export const WrapperEmpty = styled(Typography)`
  border: 1px solid;
  border-radius: 2px;
  min-width: 548px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
  cursor: pointer;
  height: auto;
  min-height: 60px;
`;

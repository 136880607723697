import { FC, ReactNode } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { EmployeeFormProvider } from '@/context/formContext';

export const ContainerStyles = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
`;

interface FormLayoutEmployeeProps {
  children: ReactNode;
}

export const FormLayoutEmployee: FC<FormLayoutEmployeeProps> = ({
  children
}) => {
  return (
    <EmployeeFormProvider>
      <ContainerStyles>
        <Box display='flex' justifyContent='center'>
          {children}
        </Box>
      </ContainerStyles>
    </EmployeeFormProvider>
  );
};

import { FC } from 'react';

import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SuccessBg } from '@/assets/images/backgrounds';
import { FinalCard } from '@/components';
import { ThemedContainer } from '@/providers';
import { getPathCandidateList } from '@/util';

export const Wrapper = styled(Box)`
  height: calc(100vh - 64px);
`;

interface InterviewSuccessResultProps {
  _test_fn?: () => void;
}

export const InterviewSuccessResult: FC<InterviewSuccessResultProps> = ({
  _test_fn
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`/candidate${getPathCandidateList()}`);
    _test_fn?.();
  };

  return (
    <ThemedContainer>
      <Wrapper alignItems='center' display='flex' justifyContent='center'>
        <FinalCard
          img={<SuccessBg />}
          onSecondButtonClick={handleButtonClick}
          secondButtonName='Back to Candidate list'
          text="Your interview request has been successfully submitted to the chosen specialist. We'll notify you as soon as they respond."
          title='Request Successfully Sent!'
        />
      </Wrapper>
    </ThemedContainer>
  );
};

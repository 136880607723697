import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';
import { UserMeResponse } from '@/redux/api/user/userApi.types';

export const userApi = createApi({
  reducerPath: 'api/user',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getUserMe: build.query<UserMeResponse, null>({
      query: () => ({ method: 'GET', url: Endpoints.USER_ME })
    })
  })
});

export const { useGetUserMeQuery } = userApi;

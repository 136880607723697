import { forwardRef, ReactNode, FC } from 'react';

import { Typography as MuiTypography, TypographyProps } from '@mui/material';

export interface TypographySpacings {
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  my?: string;
  mx?: string;

  pt?: string;
  pr?: string;
  pb?: string;
  pl?: string;
  py?: string;
  px?: string;
}

export interface CustomTypographyProps extends TypographyProps {
  children: ReactNode;
  color?: string;
}

export const Typography: FC<CustomTypographyProps & TypographySpacings> =
  forwardRef(
    (
      {
        children,
        color = 'text.primary',
        mt,
        mr,
        mb,
        ml,
        my,
        mx,
        pt,
        pr,
        pb,
        pl,
        py,
        px,
        ...props
      },
      ref
    ) => (
      <MuiTypography
        sx={{ color, mt, mr, mb, ml, my, mx, pt, pr, pb, pl, py, px }}
        {...props}
        ref={ref}
      >
        {children}
      </MuiTypography>
    )
  );

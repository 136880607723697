import { FC } from 'react';

import { TextField } from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

interface TextInputProps {
  name: string;
  label: string;
  register: UseFormRegister<any>;
  errors: boolean;
  className?: string;
}

export const PriceInput: FC<TextInputProps> = ({
  name,
  label,
  register,
  errors = false,
  className
}) => (
  <TextField
    autoComplete='off'
    fullWidth
    label={label}
    sx={{
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '1px solid #70A500'
        },
        '&:hover fieldset': {
          border: '1px solid #537A00'
        },
        '&.Mui-focused fieldset': {
          border: '1px solid #A7F582'
        }
      },
      '& .MuiInputLabel-root': {
        color: '#2B5659',
        '&.Mui-focused': {
          color: '#2B5659'
        }
      },
      '& .MuiInputBase-input': {
        color: '#2B5659'
      }
    }}
    type='text'
    variant='outlined'
    {...register(name, {
      required: 'Field is required',
      pattern: {
        value: /^[0-9]+$/,
        message: 'Invalid value'
      },
      validate: {
        minPrice: (value) => {
          if (value >= 5) {
            return true;
          }

          return 'The lowest price for the system is 5$';
        },
        maxPrice: (value) => {
          if (value <= 500) {
            return true;
          }

          return 'The highest price for the system is 500$';
        }
      }
    })}
    className={className}
    error={errors}
  />
);

import { FC, useState, useMemo } from 'react';

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { Controller } from 'react-hook-form';

import { BlueSelect, BlueLabel } from './styles';

interface BaseTimeRangeProps {
  field?: any;
  errors?: boolean;
  time: string;
  setTime: (val: string) => void;
  label?: string;
  labelId?: string;
  id?: string;
}

export const BaseTimeRange: FC<BaseTimeRangeProps> = ({
  field,
  errors,
  time,
  setTime,
  label,
  labelId,
  id
}) => {
  const [open, setOpen] = useState(false);
  const timeRanges = useMemo<string[]>(() => {
    // TODO: Find the solution and simplify it with `date-fns`
    let today = new Date();
    const ranges = [];
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    const day = today.getDate();

    while (today.getDate() === day) {
      const start =
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10
          ? '0' + today.getMinutes()
          : today.getMinutes());
      today = new Date(today.getTime() + 30 * 60000);
      const end =
        (today.getHours() < 10 ? '0' + today.getHours() : today.getHours()) +
        ':' +
        (today.getMinutes() < 10
          ? '0' + today.getMinutes()
          : today.getMinutes());
      ranges.push(start + ' - ' + end);
    }

    return ranges;
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setTime(event.target.value as string);
  };

  return (
    <FormControl error={errors} sx={{ minWidth: 120, width: 216 }}>
      <BlueLabel id={labelId}>{label}</BlueLabel>
      <BlueSelect
        IconComponent={() =>
          open ? (
            <ExpandLessOutlinedIcon
              sx={{ fill: (theme) => theme.palette.stroke?.['40'] }}
            />
          ) : (
            <ExpandMoreOutlinedIcon
              sx={{ fill: (theme) => theme.palette.stroke?.['40'] }}
            />
          )
        }
        MenuProps={{
          style: {
            maxHeight: '196px'
          }
        }}
        id={id}
        label={label}
        labelId={labelId}
        onBlur={() => field?.onBlur()}
        onChange={(newValue) => {
          if (newValue) {
            handleChange(newValue);
          }

          field?.onChange(newValue);
        }}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={time}
      >
        {timeRanges.map((item) => (
          <MenuItem key={item} selected={item === time} value={item}>
            {item}
          </MenuItem>
        ))}
      </BlueSelect>
    </FormControl>
  );
};

type ModifiedRangeProps = Omit<BaseTimeRangeProps, 'field'>;

interface TimeRangeProps extends ModifiedRangeProps {
  control: any;
  name: string;
  id?: string;
}

const TimeRange: FC<TimeRangeProps> = ({
  control,
  name,
  errors,
  time,
  setTime,
  label = 'Time',
  labelId = 'time-select-label',
  id = 'time-select'
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <BaseTimeRange
          errors={errors}
          field={field}
          id={id}
          label={label}
          labelId={labelId}
          setTime={setTime}
          time={time}
        />
      )}
    />
  );
};

export { TimeRange };

import styled from '@emotion/styled';

import { PALETTE } from '@/util/palette';

export const Container = styled.div`
  margin: 65px auto;
  width: 680px;
`;

export const MainContent = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const HeadWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
`;

export const HeadWrapperRight = styled(HeadWrapper)`
  display: flex;
  flex-direction: column;
  gap: 21.5px;
`;

export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  color: ${PALETTE.darkTitle};
  font-size: 28px;
  line-height: 16px;
`;

export const Row = styled.div`
  display: flex;
  gap: 28px;
`;

export const Border = styled.div`
  border-top: 1px solid ${PALETTE.greyBorder};
  margin-top: 15px;
`;

export const Description = styled.div`
  max-width: 600px;
  margin-top: 24px;
  margin-bottom: 32px;
  color: ${PALETTE.dark};
  font-size: 16px;
  line-height: 140%;
  font-style: normal;
  font-weight: 400;
`;

export const HeaderCardWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SkillsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SkillsOrange = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  background: ${PALETTE.purple};
  border-radius: 2px;
  text-transform: none;
  box-shadow: none;
  height: 32px;
  padding: 6px 12px 6px 12px;
  border: 1px solid ${PALETTE.purple};
  border-radius: 2px;

  & p {
    font-size: 16px;
    line-height: 130%;
    font-style: normal;
    font-weight: 500;
    color: ${PALETTE.dark};
    display: flex;
    align-items: center;
  }
`;

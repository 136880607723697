import styled from '@emotion/styled';

import PlusButton from '@/assets/images/icon/PlusButton.svg';
import RemoveBtn from '@/assets/images/icon/RemoveButton.svg';

export const LanguagesWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;
export const Languages = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
`;
export const AddButton = styled.div`
  background-image: url(${PlusButton});
  width: 32px;
  height: 32px;
  padding: 16px;
  cursor: pointer;
`;
export const RemoveButton = styled.div`
  background-image: url(${RemoveBtn});
  width: 32px;
  height: 32px;
  padding: 16px;
  cursor: pointer;
`;

import { FC, MouseEvent, useEffect, useRef, useState } from 'react';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Cookies from 'js-cookie';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { Logo } from '@/assets/images/icon';
import { Button } from '@/components';
import { CookieKey, Pages } from '@/constants';
import { useAuth } from '@/hooks';
import { useLogOutMutation } from '@/redux/api/auth/authApi';
import { useGetUserMeQuery } from '@/redux/api/user/userApi';
import { decodeBase64 } from '@/service/api';

// eslint-disable-next-line
import {
  HeadBlock,
  HeaderLine,
  HeaderWrapper,
  RightLogo,
  UserLogo,
  StyledLink
} from './styles';

interface MenuItem {
  name: string;
  route: string;
}

interface UserData {
  firstName: string;
  lastName: string;
}

const initialUserData: UserData = {
  firstName: '',
  lastName: ''
};

const MAX_RETRIES = 3;

export const MainHeader: FC = () => {
  const retryCount = useRef(0);
  const { token, setToken } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentPathname = pathname!;
  const isDefaultPage = currentPathname === '/';
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    data: user,
    refetch,
    isError
  } = useGetUserMeQuery(null, { skip: !token });
  const [logOut] = useLogOutMutation();

  useEffect(() => {
    if (token && isError && retryCount.current < MAX_RETRIES) {
      retryCount.current = retryCount.current + 1;
      refetch();
    }
  }, [isError, refetch, token]);

  const decodedData = user?.data
    ? decodeBase64<UserData>(user.data)
    : initialUserData;

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    logOut();
    Cookies.remove(CookieKey.Auth);
    setToken('');

    navigate(Pages.HOME);
  };

  if (
    currentPathname?.includes(Pages.SIGN_IN) ||
    currentPathname?.includes(Pages.SIGN_UP) ||
    currentPathname?.includes(Pages.CHANGE_PASSWORD) ||
    currentPathname?.includes(Pages.FORGOT_PASSWORD)
  ) {
    return <></>;
  }

  return (
    <HeaderWrapper isDefaultPage={isDefaultPage}>
      <Link to={Pages.HOME}>
        <HeadBlock>
          <Logo data-testid='logo' />
        </HeadBlock>
      </Link>

      <>
        {token ? (
          <>
            <RightLogo>
              <StyledLink to='/employee'>My employee list</StyledLink>
              <Link to='/employee/create'>
                <Button
                  size='medium'
                  text='Create Employee'
                  variant='createEmployee'
                />
              </Link>
              <HeaderLine />
              <IconButton onClick={handleOpenMenu}>
                <UserLogo>
                  {decodedData
                    ? `${decodedData.firstName.charAt(
                        0
                      )}${decodedData.lastName.charAt(0)}`
                    : ''}
                </UserLogo>
              </IconButton>
            </RightLogo>
            <Menu
              anchorEl={anchorEl}
              id='auth-menu'
              keepMounted
              onClose={handleCloseMenu}
              open={!!anchorEl}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            onClick={() => navigate(Pages.SIGN_IN)}
            size='small'
            testId='sign-in-btn'
            text='Sign In'
            variant='cv'
          />
        )}
      </>
    </HeaderWrapper>
  );
};

import { FC, ReactNode } from 'react';

interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, value, index }) => (
  <div
    aria-labelledby={`tab-${index}`}
    hidden={value !== index}
    id={`tabpanel-${index}`}
    role='tabpanel'
  >
    {value === index && children}
  </div>
);

import { FC } from 'react';

import { Autocomplete, Box, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { HelperText } from '@/components';

import {
  LanguagesWrapper,
  Languages,
  Container,
  AddButton,
  RemoveButton
} from './styles';

interface LanguageLevelsProps {
  control: any;
  errors: any;
  languageLevels: LanguageLevel[];
  className?: string;
  optionsLanguage: { name: string; id: number }[];
  optionsLevel: { name: string; id: number }[];
  addNewLanguage: () => void;
  deleteNewLanguage: (index: number) => void;
}

interface LanguageLevel {
  language: { id: number; name: string };
  languageLevel: { id: number; name: string };
}
export const LanguageLevels: FC<LanguageLevelsProps> = ({
  control,
  errors,
  languageLevels,
  optionsLanguage,
  optionsLevel,
  addNewLanguage,
  deleteNewLanguage
}) => {
  const addLanguageLevelField = () => {
    addNewLanguage();
  };

  const deleteLanguageLevelField = (index: number) => {
    deleteNewLanguage(index);
  };

  const renderButtons = (index: number) => {
    if (index === 0) {
      return <AddButton onClick={addLanguageLevelField} />;
    }

    return <RemoveButton onClick={() => deleteLanguageLevelField(index)} />;
  };

  return (
    <LanguagesWrapper>
      {languageLevels.map((value: LanguageLevel, index: number) => (
        <Languages key={index}>
          <Box width='100%'>
            <Container>
              <Controller
                control={control}
                name={`employeeLanguageLevels[${index}].language`}
                render={({ field }) => (
                  <Autocomplete
                    ListboxProps={{
                      style: { maxHeight: 200, overflowY: 'auto' }
                    }}
                    defaultValue={value.language}
                    getOptionLabel={(option) => option.name}
                    onChange={(
                      _,
                      newValue: { id: number; name: string } | null
                    ) => {
                      if (newValue === null) {
                        field.onChange({
                          language: {
                            id: 0,
                            name: ''
                          }
                        });
                        return;
                      }

                      field.onChange(newValue);
                    }}
                    options={optionsLanguage.filter(
                      (option) =>
                        !languageLevels.some(
                          (level: LanguageLevel) =>
                            Number(level.language) === Number(option.id)
                        )
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null // clear btn
                        }}
                        autoComplete='off'
                        error={errors}
                        fullWidth
                        label='Select language'
                        onBlur={field.onBlur}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: '1px solid #70A500'
                            },
                            '&:hover fieldset': {
                              border: '1px solid #537A00'
                            },
                            '&.Mui-focused fieldset': {
                              border: '1px solid #A7F582'
                            }
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2B5659',
                            '&.Mui-focused': {
                              color: '#2B5659'
                            }
                          },
                          '& .MuiInputBase-input': {
                            color: '#2B5659'
                          }
                        }}
                        variant='outlined'
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} style={{ padding: '12px 23px' }}>
                        {option.name}
                      </li>
                    )}
                  />
                )}
                rules={{
                  required: 'Field is required',
                  validate: (value) => {
                    if (value === null) {
                      return 'Value cannot be 0';
                    }
                  }
                }}
              />
            </Container>
            {!!errors?.[index]?.language && (
              <HelperText text={errors[0]?.language?.name?.message} />
            )}
          </Box>
          <Box width='100%'>
            <Container>
              <Controller
                control={control}
                name={`employeeLanguageLevels[${index}].languageLevel`}
                render={({ field }) => (
                  <Autocomplete
                    ListboxProps={{
                      style: { maxHeight: 200, overflowY: 'auto' }
                    }}
                    defaultValue={value.languageLevel}
                    getOptionLabel={(option) => option.name}
                    onChange={(
                      _,
                      newValue: { id: number; name: string } | null
                    ) => {
                      if (newValue === null) {
                        field.onChange({
                          languageLevel: {
                            id: 0,
                            name: ''
                          }
                        });
                        return;
                      }

                      field.onChange(newValue);
                    }}
                    options={optionsLevel}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: null // clear btn
                        }}
                        autoComplete='off'
                        error={errors}
                        fullWidth
                        label='Select level'
                        onBlur={field.onBlur}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: '1px solid #70A500'
                            },
                            '&:hover fieldset': {
                              border: '1px solid #537A00'
                            },
                            '&.Mui-focused fieldset': {
                              border: '1px solid #A7F582'
                            }
                          },
                          '& .MuiInputLabel-root': {
                            color: '#2B5659',
                            '&.Mui-focused': {
                              color: '#2B5659'
                            }
                          },
                          '& .MuiInputBase-input': {
                            color: '#2B5659'
                          }
                        }}
                        variant='outlined'
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} style={{ padding: '12px 23px' }}>
                        {option.name}
                      </li>
                    )}
                  />
                )}
                rules={{
                  required: 'Field is required',
                  minLength: { value: 1, message: '*min 1 symbols' },
                  validate: (value) => {
                    if (value === null || 0) {
                      return 'Value cannot be 0';
                    }
                  }
                }}
              />
            </Container>
            {!!errors?.[index]?.languageLevel && (
              <HelperText text={errors[0]?.languageLevel?.name?.message} />
            )}
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            height='56px'
            justifyContent='center'
          >
            {renderButtons(index)}
          </Box>
        </Languages>
      ))}
    </LanguagesWrapper>
  );
};

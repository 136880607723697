import { ReactNode } from 'react';

import {
  AddPhoto,
  BackButton,
  Basic,
  Clear,
  ClearBorder,
  CreateEmployee,
  Cv,
  DefaultBtn,
  DownloadCV,
  Employees,
  GoogleBtn,
  Hire,
  IconButton,
  Interview,
  Link,
  Next,
  Prev
} from './styles';

export type ButtonVariant =
  | 'default'
  | 'google'
  | 'clear'
  | 'clearBorder'
  | 'next'
  | 'prev'
  | 'employees'
  | 'hire'
  | 'cv'
  | 'addPhoto'
  | 'createEmployee'
  | 'backButton'
  | 'link'
  | 'iconButton'
  | 'downloadCV'
  | 'interview'
  | 'basic';

export type Size = 'small' | 'medium' | 'large';

type IButtonProps = {
  text?: string;
  classname?: string;
  className?: string;
  link?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: Size;
  onClick?: (e: any) => void;
  type?: 'submit' | 'button' | 'reset' | undefined;
  children?: ReactNode;
  testId?: string;
};

const getButtonComponent = (mod: ButtonVariant) => {
  switch (mod) {
    case 'google':
      return GoogleBtn;
    case 'clear':
      return Clear;
    case 'clearBorder':
      return ClearBorder;
    case 'next':
      return Next;
    case 'prev':
      return Prev;
    case 'hire':
      return Hire;
    case 'cv':
      return Cv;
    case 'employees':
      return Employees;
    case 'addPhoto':
      return AddPhoto;
    case 'createEmployee':
      return CreateEmployee;
    case 'backButton':
      return BackButton;
    case 'link':
      return Link;
    case 'iconButton':
      return IconButton;
    case 'downloadCV':
      return DownloadCV;
    case 'interview':
      return Interview;
    case 'basic':
      return Basic;
    default:
      return DefaultBtn;
  }
};

export const Button = ({
  type = 'button',
  text,
  classname = '',
  variant = 'default',
  size = 'medium',
  onClick,
  children,
  disabled,
  testId,
  fullWidth = false
}: IButtonProps) => {
  const ButtonComponent = getButtonComponent(variant);

  return (
    <ButtonComponent
      className={`${disabled && 'disabled'}  ${classname}`}
      data-testid={testId}
      disabled={!!disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      type={type}
    >
      <p>{text}</p>

      {children}
    </ButtonComponent>
  );
};

import { RouteProps } from 'react-router-dom';

import { Pages } from '@/constants';
import {
  MainPage as Home,
  ForgotPassword,
  SignIn,
  SignUpPage,
  Employee,
  CandidateProfilePage,
  EmployeeProfile,
  Candidate,
  Employees,
  InterviewSuccessResult,
  FormLayout,
  FormLayoutEmployee,
  SuccessChangePasswordPage,
  ChangePassword
} from '@/page';
import { IROUTE_MODEL } from '@/types';

export const PUBLIC: IROUTE_MODEL<RouteProps['children']>[] = [
  { path: Pages.HOME, element: <Home /> },
  { path: Pages.CANDIDATE, element: <Candidate /> },
  { path: Pages.CANDIDATE_ID, element: <CandidateProfilePage /> },
  { path: Pages.INTERVIEW_SUCCESS, element: <InterviewSuccessResult /> },
  {
    path: Pages.EMPLOYEE,
    element: (
      <FormLayoutEmployee>
        <Employees />
      </FormLayoutEmployee>
    )
  },
  {
    path: Pages.EMPLOYEE_ID,
    element: (
      <FormLayoutEmployee>
        <EmployeeProfile />
      </FormLayoutEmployee>
    )
  },
  {
    path: Pages.EMPLOYEE_CREATE,
    element: (
      <FormLayoutEmployee>
        <Employee />
      </FormLayoutEmployee>
    )
  },
  {
    path: Pages.CHANGE_PASSWORD,
    element: (
      <FormLayout>
        <ChangePassword />
      </FormLayout>
    )
  },
  {
    path: Pages.CHANGE_PASSWORD_SUCCESS,
    element: (
      <FormLayout>
        <SuccessChangePasswordPage />
      </FormLayout>
    )
  },
  {
    path: Pages.FORGOT_PASSWORD,
    element: (
      <FormLayout>
        <ForgotPassword />
      </FormLayout>
    )
  },
  {
    path: Pages.SIGN_IN,
    element: (
      <FormLayout>
        <SignIn />
      </FormLayout>
    )
  },
  {
    path: Pages.SIGN_UP,
    element: (
      <FormLayout>
        <SignUpPage />
      </FormLayout>
    )
  }
];

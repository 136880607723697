/** @jsxImportSource @emotion/react */

import React from 'react';

import { Button, SelectSkills, Modal } from '@/components';

import {
  Bottom,
  ButtonWrapper,
  ModalContainer,
  ModalFormInput
} from './styles';

interface SkillsTechsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  control: any;
  errors: any;
  skillsAndTechnologiesDictionary: any;
  editedSkillsTechs: any;
  handleSkillSelection: any;
  value?: any;
}

const SkillsTechsModal: React.FC<SkillsTechsModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  control,
  errors,
  skillsAndTechnologiesDictionary,
  editedSkillsTechs,
  handleSkillSelection,
  value
}) => {
  return (
    <Modal active={isOpen} modalTitle='Edit Skills & Techs' onClose={onClose}>
      <ModalContainer>
        <form onSubmit={onSubmit}>
          <SelectSkills
            control={control}
            css={[ModalFormInput]}
            errors={errors}
            label='Skills & Technologies'
            name='employeeSkills'
            onSkillSelection={handleSkillSelection}
            options={skillsAndTechnologiesDictionary}
            selectedSkills={editedSkillsTechs}
            value={value}
          />
          <Bottom />
        </form>
        <ButtonWrapper>
          <Button
            onClick={onSubmit}
            size='medium'
            text='Save'
            type='submit'
            variant='next'
          />
        </ButtonWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default SkillsTechsModal;

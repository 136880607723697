export const createMockedArray = (size: number): number[] => {
  return new Array(size as number).fill(null);
};

// Add space between words. Ex: input: 'SkillsAndTechnologies', output: 'Skills And Technologies'
export const formatCamelCaseToWords = (input: string) => {
  const words = input.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  return words.join(' ');
};

// Remove space between words
export const objKeyFormatter = (key: string) =>
  key.split(' ').join().toLocaleLowerCase();

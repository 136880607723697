import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

import User from '@/assets/images/icon/user.webp';

export const Logo = styled.div`
  width: 64px;
  height: 64px;
  background-image: url(${User});
  background-size: contain;
`;

export const WrapperCard = muiStyled('div')(({ theme }) => ({
  width: '100%',
  height: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  marginBottom: 0,
  border: `1px solid ${theme.palette.stroke?.['10']}`,
  borderRadius: '2px'
}));

export const InfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const InfoLabel = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 10px;
`;

export const BtnEdit = styled.div`
  margin-left: auto;
`;

export const Photo = styled.div`
  margin-right: 16px;
  width: fit-content;
  cursor: pointer;
`;

export const SkillsWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Skills = styled.div`
  display: flex;
  justify-content: center;
  background: #f4f4f5;
  padding: 8px;
  text-transform: none;
  box-shadow: none;
  border: 1px solid #f4f4f5;
  border-radius: 2px;

  & p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #27262b;
    display: flex;
    align-items: center;
  }
`;

import { FC, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  BackToTop,
  CandidateSearchStyles,
  CardInterview,
  Loader
} from '@/components';
import { StorageKey } from '@/constants';
import { useGroupedOptions, GroupedOption } from '@/hooks/groupOptions';
import { useInternetStatus } from '@/hooks/useInternetStatus';
import MainSearch from '@/page/home/mainSearch';
import {
  useGetMatchMutation,
  useGetPerfectMatchMutation
} from '@/redux/api/employee/employeeApi';
import { Res } from '@/types';
import { getPathCandidateList } from '@/util';
import { objectToGetParamsConvert } from '@/util/objectToGetParamsConvert';

import { Employee } from '@/types/employee';

import {
  CandidatesLoader,
  CandidatesScrollLoader,
  TitleSkeleton
} from './_components';
import { CandidatesErrorHandler } from './_components/CandidatesErrorHandler';
import { Filter } from './filter';
import { CardWrapper, Container, Title, Wrapper } from './styles';

export const Candidate: FC = () => {
  const navigate = useNavigate();

  const groupedOptions = useGroupedOptions();
  const [getMatch, { isLoading: isLoadingGetMatch }] = useGetMatchMutation();
  const [getPerfectMatch, { isLoading: isLoadingGetPerfectMatch }] =
    useGetPerfectMatchMutation();

  const [isError, setIsError] = useState(false);
  const [link] = useState(useLocation().search);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [filter, setFilter] = useState<Record<string, number[]>>(() => {
    const params: Record<string, number[]> = {};
    const parts = link.substring(1).split('&');

    for (const part of parts) {
      const [key, value] = part.split('=');
      if (key && value) {
        params[key] = value.split(',').map(Number);
      }
    }

    return params;
  });

  const [matchData, setMatchData] = useState<Res<any> | null>(null);
  const [perfectMatchData, setPerfectMatchData] = useState<Res<any> | null>(
    null
  );

  async function getPerfectMatchData(
    page: number,
    filter: Object
  ): Promise<any> {
    return await getPerfectMatch({
      pageIndex: page,
      pageSize: 5,
      ...filter
    })
      .unwrap()
      .catch(() => {
        setIsError(true);
      });
  }

  async function getMatchData(page: number, filter: Object): Promise<any> {
    return await getMatch({
      pageIndex: page,
      pageSize: 5,
      ...filter
    })
      .unwrap()
      .catch(() => {
        setIsError(true);
      });
  }

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setShowBackToTop(scrollY > 0);
  }, []);

  useEffect(() => {
    let prevScrollY = 0;
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      const isScrollingUp = scrollY < prevScrollY;
      prevScrollY = scrollY;
      if (isScrollingUp) {
        handleScroll();
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const makeOptions = useCallback(
    (groupedOptions: GroupedOption[]) => {
      const res = groupedOptions.map((item) => item.label);

      return res
        .map((optionName) =>
          filter[optionName.toLowerCase()]?.map((x) =>
            groupedOptions
              .find(({ label }) => label === optionName)
              ?.options.find(({ value }) => value === Number(x))
          )
        )
        .filter((item) => item !== undefined)
        .flatMap((item) => item);
    },

    [filter]
  );

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }

    const queryString = Object.entries(filter)
      .map(
        ([key, value]) =>
          `${key}=${value.map((v) => encodeURIComponent(v)).join(',')}`
      )
      .join('&');

    navigate(`/candidate?${queryString}`);
  }, [filter, isInitialRender, navigate]);

  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      (async () => {
        getPerfectMatchData(1, filter)
          .then((responsePerfectMatchData) => {
            setPerfectMatchData(responsePerfectMatchData);
          })
          .catch(() => {
            setIsError(true);
          });

        getMatchData(1, filter)
          .then((responseMatchData) => {
            setMatchData(responseMatchData);
          })
          .catch(() => {
            setIsError(true);
          });
      })();
    }
    // eslint-disable-next-line
  }, [filter]);

  const loadMore = async (page: number) => {
    if (!isLoadingGetPerfectMatch) {
      const responseData = await getPerfectMatchData(page, filter);
      setPerfectMatchData((prev) => ({
        ...responseData,
        items: [...prev?.items, ...responseData.items]
      }));
    }
  };

  const loadMatchMore = async (page: number) => {
    if (!isLoadingGetMatch) {
      const responseData = await getMatchData(page, filter);
      setMatchData((prev) => ({
        ...responseData,
        items: [...prev?.items, ...responseData.items]
      }));
    }
  };

  const toInterviewRequest = (id: number | string, param: string) => {
    navigate(`/interview/${id}${param}`);
  };

  if (getPathCandidateList()) {
    localStorage.removeItem(StorageKey.CandidateList);
  }

  const showTitle = Object.keys(filter).length && perfectMatchData;

  const isOnline = useInternetStatus();

  useEffect(() => {
    setIsError(!isOnline);
  }, [isOnline]);

  return (
    <Wrapper>
      <MainSearch
        options={groupedOptions}
        setFilter={setFilter}
        styles={CandidateSearchStyles}
        value={makeOptions(groupedOptions)}
      />
      <Container>
        <Filter filter={filter} setFilter={setFilter} />
        <CardWrapper>
          {Object.keys(filter).length ? (
            <>
              {showTitle && !isError ? (
                <Title>
                  We found {perfectMatchData?.totalCount} candidates with
                  perfect match
                </Title>
              ) : isError ? (
                <Box
                  alignItems='center'
                  display='flex'
                  justifyContent='center'
                  mt='100px'
                >
                  <CandidatesErrorHandler />
                </Box>
              ) : (
                <Box mb='22px'>
                  <TitleSkeleton />
                </Box>
              )}

              {!isError && (
                <InfiniteScroll
                  hasMore={perfectMatchData?.hasNextPage}
                  loadMore={loadMore}
                  loader={<CandidatesScrollLoader />}
                  pageStart={1}
                >
                  {perfectMatchData ? (
                    perfectMatchData.items.map((card: Employee) => (
                      <CardInterview
                        additionalParams={objectToGetParamsConvert(filter)}
                        dataInfo={card}
                        isEmployee={false}
                        key={card.id}
                        onCardButtonClick={(data?: string) =>
                          toInterviewRequest(card.id, data || '')
                        }
                        text='Interview Request'
                      />
                    ))
                  ) : (
                    <CandidatesLoader />
                  )}
                </InfiniteScroll>
              )}
              {perfectMatchData?.hasNextPage === false && (
                <>
                  {!isError && (
                    <Title>
                      We found {matchData?.totalCount} candidates with not
                      perfect match
                    </Title>
                  )}
                  {!isError && (
                    <InfiniteScroll
                      hasMore={matchData?.hasNextPage}
                      loadMore={loadMatchMore}
                      loader={<Loader />}
                      pageStart={1}
                    >
                      {matchData &&
                        matchData.items.map((card: Employee) => (
                          <CardInterview
                            additionalParams={objectToGetParamsConvert(filter)}
                            dataInfo={card}
                            isEmployee={false}
                            key={card.id}
                            onCardButtonClick={(data?: string) =>
                              toInterviewRequest(card.id, data || '')
                            }
                            text='Interview Request'
                          />
                        ))}
                    </InfiniteScroll>
                  )}
                </>
              )}
            </>
          ) : (
            <div style={{ width: 1032 }}>Select some filters</div>
          )}
        </CardWrapper>
        {showBackToTop && !isError && <BackToTop />}
      </Container>
    </Wrapper>
  );
};

export default Candidate;

import styled from '@emotion/styled';

export const ModalContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const FormWrapper = styled.div`
  flex: 1 1 auto;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
`;

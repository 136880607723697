import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

import mainBackground from '@/assets/images/photo/mainBackground.png';
import { Typography } from '@/components';

export const Wrapper = styled.div`
  height: calc(100vh - 64px);
  width: 100vw;
  background-color: #ebf4fc;
  background-image: url(${mainBackground});
  background-position: bottom left, bottom right;
  background-repeat: no-repeat;
`;

export const Container = styled.div`
  display: flex;
  padding: 56px 24px 0 204px;
  width: 100%;
  flex-direction: column;
`;

export const Tab = styled.div`
  max-width: 880px;
  width: 100%;
`;

export const Title = muiStyled(Typography)(({ theme }) => ({
  marginBottom: '24px',
  marginTop: '32px',
  color: theme.palette.primaryRoot.greyDark
}));

import {
  Bullet,
  Order,
  Redo,
  TextBold,
  TextItalic,
  TextStrike,
  TextUnderline,
  Undo
} from '@/assets/images/icon';

import { sizeOptions } from './editor.config';
import {
  StyledBulletIconButton,
  StyledIconButton,
  StyledOrderedIconButton,
  ToolbarBlock
} from './styles';

export const QuillToolbar = () => (
  <div data-testid='toolbar' id='toolbar'>
    <ToolbarBlock className='ql-formats' style={{ marginRight: '8px' }}>
      <StyledIconButton className='ql-undo' data-testid='undo'>
        <Undo />
      </StyledIconButton>
      <StyledIconButton className='ql-redo' data-testid='redo'>
        <Redo />
      </StyledIconButton>
    </ToolbarBlock>

    <span className='ql-formats' style={{ marginRight: 0, display: 'none' }}>
      <select className='ql-header' defaultValue='3'>
        {sizeOptions.map((option) => (
          <option key={option.name + option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </span>

    <ToolbarBlock
      className='ql-formats'
      style={{ marginRight: '8px', display: 'none' }}
    >
      <select className='ql-align' />
    </ToolbarBlock>

    <ToolbarBlock
      className='ql-formats'
      style={{ marginRight: '8px', display: 'none' }}
    >
      <select className='ql-color' />
    </ToolbarBlock>

    <ToolbarBlock className='ql-formats' style={{ marginRight: '8px' }}>
      <StyledIconButton
        className='ql-bold'
        data-testid='bold'
        sx={{ width: '28px !important' }}
      >
        <TextBold />
      </StyledIconButton>
      <StyledIconButton
        className='ql-italic'
        data-testid='italic'
        sx={{ width: '28px !important' }}
      >
        <TextItalic />
      </StyledIconButton>
      <StyledIconButton
        className='ql-underline'
        sx={{ width: '28px !important', display: 'none !important' }}
      >
        <TextUnderline />
      </StyledIconButton>
      <StyledIconButton
        className='ql-strike'
        sx={{ width: '28px !important', display: 'none !important' }}
      >
        <TextStrike />
      </StyledIconButton>
    </ToolbarBlock>

    <span className='ql-formats'>
      <StyledOrderedIconButton
        className='ql-list'
        data-testid='ordered'
        value='ordered'
      >
        <Bullet id='ordered' />
      </StyledOrderedIconButton>
      <StyledBulletIconButton
        className='ql-list'
        data-testid='bullet'
        value='bullet'
      >
        <Order id='bullet' />
      </StyledBulletIconButton>
    </span>
  </div>
);

import * as Yup from 'yup';

export const MAX_CHARTERS = 2500;

export const validationSchema = Yup.object().shape({
  about: Yup.string()
    .required('About is required')
    .max(MAX_CHARTERS, 'About must be at most 2500 characters')
    .test('noCyrillic', 'Invalid value', (value) => !/[а-яА-Я]/.test(value))
    .test(
      'noLinks',
      'You can not use links in the field',
      (value) => !/(https?|ftp|www)\S+/i.test(value)
    )
});

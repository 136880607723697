import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OrFrame } from '@/assets/images/icon';
import { Button, HelperText, GoogleLoginButton } from '@/components';
import { Pages, nameValidationSchema } from '@/constants';
import { useSignUpFormState } from '@/context/formContext';
import { useAuth } from '@/hooks';
import {
  WrapperBtnLogin,
  SignInForm,
  OrStroke,
  ButtonNextWrapper
} from '@/page/(auth)/signUp/styles';
import { useSignUpGoogleMutation } from '@/redux/api/auth/authApi';
import { DEFAULT_COOKIE_KEY, setDefaultAuthCookieKey } from '@/service/api';

import { ISignUpFields } from '@/types/signupFields';

interface NameStepProps {
  _test_fn?: () => void;
}

export const NameStep: FC<NameStepProps> = ({ _test_fn }) => {
  const navigate = useNavigate();

  const { setToken } = useAuth();
  const [signUpGoogle] = useSignUpGoogleMutation();

  const { onHandleNext, setFormData, formData } = useSignUpFormState();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ISignUpFields & { firstName: string; lastName: string }>({
    mode: 'all',
    defaultValues: formData as { firstName: string; lastName: string },
    resolver: yupResolver(nameValidationSchema)
  });

  const googleSignUpHandler = ({ credential }: CredentialResponse) => {
    if (credential) {
      signUpGoogle({
        idToken: credential
      })
        .then(() => {
          setToken(DEFAULT_COOKIE_KEY);
          setDefaultAuthCookieKey();
          navigate(Pages.HOME);
          _test_fn?.();
        })
        .catch((error) => {
          setError('firstName', { message: error?.data?.Message });
          setError('lastName', { message: error?.data?.Message });
        });
    }
  };

  const onSubmit = (data: ISignUpFields) => {
    onHandleNext();
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };

  return (
    <SignInForm onSubmit={handleSubmit(onSubmit)}>
      <Box mt='32px' width='100%'>
        <TextField
          autoComplete='off'
          fullWidth
          label='First name'
          type='text'
          variant='outlined'
          {...register('firstName')}
          error={!!errors.firstName}
        />
        {!!errors.firstName && <HelperText text={errors.firstName?.message} />}
      </Box>

      <Box mt='32px' width='100%'>
        <TextField
          autoComplete='off'
          fullWidth
          label='Last name'
          type='text'
          variant='outlined'
          {...register('lastName')}
          error={!!errors.lastName}
        />
        {!!errors.lastName && <HelperText text={errors.lastName?.message} />}
      </Box>

      <ButtonNextWrapper>
        <Button fullWidth size='large' text='Next' type='submit' />
      </ButtonNextWrapper>
      <OrStroke>
        <OrFrame />
      </OrStroke>

      <Box mt='24px' width='100%'>
        <Button
          fullWidth
          size='large'
          text='Continue with Google'
          variant='google'
        />
        <WrapperBtnLogin>
          <GoogleLoginButton onSuccess={googleSignUpHandler} />
        </WrapperBtnLogin>
      </Box>
    </SignInForm>
  );
};

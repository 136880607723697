import { FC } from 'react';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from 'react-hook-form';

import { StyledDatePicker } from './styles';

const StyledCalendarMonthIcon = () => (
  <CalendarMonthOutlinedIcon
    sx={{ fill: (theme) => theme.palette.stroke?.['40'] }}
  />
);

interface ICalendarComponent {
  control: any;
  name: string;
  errors: any;
  label?: string;
  value?: Date;
  setValue: (val: Date) => void;
  disablePast?: boolean;
}

export const Calendar: FC<ICalendarComponent> = ({
  control,
  name,
  errors,
  label = 'Date',
  value,
  setValue,
  disablePast = true
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <StyledDatePicker
          data-testid='calendarTest'
          disablePast={!!disablePast}
          error={!!errors}
          label={label}
          onChange={(newValue: any) => {
            if (newValue) {
              setValue(newValue);
            }

            field.onChange(newValue);
          }}
          slots={{
            openPickerIcon: StyledCalendarMonthIcon
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #70A500'
              },
              '&:hover fieldset': {
                border: '1px solid #537A00'
              },
              '&.Mui-focused fieldset': {
                border: '1px solid #A7F582'
              }
            },
            '& .MuiInputLabel-root': {
              color: '#2B5659',
              '&.Mui-focused': {
                color: '#2B5659'
              }
            },
            '& .MuiInputBase-input': {
              color: '#2B5659'
            }
          }}
          value={value}
        />
      )}
    />
  </LocalizationProvider>
);

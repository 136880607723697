import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  CredWrapper,
  HelperText,
  LanguageLevels,
  SelectSkills,
  Typography
} from '@/components';
import { Pages } from '@/constants';
import { useEmployeeFormState } from '@/context/formContext';
import { useDictionary } from '@/hooks';
import {
  Container,
  Decsription,
  ButtonWrapperSB,
  SubtitleMain,
  HeadWrapper,
  HeadWrapperRight,
  Name,
  Subtitle,
  Logo,
  DollarWrapper,
  Dollar,
  DollarText,
  Form
} from '@/page/employee/create/styles';
import { ThemedContainer } from '@/providers';
import { useCreateEmployeeMutation } from '@/redux/api/employee/employeeApi';
import { useGetSettingsByKeyQuery } from '@/redux/api/settings/settingsApi';

import { Employee } from '@/types/employee';

import {
  employeeDefaultValues,
  employeeValidationSchema
} from './skills.config';

interface SkillItem {
  id: number;
  name: string;
}

interface DictionaryItem {
  id: number;
  name: string;
}

interface FormData {
  employeeSkills: {
    id: number;
    name: string;
  }[];
  employeeLanguageLevels: {
    name?: string;
    language: {
      id: number;
      name: string;
    };
    languageLevel: {
      id: number;
      name: string;
    };
  }[];
}

const Skills = () => {
  const navigate = useNavigate();

  const { onHandleBack, resetEmployeeData, employeeData } =
    useEmployeeFormState();

  const { data: price } = useGetSettingsByKeyQuery('PriceMarkup');
  const [createEmployee] = useCreateEmployeeMutation();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: employeeDefaultValues,
    resolver: yupResolver(employeeValidationSchema)
  });

  const employeeLanguageLevels: any = watch('employeeLanguageLevels');
  const employeeSkills: SkillItem[] = watch('employeeSkills');

  useEffect(() => {
    return () => {
      reset(employeeData);
    };
    // eslint-disable-next-line
  }, [reset]);

  const handleSkillSelection = (newValues: SkillItem[]) => {
    let updatedSkills: SkillItem[] = [...employeeSkills];

    newValues.forEach((skill) => {
      if (!updatedSkills.some((s) => s.id === skill.id)) {
        updatedSkills.push(skill);
      }
    });

    updatedSkills = updatedSkills.filter((skill) =>
      newValues.some((s) => s.id === skill.id)
    );

    setValue('employeeSkills', updatedSkills);
  };

  const onSubmit = async (data: FormData) => {
    const newEmployee = {
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      price: employeeData.price,
      employeeStatus: {
        id: employeeData.employeeStatus.id
      },
      employeeStatusId: employeeData.employeeStatus.id,
      seniorityId: employeeData.seniority.id,
      seniority: employeeData.seniority,
      employeeSpecializations: [employeeData.employeeSpecializations],
      employeeCountries: [employeeData.employeeCountries],
      employeeSkills: data.employeeSkills,
      employeeLanguageLevels: data.employeeLanguageLevels
    };

    // TODO: should normalize types for the newEmployee
    createEmployee(newEmployee as Employee)
      .unwrap()
      .then((response) => {
        navigate(`${Pages.EMPLOYEE}/${response.id}`);
        resetEmployeeData?.();
      })
      .catch(() => {
        enqueueSnackbar('Oops, something went wrong', {
          variant: 'error'
        });
      });
  };

  const marginPrice: number = price?.value ? +price.value : 1;

  const { dictionaries } = useDictionary();
  const skillsDictionary: DictionaryItem[] = dictionaries.SkillsAndTechnologies;
  const languagesDictionary = dictionaries.Languages;
  const languageLevelsDictionary = dictionaries.LanguageLevels;

  return (
    <ThemedContainer>
      <Container data-testid={'Skills component'}>
        <Typography variant='displayMedium'>Skills & Techs</Typography>
        <Decsription>
          Showcase skills, technologies mastered, and language proficiency
          levels on employee profile. Share knowledge and strengths to make a
          lasting impression on potential clients.
        </Decsription>
        <HeadWrapper>
          <Logo>
            {employeeData.firstName?.charAt(0)}
            {employeeData.lastName?.charAt(0)}
          </Logo>
          <HeadWrapperRight>
            <Name>
              {employeeData.firstName} {employeeData.lastName}
            </Name>
            <CredWrapper
              mainInfo={{
                specialization: employeeData.employeeSpecializations?.name,
                seniority: employeeData.seniority?.name,
                location: employeeData.employeeCountries?.name,
                fontSize: '14px'
              }}
            />
            <DollarWrapper>
              <Dollar />
              <DollarText>
                ${Math.ceil(Number(employeeData.price) * marginPrice)}/ h
              </DollarText>
            </DollarWrapper>
          </HeadWrapperRight>
        </HeadWrapper>

        <SubtitleMain>Skills & Technologies </SubtitleMain>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box mt='15px' width='100%'>
            <SelectSkills
              control={control}
              errors={errors}
              label='Skills & Technologies'
              name='employeeSkills'
              onSkillSelection={handleSkillSelection}
              options={skillsDictionary}
              selectedSkills={employeeSkills}
              value={employeeSkills.map((skillId) => {
                const skill = skillsDictionary.find(
                  (item: any) => item.id === skillId.id
                );
                return {
                  name: skill ? skill.name : '',
                  id: skill?.id || 0
                };
              })}
            />
            <HelperText text={errors['employeeSkills']?.message} />
          </Box>

          <Subtitle>Languages</Subtitle>
          <LanguageLevels
            addNewLanguage={() => {
              setValue('employeeLanguageLevels', [
                ...getValues('employeeLanguageLevels'),
                {
                  language: { id: 0, name: '' },
                  languageLevel: { id: 0, name: '' }
                }
              ]);
            }}
            control={control}
            deleteNewLanguage={(idx: number) => {
              const list = getValues('employeeLanguageLevels');
              list.splice(idx, 1);
              setValue('employeeLanguageLevels', list);
            }}
            errors={errors['employeeLanguageLevels']}
            languageLevels={employeeLanguageLevels}
            optionsLanguage={languagesDictionary}
            optionsLevel={languageLevelsDictionary}
          />

          <ButtonWrapperSB>
            <Button
              onClick={() => onHandleBack()}
              text='Previous Step'
              variant='prev'
            />
            <Button size='medium' text='Save' type='submit' variant='next' />
          </ButtonWrapperSB>
        </Form>
      </Container>
    </ThemedContainer>
  );
};

export default Skills;

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';
import { UploadImageResponse } from '@/redux/api/file/fileApi.types';

export const fileApi = createApi({
  reducerPath: 'api/file',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    uploadImage: build.mutation<UploadImageResponse, FormData>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.UPLOAD_IMAGE,
        headers: {
          Accept: '*/*'
        },
        body: data
      })
    }),
    uploadCv: build.mutation<unknown, FormData>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.UPLOAD_CV,
        headers: {
          Accept: '*/*'
        },
        body: data
      })
    })
  })
});

export const { useUploadImageMutation, useUploadCvMutation } = fileApi;

import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { FieldPath, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, HelperText, Typography } from '@/components';
import { Pages, signUpPasswordValidationSchema } from '@/constants';
import { Form, SupportLink } from '@/page/(auth)/change-password/styles';
import { useResetPasswordPostMutation } from '@/redux/api/auth/authApi';

interface ChangeFormProps {
  token: string;
}

interface FormData {
  password: string;
  confirmPassword: string;
}

export const ChangeForm: FC<ChangeFormProps> = ({ token }) => {
  const resultToken = token.replace(/ /g, '+');

  const navigate = useNavigate();

  const [resetPasswordPost] = useResetPasswordPostMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    resolver: yupResolver(signUpPasswordValidationSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isConfirmPasswordFocused, setIsConfirmPasswordFocused] =
    useState(false);

  const onSubmit = async (data: FormData) => {
    resetPasswordPost({
      token: resultToken,
      password: data.password
    })
      .unwrap()
      .then(() => navigate('/change-password/success'))
      .catch((error) => {
        setError('password', { message: error?.data?.Message });
        setError('confirmPassword', { message: error?.data?.Message });
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box mt='32px' width='100%'>
          <TextField
            autoComplete='off'
            fullWidth
            label='Password'
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            {...register('password' as FieldPath<FormData>)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isPasswordFocused && (
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            error={!!errors.password}
            onFocus={() => setIsPasswordFocused(true)}
          />
          <HelperText text={errors.password?.message} />
        </Box>

        <Box mt='26px' width='100%'>
          <TextField
            autoComplete='off'
            fullWidth
            label='Confirm Password'
            type={showConfirmPassword ? 'text' : 'password'}
            variant='outlined'
            {...register('confirmPassword' as FieldPath<FormData>)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isConfirmPasswordFocused && (
                    <IconButton
                      aria-label='toggle confirm password visibility'
                      edge='end'
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            error={!!errors.confirmPassword}
            onFocus={() => setIsConfirmPasswordFocused(true)}
          />
          <HelperText text={errors.confirmPassword?.message} />
        </Box>

        <Box mt='40px' width='100%'>
          <Button fullWidth size='large' text='Change' type='submit' />
        </Box>
      </Form>

      <Box mt='24px' width='100%'>
        <Button
          fullWidth
          onClick={() => navigate(Pages.HOME)}
          size='large'
          text='Cancel'
          variant='clearBorder'
        />
      </Box>
      <Box
        alignItems='center'
        display='flex'
        gap='12px'
        justifyContent='center'
        mt='55px'
      >
        <Typography variant='labelLarge'>Do you need help?</Typography>
        <SupportLink to={Pages.HOME}>Customer support</SupportLink>
      </Box>
    </>
  );
};

import { FC } from 'react';

import { Box } from '@mui/material';

import { CandidateCardSkeleton } from './CandidateCardSkeleton';

const cards: null[] = new Array(2).fill(null);

export const CandidatesScrollLoader: FC = () => (
  <>
    {cards.map((_, index) => (
      <Box key={index} mb='22px'>
        <CandidateCardSkeleton />
      </Box>
    ))}
  </>
);

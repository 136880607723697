import { FC } from 'react';

import { Info, Separator, WrapperCred } from './styles';

interface IMainInfo {
  specialization: string;
  seniority: string;
  location: string;
  price?: number;
  fontSize?: string;
}

interface IStepper {
  mainInfo: IMainInfo;
}

export const CredWrapper: FC<IStepper> = ({ mainInfo }) => {
  const { specialization, seniority, location, price, fontSize } = mainInfo;

  const fontSizeStyle = fontSize ? { fontSize: fontSize } : undefined;

  return (
    <WrapperCred>
      <Info style={fontSizeStyle}>{specialization}</Info>
      <Separator />
      <Info style={fontSizeStyle}>{seniority}</Info>
      <Separator />
      <Info style={fontSizeStyle}>{location}</Info>
      {price && (
        <>
          <Separator />
          <Info style={fontSizeStyle}>${mainInfo.price}/hr</Info>
        </>
      )}
    </WrapperCred>
  );
};

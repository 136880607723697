import { Dispatch, FC, SetStateAction, useState } from 'react';

import { InputActionMeta, StylesConfig } from 'react-select';

import { SearchSelect } from '@/components';
import { ThemedContainer } from '@/providers';
import { formatCamelCaseToWords, objKeyFormatter } from '@/util/misc';

import { DictionaryOption } from '@/types/dictionary';

interface Option {
  label: string;
  value: number;
  group: string;
}

export interface MainSearchProps {
  options: {
    label: string;
    options: Option[];
  }[];
  setFilter?: Dispatch<SetStateAction<Record<string, number[]>>>;
  onOptionSelect?: (selectedOptionValue: DictionaryOption) => void;
  selectedValue?: any;
  styles: StylesConfig;
  value?: any;
}

const MainSearch: FC<MainSearchProps> = ({
  options,
  setFilter,
  onOptionSelect,
  styles,
  value
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleChange = (selectedOptions: any) => {
    setFilter?.(() => {
      const resultObject: Record<string, number[]> = {};

      selectedOptions.forEach((item: any) => {
        if (resultObject[objKeyFormatter(item.group)]) {
          resultObject[objKeyFormatter(item.group)].push(item.value);
        } else {
          resultObject[objKeyFormatter(item.group)] = [item.value];
        }
      });

      return resultObject;
    });

    if (selectedOptions.length > 0 && onOptionSelect) {
      onOptionSelect({
        group: selectedOptions[0].group,
        label: selectedOptions[0].label,
        value: selectedOptions[0].value
      });
    }
  };

  return (
    <ThemedContainer theme='other'>
      <SearchSelect
        menuIsOpen={menuIsOpen}
        onChange={handleChange}
        onInputChange={(input: InputActionMeta) => setMenuIsOpen(!!input)}
        options={options.map((option) => ({
          ...option,
          label: formatCamelCaseToWords(option.label) // Add space between words
        }))}
        placeholder='Search by title, skill etc.'
        styles={styles}
        value={value}
      />
    </ThemedContainer>
  );
};

export default MainSearch;

import { styled } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export const BlueSelect = styled(Select)(({ theme }) => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.stroke?.['10']
  },
  '&.MuiOutlinedInput-root.Mui-focused >.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.main?.primary
  },
  '>.MuiSelect-select': {
    textAlign: 'left'
  },
  '&.MuiInputBase-root': {
    '>svg': {
      marginRight: '8px',
      minWidth: '24px'
    }
  }
}));

export const BlueLabel = styled(InputLabel)(({ theme }) => ({
  '&.Mui-focused': {
    color: theme.palette.main?.primary
  }
}));

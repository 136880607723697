import { useSelector } from 'react-redux';

import { useActions } from '@/hooks/useActions';
import { RootState } from '@/redux/store';

export const useAuth = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { setToken } = useActions();

  return { token, setToken };
};

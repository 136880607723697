import Cookies from 'js-cookie';
import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { CookieKey, Pages } from '@/constants';

export const RequireAuth = () => {
  const location = useLocation();

  if (!Cookies.get(CookieKey.Auth)) {
    return <Navigate state={{ from: location }} to={Pages.HOME} />;
  }

  return <Outlet />;
};

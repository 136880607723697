import Cookies from 'js-cookie';

import { CookieKey } from '@/constants';

export type CustomHeaders = { [key: string]: string };
export type APIVariables = {
  token?: string | null;
  baseURL?: string;
  customHeaders?: CustomHeaders;
  method: string;
};

export const API_URL = process.env.REACT_APP_API_URL;
export const DEFAULT_COOKIE_KEY = 'true';

export const setDefaultAuthCookieKey = (value?: string) => {
  Cookies.set(CookieKey.Auth, value ?? DEFAULT_COOKIE_KEY);
};

function convertToCamelCaseKeys(
  inputObject: Record<string, unknown>
): Record<string, unknown> {
  return Object.keys(inputObject).reduce((camelCasedObject, key) => {
    const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
    camelCasedObject[camelCaseKey] = inputObject[key];
    return camelCasedObject;
  }, {} as Record<string, unknown>);
}

export const decodeBase64 = <T>(base64: string): T => {
  try {
    return convertToCamelCaseKeys(JSON.parse(atob(base64))) as T;
  } catch (error) {
    console.error('Error relates to decodeBase64 func', error);
    return undefined as T;
  }
};

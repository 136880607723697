import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';

import { store } from '@/redux/store';

import { ThemedContainer } from './providers';
import { Routes } from './router';

import 'react-quill/dist/quill.snow.css';
import '@/assets/fonts/font.css';
import './main.css';

export const App = () => (
  <Provider store={store}>
    <ThemedContainer>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        maxSnack={3}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Routes />
        </GoogleOAuthProvider>
      </SnackbarProvider>
    </ThemedContainer>
  </Provider>
);

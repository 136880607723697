import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';

import { GetSettingsByKeyResponse } from './settings.types';

export const settingsApi = createApi({
  reducerPath: 'api/settings',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    getSettingsByKey: build.query<GetSettingsByKeyResponse, string>({
      query: (key) => `${Endpoints.SETTINGS_BY_KEY}?key=${key}`
    })
  })
});

export const { useGetSettingsByKeyQuery } = settingsApi;

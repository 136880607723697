import { FC } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

interface Option {
  name: string;
  id: number;
  group?: string;
}

interface SelectInputProps {
  name: string;
  label: string;
  control: any;
  options: Option[];
  errors: boolean;
  className?: string;
  getValue?: () => string;
  value?: any;
  onChange?: (selectedValue: Option) => void;
}

export const SelectInput: FC<SelectInputProps> = ({
  name,
  label,
  control,
  options,
  errors,
  className,
  value,
  onChange
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field }) => (
      <Autocomplete
        ListboxProps={{ style: { maxHeight: 200, overflowY: 'auto' } }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(_, newValue) => {
          if (onChange) {
            return onChange(newValue);
          }

          field.onChange(newValue);
        }}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: null // clear btn
            }}
            autoComplete='off'
            className={className}
            error={errors}
            fullWidth
            label={label}
            onBlur={field.onBlur}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  border: '1px solid #70A500'
                },
                '&:hover fieldset': {
                  border: '1px solid #537A00'
                },
                '&.Mui-focused fieldset': {
                  border: '1px solid #A7F582'
                }
              },
              '& .MuiInputLabel-root': {
                color: '#2B5659',
                '&.Mui-focused': {
                  color: '#2B5659'
                }
              },
              '& .MuiInputBase-input': {
                color: '#2B5659'
              }
            }}
            variant='outlined'
          />
        )}
        renderOption={(props, option) => (
          <li {...props} style={{ padding: '12px 23px' }}>
            {option.name}
          </li>
        )}
        value={value}
      />
    )}
    rules={{
      required: 'Please add a location'
    }}
  />
);

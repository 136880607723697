import { FC, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import { Logo } from '@/assets/images/icon';
import { Button, ButtonVariant } from '@/components';
import { Pages } from '@/constants';

// eslint-disable-next-line
import {
  CardWrapper,
  ContentBlock,
  Title,
  Text,
  CardHeader,
  HeadBlock
} from './styles';

interface IFinalCardProps {
  title: string;
  text: string;
  firstButtonName?: string;
  secondButtonName: string;
  firstButtonVariant?: ButtonVariant;
  secondButtonVariant?: ButtonVariant;
  onFirstButtonClick?: () => void;
  onSecondButtonClick: () => void;
  img?: ReactNode;
}

export const FinalCard: FC<IFinalCardProps> = ({
  title,
  text,
  firstButtonName,
  secondButtonName,
  firstButtonVariant = 'clear',
  secondButtonVariant = 'clearBorder',
  onFirstButtonClick,
  onSecondButtonClick,
  img
}) => (
  <CardWrapper>
    <CardHeader>
      <Link to={Pages.HOME}>
        <HeadBlock>
          <Logo />
        </HeadBlock>
      </Link>
    </CardHeader>

    <ContentBlock>
      <>{img}</>
      <Title>{title}</Title>
      <Text>{text}</Text>

      {firstButtonName && (
        <Button
          onClick={onFirstButtonClick}
          text={firstButtonName}
          type='button'
          variant={firstButtonVariant}
        />
      )}

      {secondButtonName && (
        <Button
          onClick={onSecondButtonClick}
          text={secondButtonName}
          variant={secondButtonVariant}
        />
      )}
    </ContentBlock>
  </CardWrapper>
);

import { FC } from 'react';

import styled from '@emotion/styled';
import { Box, Skeleton } from '@mui/material';

import { createMockedArray } from '@/util/misc';
import { PALETTE } from '@/util/palette';

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  max-width: 310px;
  transform: none;
  background-color: ${PALETTE.lightGrey};
`;

const TitleSkeleton = styled(StyledSkeleton)`
  background-color: ${PALETTE.stroke1};
  border-radius: 2px;
`;

export const FiltersLoader: FC = () => (
  <>
    <Box data-testid='filters-loader' mb='25px'>
      <TitleSkeleton data-testid='titleSkeletonId' height='14px' />
    </Box>
    {createMockedArray(5).map((_, index) => (
      <Box key={index} mb='12px'>
        <StyledSkeleton data-testid='styled-skeleton' height='78px' />
      </Box>
    ))}
  </>
);

import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const Form = styled('form')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export const SupportLink = styled(Link)(({ theme }) => ({
  ...theme.typography.labelMedium,
  color: theme.palette.text.link
}));

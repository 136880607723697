import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

import DollarIcon from '@/assets/images/icon/dollar.svg';
import TranslateIcon from '@/assets/images/icon/translate.svg';

interface StyleRootGap {
  isGap: boolean;
}

export const Row = styled.div<StyleRootGap>`
  display: flex;
  gap: ${(props) => (props.isGap ? '0px' : '28px')};
  align-items: center;
`;

export const Dollar = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${DollarIcon});
  background-size: 20px;
`;
export const Translate = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${TranslateIcon});
  background-size: 20px;
`;

export const Separator = muiStyled('div')(({ theme }) => ({
  borderRadius: '50%',
  width: '4px',
  height: '4px',
  margin: '0 8px',
  backgroundColor: theme.palette.grey[100]
}));

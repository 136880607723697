import { CredentialResponse } from '@react-oauth/google';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/redux/api/api';
import { Endpoints } from '@/redux/api/endpoints';

import {
  SignInDataPayload,
  SignUpDataPayload,
  SignInGoogleDataPayload,
  SignUpGooglePayload,
  IsEmailAlreadyRegisteredResponse,
  IsEmailAlreadyRegisteredPayload,
  ResetPasswordGetPayload,
  ResetPasswordPostPayload,
  ResendEmailConfirmationPayload
} from './authApi.types';

export const authApi = createApi({
  reducerPath: 'api/auth',
  baseQuery: baseQuery,
  endpoints: (build) => ({
    signIn: build.mutation<Promise<Response>, SignInDataPayload>({
      query: (data: SignInDataPayload) => ({
        method: 'POST',
        url: Endpoints.SIGN_IN,
        body: data
      })
    }),
    signUp: build.mutation<Promise<Response>, SignUpDataPayload>({
      query: (data: SignInDataPayload) => ({
        method: 'POST',
        url: Endpoints.SIGN_UP,
        body: data
      })
    }),
    signInGoogle: build.mutation<unknown, SignInGoogleDataPayload>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.SIGN_IN_GOOGLE,
        body: data
      }),
      extraOptions: { maxRetries: 2 }
    }),
    signUpGoogle: build.mutation<CredentialResponse, SignUpGooglePayload>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.SIGN_UP_GOOGLE,
        body: data
      })
    }),
    isEmailAlreadyRegistered: build.mutation<
      IsEmailAlreadyRegisteredResponse,
      IsEmailAlreadyRegisteredPayload
    >({
      query: (email) => ({
        method: 'GET',
        url: `${Endpoints.IS_EMAIL_ALREADY_REGISTERED}?email=${email}`
      })
    }),
    resetPasswordGet: build.mutation<unknown, ResetPasswordGetPayload>({
      query: (data) => ({
        method: 'GET',
        url: `${Endpoints.RESET_PASSWORD_GET}?email=${data.email}`
      })
    }),
    resetPasswordPost: build.mutation<unknown, ResetPasswordPostPayload>({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.RESET_PASSWORD_POST,
        body: data
      })
    }),
    resendEmailConfirmation: build.mutation<
      unknown,
      ResendEmailConfirmationPayload
    >({
      query: (data) => ({
        method: 'POST',
        url: Endpoints.RESEND_EMAIL_CONFIRMATION,
        body: data
      })
    }),
    logOut: build.mutation<void, void>({
      query: () => ({ method: 'POST', url: Endpoints.LOGOUT })
    })
  })
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useSignInGoogleMutation,
  useSignUpGoogleMutation,
  useIsEmailAlreadyRegisteredMutation,
  useResetPasswordGetMutation,
  useResetPasswordPostMutation,
  useResendEmailConfirmationMutation,
  useLogOutMutation
} = authApi;

import { FC } from 'react';

import { Header } from '@/components';
import { useSignUpFormState } from '@/context/formContext';

import { EmailStep, NameStep, PasswordStep, EmailVerification } from './_step';

enum Steps {
  EMAIL = 1,
  NAME,
  PASSWORD,
  RESEND_EMAIL
}

interface SignUpProps {
  _test_fn?: () => void;
  _test_step?: Steps;
}

export const SignUpPage: FC<SignUpProps> = ({ _test_fn, _test_step }) => {
  const { step, resetFormData } = useSignUpFormState();

  const renderStepComponent = (stepValue: Steps) => {
    switch (stepValue) {
      case Steps.EMAIL:
        return <EmailStep _test_fn={_test_fn} />;
      case Steps.NAME:
        return <NameStep _test_fn={_test_fn} />;
      case Steps.PASSWORD:
        return <PasswordStep _test_fn={_test_fn} />;
      default:
        return <EmailVerification _test_fn={_test_fn} />;
    }
  };

  const headerProps = () => {
    if (step === Steps.RESEND_EMAIL) {
      return {};
    }

    return {
      description: 'Have an account?',
      descriptionLink: 'Sign In',
      link: '/signIn',
      title: 'Create an account',
      onDescriptionLinkClick: resetFormData
    };
  };

  return (
    <Header {...headerProps()}>
      {renderStepComponent(_test_step ? _test_step : step)}
    </Header>
  );
};

import * as yup from 'yup';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^[^\s]+(?:$|.*[^\s]+$)/;
export const WHITE_SPACE_REGEX = /^[^\s]+(?:$|.*[^\s]+$)/;
export const NO_CYRILLIC_REGEX = /[А-Яа-яЁё]/;
export const NO_SPECIAL_CHARS_REGEX = /^(?!.*(['"`-]){2,})[A-Za-z'"`-]+$/;
export const NO_NUMBERS_REGEX = /^[0-9]+$/;
export const NO_LINKS_REGEX = /((https?|ftp|ftps):\/\/|www\.)\S+/;
export const LATIN_WITH_SYMBOLS_REGEX =
  /^[A-Za-z\s!@#$%^&*()_+{}[\]:;<>,.?~\\-]+$/;
export const NUMBER_SYMBOL_WORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\-]).+$/; // Number, symbol, word are required

export const signUpPasswordValidationSchema = yup
  .object({
    password: yup
      .string()
      .required('Please enter a password')
      .matches(WHITE_SPACE_REGEX, 'Cannot start or end with spaces')
      .min(8, 'Sorry, your password must be between 8 and 32 characters long')
      .max(32, 'Sorry, your password must be between 8 and 32 characters long')
      .matches(
        NUMBER_SYMBOL_WORD_REGEX,
        'The password is weak. Try mixing letters, numbers, symbols.'
      ),
    confirmPassword: yup
      .string()
      .required('Please enter a password')
      .oneOf([yup.ref('password')], 'Password don`t match')
  })
  .required();

export const emailValidationSchema = yup
  .object({
    email: yup
      .string()
      .required('Please enter an email address')
      .min(5, 'Sorry, your email must be between 5 and 256 characters long')
      .max(256, 'Sorry, your email must be between 5 and 256 characters long')
      .matches(EMAIL_REGEX, 'Invalid email format')
  })
  .required();

const minMaxFirstName =
  'Sorry, your first name must be between 2 and 32 characters long';
const minMaxLastName =
  'Sorry, your last name must be between 2 and 32 characters long';
const validMessage = 'Are you sure you entered your name correctly?';
export const nameValidationSchema = yup
  .object({
    firstName: yup
      .string()
      .label('First Name')
      .trim()
      .required('Please enter a first name')
      .min(2, minMaxFirstName)
      .max(32, minMaxFirstName)
      .matches(NO_SPECIAL_CHARS_REGEX, validMessage),
    lastName: yup
      .string()
      .label('Last Name')
      .trim()
      .required('Please enter a last name')
      .min(2, minMaxLastName)
      .max(32, minMaxLastName)
      .matches(NO_SPECIAL_CHARS_REGEX, validMessage)
  })
  .required();

export const signInValidationSchema = yup
  .object({
    email: yup
      .string()
      .required('Please enter an email address')
      .min(5, 'Sorry, your email must be between 5 and 256 characters long')
      .max(256, 'Sorry, your email must be between 5 and 256 characters long')
      .matches(EMAIL_REGEX, 'Invalid email format'),
    password: yup
      .string()
      .required('Please enter a password')
      .min(6, 'Sorry, your password must be between 6 and 1024 characters long')
      .max(
        1024,
        'Sorry, your password must be between 6 and 1024 characters long'
      )
      .matches(PASSWORD_REGEX, 'Invalid password format')
  })
  .required();

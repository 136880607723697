import styled from '@emotion/styled';

import { PALETTE } from '@/util/palette';

interface StyleRoot {
  isGray: string;
}

export const WrapperStep = styled.div`
  display: flex;
  align-items: center;
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  width: 48px;
  height: 1px;
  background: ${PALETTE.secondaryGrey};
  margin: 0 8px 0 16px;
`;

export const AboveText = styled.p<StyleRoot>`
  font-family: 'FS Albert Pro Latin', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: ${(props) => props.isGray};
`;

export const BottomText = styled.p<StyleRoot>`
  font-family: 'FS Albert Pro Latin', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: ${(props) => props.isGray};
`;

export const Ball = styled.div<StyleRoot>`
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  background-color: ${(props) => props.isGray};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextInBall = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: white;
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 8px;
`;

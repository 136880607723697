import { FC, ReactNode } from 'react';

import { Modal as MuiModal } from '@mui/material';

import { ModalContent, ModalHead, ModalIcon, ModalTitle } from './styles';

export type ModalSize = 'big' | 'small';

interface ModalProps {
  children: ReactNode;
  active: boolean;
  onClose: () => void;
  modalTitle: string;
  variant?: ModalSize;
}

export const Modal: FC<ModalProps> = ({
  active,
  onClose,
  children,
  modalTitle,
  variant = 'big'
}) => (
  <MuiModal
    onClose={onClose}
    open={active}
    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    <ModalContent
      active={active}
      onClick={(e) => e.stopPropagation()}
      variant={variant}
    >
      <ModalHead>
        <ModalTitle>{modalTitle}</ModalTitle>
        <ModalIcon onClick={onClose} />
      </ModalHead>
      {children}
    </ModalContent>
  </MuiModal>
);

import { Stepper } from '@/components';
import { useEmployeeFormState } from '@/context/formContext';

import BaseInfo from './_step/base';
import Skills from './_step/skills';
import Specialization from './_step/specialization';
import Status from './_step/status';
import { StepperWrapper } from './styles';

enum Steps {
  BASEINFO = 1,
  SPECIALIZATION,
  STATUS,
  SKILLS
}

export const Employee = () => {
  const { step } = useEmployeeFormState();

  const renderStepComponent = (stepValue: Steps) => {
    switch (stepValue) {
      case Steps.BASEINFO:
        return <BaseInfo />;
      case Steps.SPECIALIZATION:
        return <Specialization />;
      case Steps.STATUS:
        return <Status />;
      case Steps.SKILLS:
        return <Skills />;
      default:
        return null;
    }
  };

  return (
    <StepperWrapper>
      <Stepper numberStep={step} /> {renderStepComponent(step)}
    </StepperWrapper>
  );
};

import { CreateStyled } from '@emotion/styled';

// The issue is: If you want to write it to the DOM, pass a string instead: error="false" or error={value.toString()}.
// https://github.com/emotion-js/emotion/issues/2193#issuecomment-958381691

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$')
};

// just an example of a $property, thought it does not make so much sense this way lol
// interface StyledProps {
//   $darkTheme: boolean
// }
//
// export const MyComponent= styled('div', transientOptions)<StyledProps>`
//   background-color: var(--surface-${props => props.$darkTheme});

import styled from '@emotion/styled';

export const RootCandidate = styled.div``;

export const Title = styled.div`
  margin: 0 0 26px 0;
  font-family: 'FS Albert Pro Latin', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #27272a;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1100px;
  margin: 48px auto 0 auto;
`;
export const Container = styled.div`
  display: flex;
  width: fit-content;
  gap: 24px;
  margin: 50px auto;
  width: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 836px;
  width: 100%;
  margin: 0 auto;
`;

import { Box, styled } from '@mui/material';

import { Error } from '@/assets/images/icon';

import {
  CustomTypographyProps,
  Typography,
  TypographySpacings
} from '@/components/typography/Typography';

const StyledImg = styled(Error)`
  margin-right: 4px;
  transform: translateY(1px);
`;

interface HelperTextProps extends Omit<CustomTypographyProps, 'children'> {
  text?: string;
}

export const HelperText = ({
  text = '',
  color = 'main.wrong',
  variant = 'bodySmall',
  ...restProps
}: HelperTextProps & TypographySpacings) => (
  <Box alignItems='flex-start' display='flex' mr='14px' mt='3px'>
    {text && (
      <>
        <StyledImg />
        <Typography color={color} role='alert' variant={variant} {...restProps}>
          {text}
        </Typography>
      </>
    )}
  </Box>
);

import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material';

import { Typography } from '@/components/typography/Typography';

export const WrapperCard = muiStyled('div')(({ theme }) => ({
  height: 'auto',
  minHeight: '204px',
  miWidth: '548px',
  display: 'flex',
  border: `1px solid ${theme.palette?.stroke?.[10]}`,
  borderRadius: '4px',
  padding: '16px 28px',
  marginBottom: '22px'
}));

export const InfoLabel = styled.div`
  height: auto;
  display: flex;
  column-gap: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  justify-content: space-around;
`;

export const WrapperSalaryLanguage = styled.div`
  margin: 20px 0;
`;
export const BtnEdit = styled.div`
  min-width: 156px;
  margin-left: auto;
`;

export const InfoBlock = styled.div`
  padding-left: 5px;
  width: 100%;
`;

export const Photo = styled.div`
  margin-right: 16px;
`;

export const SkillsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Skills = styled.div`
  display: flex;
  justify-content: center;
  background: #f4f4f5;
  padding: 8px;
  text-transform: none;
  box-shadow: none;
  border: 1px solid #f4f4f5;
  border-radius: 2px;
`;

export const AboveWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const WrapperAboveBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelActive = styled(Typography)`
  height: 28px;
  width: 95px;
  border-radius: 4px;
  background: #f1f9f1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelContract = styled(Typography)`
  height: 28px;
  width: 91px;
  border-radius: 4px;
  background: #f4f4f5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { css } from '@emotion/react';

export const logo = css`
  font-style: normal;
  font-weight: 500;
  color: var(--blackText);
  font-size: 12px;
  line-height: 120%;
`;

export const button = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: initial;
  letter-spacing: 0.1px;
  font-family: 'FS Albert Pro Latin', sans-serif;
`;

export const buttonCrEmp = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.1px;
  font-family: 'FS Albert Pro Latin', sans-serif;
`;

export const buttonNext = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  font-family: 'FS Albert Pro Latin', sans-serif;
`;

export const buttonSeniority = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
`;

export const buttonCardEmployee = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.67px;
`;

export const link = css`
  font-style: normal;
  font-weight: 500;
  color: var(--blue);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const linkMedium = css`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;
/**********************************************/
export const linkLittle = css`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
`;

export const titleReg = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blackText);
  font-size: 32px;
  line-height: normal;
`;

export const titleCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blackText);
  font-size: 28px;
  line-height: 120%;
`;

export const titleCrProfile = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blackText);
  font-size: 18px;
  line-height: 16px;
`;

export const titleModal = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blackText);
  font-size: 20px;
  line-height: 120%;
`;
/**********************************************/
export const subtitleCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blackText);
  font-size: 18px;
  line-height: 120%;
`;
/**********************************************/
export const descCrEmp = css`
  font-style: normal;
  font-weight: 400;
  color: var(--grey-dark);
  font-size: 16px;
  line-height: 135.5%;
`;

export const descCrProfile = css`
  font-style: normal;
  font-weight: 400;
  color: var(--grey-dark);
  font-size: 16px;
  line-height: 140%;
`;

export const descReg = css`
  font-style: normal;
  font-weight: 400;
  color: var(--grey-dark);
  font-size: 16px;
  line-height: normal;
`;

export const descRegAgree = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.8;
`;
/**********************************************/

export const nameCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
  font-size: 18px;
  line-height: 16px;
`;

export const bigNameCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--black-title);
  font-size: 28px;
  line-height: 16px;
`;

export const locationCrEmp = css`
  font-style: normal;
  font-weight: 400;
  color: var(--black);
  font-size: 16px;
  line-height: 20.8px;
`;

export const logoCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
  font-size: 24px;
  line-height: 16px;
`;

export const logoHeaderEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--white);
  font-size: 16px;
`;

export const bigLogoCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
  font-size: 64px;
  line-height: 16px;
`;

export const cardTCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--blue);
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.8px;
`;

export const cardSCrEmp = css`
  font-style: normal;
  font-weight: 400;
  color: var(--black);
  font-size: 14px;
  line-height: 135.5%;
  letter-spacing: -0.4px;
`;

export const priceCrEmp = css`
  font-style: normal;
  font-weight: 600;
  color: var(--black);
  font-size: 14px;
  line-height: 22px;
`;
/**********************************************/

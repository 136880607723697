import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TextField, IconButton, InputAdornment, Box } from '@mui/material';
import { CredentialResponse } from '@react-oauth/google';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OrFrame } from '@/assets/images/icon';
import { Button, Header, HelperText, GoogleLoginButton } from '@/components';
import { Pages, getPageById, signInValidationSchema } from '@/constants';
import { useAuth } from '@/hooks';
import {
  useSignInGoogleMutation,
  useSignInMutation
} from '@/redux/api/auth/authApi';
import { setDefaultAuthCookieKey, DEFAULT_COOKIE_KEY } from '@/service/api';
import { getPathInterview } from '@/util';

import { ISignInFields } from '@/types/signinFields';

import {
  MainWrapper,
  OrStroke,
  WrapperBtnLogin,
  Form,
  ForgotLink
} from './styles';

type TUpdatedSignInFields = Omit<ISignInFields, 'firstName'>;

export const SignIn: FC = () => {
  const navigate = useNavigate();

  const { token, setToken } = useAuth();

  const [signInGoogle] = useSignInGoogleMutation();
  const [signIn] = useSignInMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<TUpdatedSignInFields>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(signInValidationSchema)
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const googleLogin = async ({ credential }: CredentialResponse) => {
    if (credential) {
      signInGoogle({
        idToken: credential
      }).unwrap();
      setDefaultAuthCookieKey();
      setToken(DEFAULT_COOKIE_KEY);
      if (getPathInterview()) {
        navigate(getPageById(Pages.INTERVIEW_ID, getPathInterview() as string));
      } else {
        navigate(Pages.HOME);
      }
    }
  };

  const onSubmit = async (data: TUpdatedSignInFields) => {
    signIn(data)
      .unwrap()
      .then(() => {
        setDefaultAuthCookieKey();
        setToken(DEFAULT_COOKIE_KEY);
        if (getPathInterview()) {
          navigate(
            getPageById(Pages.INTERVIEW_ID, getPathInterview() as string)
          );
        } else {
          navigate(Pages.HOME);
        }
      })
      .catch((error) => {
        setError('email', { message: error?.data?.Message });
        setError('password', { message: error?.data?.Message });
      });
  };

  return (
    <Header
      description='New user?'
      descriptionLink='Create an Account'
      link={Pages.SIGN_UP}
      title='Sign In'
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Box mt='32px' width='100%'>
          <TextField
            autoComplete='off'
            fullWidth
            label='Email'
            type='email'
            variant='outlined'
            {...register('email')}
            error={!!errors.email}
          />
          <HelperText text={errors.email?.message} />
        </Box>

        <Box mt='32px' width='100%'>
          <TextField
            autoComplete='off'
            fullWidth
            label='Password'
            type={showPassword ? 'text' : 'password'}
            variant='outlined'
            {...register('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isPasswordFocused && (
                    <IconButton
                      aria-label='toggle password visibility'
                      edge='end'
                      onClick={(e) => {
                        e.preventDefault();
                        togglePasswordVisibility();
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            error={!!errors.password}
            onFocus={handlePasswordFocus}
          />
          <HelperText text={errors.password?.message} />
        </Box>

        <ForgotLink to={Pages.FORGOT_PASSWORD}>Forgot Password?</ForgotLink>
        <Box mt='32px' width='100%'>
          <Button fullWidth size='large' text='Sign In' type='submit' />
        </Box>
        <OrStroke>
          <OrFrame />
        </OrStroke>

        <Box mt='24px' width='100%'>
          {token ? (
            <p>
              You are already signed in as: {'Need fix' /*session.user.email*/}
            </p>
          ) : (
            <MainWrapper>
              <Button
                fullWidth
                size='large'
                text='Continue with Google'
                type='button'
                variant='google'
              />
              <WrapperBtnLogin>
                <GoogleLoginButton onSuccess={googleLogin} />
              </WrapperBtnLogin>
            </MainWrapper>
          )}
        </Box>
      </Form>
    </Header>
  );
};

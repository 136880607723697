export enum Endpoints {
  ROOT = '/',

  USER_ME = '/User/Me',

  SIGN_IN = '/Auth/SignIn',
  SIGN_UP = '/Auth/SignUp',
  SIGN_IN_GOOGLE = '/Auth/GoogleSignIn',
  SIGN_UP_GOOGLE = '/Auth/GoogleSignUp',
  RESET_PASSWORD_GET = '/Auth/ResetPassword',
  IS_EMAIL_ALREADY_REGISTERED = '/Auth/IsEmailAlreadyRegistered',
  RESET_PASSWORD_POST = '/Auth/ResetPassword',
  RESEND_EMAIL_CONFIRMATION = '/Auth/ResendEmailConfirmation',
  LOGOUT = '/Auth/LogOut',

  EMPLOYEES = '/Employee/GetList',
  GET_EMPLOYEE = '/Employee/Get',
  CREATE_EMPLOYEE = '/Employee/Create',
  PERFECT_MATCH = '/Employee/PerfectMatchSearch',
  UPDATE_EMPLOYEE = '/Employee/Update',
  REMOVE_EMPLOYEE_AVATAR = '/Employee/RemoveEmployeeAvatar',
  MATCH = '/Employee/Search',

  DICTIONARY = '/Dictionary/Get',
  DICTIONARY_TYPES = '/Dictionary/GetTypes',

  SETTINGS_BY_KEY = '/Settings/GetSettingsByKey',

  CREATE_INTERVIEW_REQUEST = '/Interview/CreateRequest',

  UPLOAD_CV = 'File/UploadCv',
  UPLOAD_IMAGE = 'File/UploadImage'
}

import styled from '@emotion/styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Checkbox from '@mui/material/Checkbox';

export const FilterWrapper = styled.div`
  width: 240px;
`;

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 600;
`;

export const MuiAccordion = styled(Accordion)`
  margin: 0 !important;
  box-shadow: none;
  padding: 15px 0;

  .MuiCollapse-wrapper {
    max-height: 300px;
    /* margin-bottom: 20px; */
    overflow-y: auto;
  }

  .Mui-expanded {
    min-height: 0 !important;
  }

  :before {
    opacity: 1 !important;
  }
`;

export const MuiAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  font-size: 16px;
  line-height: 21.6px;
  font-weight: 600;

  .Mui-expanded {
    margin: 12px 0 !important;
  }

  svg {
    transform: rotate(-90deg);
  }

  .Mui-expanded {
    svg {
      transform: rotate(-360deg);
    }
  }
`;

export const MuiAccordionDetails = styled(AccordionDetails)`
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

export const MuiCheckbox = styled(Checkbox)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export const AccordionText = styled.span``;

export const MuiExpandMoreIcon = styled(ExpandMoreIcon)``;

/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  Button,
  HelperText,
  SelectInput,
  TextInput,
  Typography
} from '@/components';
import { useEmployeeFormState } from '@/context/formContext';
import { useDictionary } from '@/hooks';
import {
  Container,
  Decsription,
  SubtitleMain,
  ButtonWrapper,
  Subtitle,
  Form
} from '@/page/employee/create/styles';
import { ThemedContainer } from '@/providers';

import { baseValidateSchema, employeeDefaultValues } from './base.config';

interface FormData {
  firstName: string;
  lastName: string;
  employeeCountries: {
    id: number;
    name: string;
  };
}

const BaseInfo = () => {
  const { onHandleNext, setEmployeeData, employeeData } =
    useEmployeeFormState();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: employeeDefaultValues,
    resolver: yupResolver(baseValidateSchema)
  });

  const { dictionaries } = useDictionary();
  const locationDictionary = dictionaries.Countries;

  const employee: any = watch('employeeCountries');

  const getValue = {
    name: employee?.name ?? '',
    id: employee?.id ?? ''
  };

  const onSubmit = (data: FormData) => {
    onHandleNext();

    setEmployeeData((prevEmployeeData: any) => ({
      ...prevEmployeeData,
      ...data
    }));
  };

  useEffect(() => {
    reset(employeeData);
  }, [employeeData, reset]);

  return (
    <ThemedContainer>
      <Container data-testid={'Base component'}>
        <Typography variant='displayMedium'>Base Info</Typography>
        <Decsription>
          Provide the foundational information for the new Employee, including
          their Name, Surname, and Location.
        </Decsription>
        <SubtitleMain>Name</SubtitleMain>
        <Decsription>
          For security reasons, your surname would be displayed only to you.
        </Decsription>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box mt='15px' width='100%'>
            <TextInput
              errors={errors}
              label='First Name'
              name='firstName'
              register={control.register}
            />
            <HelperText text={errors.firstName?.message} />
          </Box>

          <Box mt='15px' width='100%'>
            <TextInput
              errors={errors}
              label='Last Name'
              name='lastName'
              register={control.register}
            />
            <HelperText text={errors.lastName?.message} />
          </Box>

          <Subtitle>Location</Subtitle>
          <Box mt='15px' width='100%'>
            <SelectInput
              control={control}
              errors={!!errors['employeeCountries']}
              label='Select Location'
              name='employeeCountries'
              options={locationDictionary || []}
              value={getValue}
            />
            <HelperText
              text={
                errors['employeeCountries']?.name?.message ??
                errors['employeeCountries']?.message
              }
            />
          </Box>

          <ButtonWrapper>
            <Button
              size='medium'
              text='Next Step'
              type='submit'
              variant='next'
            />
          </ButtonWrapper>
        </Form>
      </Container>
    </ThemedContainer>
  );
};

export default BaseInfo;

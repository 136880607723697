import { FC } from 'react';

import { Box } from '@mui/material';

import { CandiatesError } from '@/assets/images/backgrounds';
import { Typography } from '@/components';

export const CandidatesErrorHandler: FC = () => (
  <Box
    alignItems='center'
    display='flex'
    flexDirection='column'
    gap='32px'
    mb='22px'
    width='600px'
  >
    <CandiatesError />
    <Typography textAlign='center' variant='titleMedium' width='630px'>
      Oops! Something went wrong. We encountered an issue loading the list of
      candidates.
      <p>Please, check your connection and filters, or try again later.</p>
    </Typography>
  </Box>
);

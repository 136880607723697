import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { PALETTE } from '@/util/palette';

interface SeniorityProps {
  selected: boolean;
  error?: boolean;
}

export const Seniority = styled.div<SeniorityProps>`
  height: 32px;
  display: flex;
  justify-content: center;
  background: ${({ selected }) =>
    selected ? PALETTE.purple : PALETTE.greyBlock};
  border-radius: 2px;
  text-transform: none;
  box-shadow: none;
  padding: 6px 12px;
  border: 1px solid
    ${({ selected }) => (selected ? PALETTE.purple : PALETTE.greyBlock)};
  cursor: pointer;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${PALETTE.mainWrong};
      background: ${PALETTE.greyBlock};
    `}

  &:hover,
  &:focus {
    background: ${PALETTE.purple};
    border: 1px solid ${PALETTE.purple};
    filter: brightness(1);
  }
`;

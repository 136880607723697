import styled from '@emotion/styled';
import { Box, styled as muiStyled } from '@mui/material';

import DolllarM from '@/assets/images/icon/dollar.svg';
import { Typography } from '@/components';
import { cardSCrEmp, cardTCrEmp } from '@/style/mixin';

interface CardProps {
  selected?: boolean;
}

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
`;

export const Container = styled.div`
  width: 528px;
`;

export const Decsription = styled.div`
  font-style: normal;
  font-weight: 400;
  color: #4f6a6c;
  font-size: 16px;
  line-height: 135.5%;
  margin-top: 15px;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 600;
  margin-top: 25px;
  color: #2b5659;
  font-size: 18px;
  line-height: 120%;
`;

export const SubtitleMain = styled.div`
  font-style: normal;
  font-weight: 600;
  margin-top: 35px;
  color: #2b5659;
  font-size: 18px;
  line-height: 120%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 55px;
`;

export const ButtonWrapperSB = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
`;

export const ButtonSeniority = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const HeadWrapper = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeadWrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const Name = styled.div`
  color: #2b5659;
  font-size: 18px;
  line-height: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const Location = styled.div`
  color: #2b5659;
  font-size: 16px;
  line-height: 20.8px;
  font-style: normal;
  font-weight: 400;
`;

export const CredWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Logo = styled.div`
  width: 56px;
  height: 56px;
  background-color: #e7eaf6;
  border-radius: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #2b5659;
  font-size: 24px;
  line-height: 16px;
  font-style: normal;
  font-weight: 600;
`;

export const DollarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Dollar = styled.div`
  background-image: url(${DolllarM});
  width: 20px;
  height: 20px;
  background-size: 20px;
`;
export const DollarText = styled.div`
  color: #2b5659;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: 600;
`;

export const Form = styled.form`
  width: 528px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
export const CardsWrapperLeftCard = styled.div`
  display: flex;
  flex-direction: column;
`;
export const CardsWrapperRightCard = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = muiStyled(Box)<CardProps>(({ selected }) => ({
  width: '252px',
  height: '145px',
  border: '1px solid #70A500',
  marginTop: '15px',
  padding: '20px',
  cursor: 'pointer',

  '&:focus': {
    backgroundColor: '#C8F860'
  },

  ...(selected && {
    border: '2px solid #70A500',
    backgroundColor: '#C8F860'
  })
}));

export const CardTitle = muiStyled(Typography)<CardProps>`
  ${cardTCrEmp}
  color: #2B5659;
`;

export const CardSubtitle = muiStyled(Typography)<CardProps>`
  ${cardSCrEmp}
  margin-top: 10px;
  color: ${({ selected }) => (selected ? '#4F6A6C' : '#7F8788')};
  white-space: pre-line;
`;

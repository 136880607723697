import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material/styles';

import { Typography } from '@/components';

export const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export const ImgSent = styled.p`
  margin-top: -40px;
  display: flex;
  justify-content: center;
`;

export const TitleSent = muiStyled(Typography)(({ theme }) => ({
  ...theme.typography.displayLarge,
  color: theme.palette.primaryRoot.blueDark,
  textAlign: 'center'
}));

import * as Yup from 'yup';

import { LATIN_WITH_SYMBOLS_REGEX } from '@/constants';

export const initialFormData = {
  contractDescription: '',
  intervals: []
};

export const interviewDatesValidationSchema = Yup.object().shape({
  contractDescription: Yup.string()
    .max(3000, 'Maximum values is 3000 symbols')
    .matches(LATIN_WITH_SYMBOLS_REGEX, {
      message: 'Invalid value',
      excludeEmptyString: true // disable check if an empty string
    })
    .optional(),
  intervals: Yup.array()
    .min(1, 'Field is required')
    .of(
      Yup.object().shape({
        selectedDate: Yup.date().label('Date').required('Field is required'),
        selectedTime: Yup.string().required('Field is required')
      })
    )
    .required('Fields are required')
});

/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useForm } from 'react-hook-form';

import {
  Button,
  CredWrapper,
  HelperText,
  PriceInput,
  Typography
} from '@/components';
import { useEmployeeFormState } from '@/context/formContext';
import { useDictionary } from '@/hooks';
import {
  Container,
  Decsription,
  ButtonWrapperSB,
  SubtitleMain,
  HeadWrapper,
  HeadWrapperRight,
  Name,
  Subtitle,
  Logo,
  CardsWrapper,
  CardsWrapperLeftCard,
  CardsWrapperRightCard,
  Card,
  CardTitle,
  CardSubtitle,
  Form
} from '@/page/employee/create/styles';
import { ThemedContainer } from '@/providers';

import { EFormData } from '@/types/employeeFields';

enum StatusEnum {
  Activate = 1,
  HasContract = 2
}

const Status = () => {
  const { onHandleNext, onHandleBack, setEmployeeData, employeeData } =
    useEmployeeFormState();

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<EFormData>({
    mode: 'all',
    defaultValues: employeeData
  });

  const { dictionaries } = useDictionary();
  const statusDictionary = dictionaries.EmployeeStatus;

  const [selectedStatus, setSelectedStatus] = useState<StatusEnum>(
    StatusEnum.Activate
  );

  const employeeStatusOptions = useMemo(() => {
    return {
      Activate: {
        id: statusDictionary[1]?.id,
        name: statusDictionary[1]?.name
      },
      HasContract: {
        id: statusDictionary[0]?.id,
        name: statusDictionary[0]?.name
      }
    };
  }, [statusDictionary]);

  useEffect(() => {
    setSelectedStatus(
      employeeData.employeeStatus?.id === employeeStatusOptions.HasContract?.id
        ? StatusEnum.HasContract
        : StatusEnum.Activate
    );
  }, [employeeData, employeeStatusOptions]);

  const onSubmit = (data: EFormData) => {
    const employeeStatus =
      selectedStatus === StatusEnum.Activate
        ? employeeStatusOptions.Activate
        : employeeStatusOptions.HasContract;

    setEmployeeData((prevEmployeeData: EFormData) => ({
      ...prevEmployeeData,
      ...data,
      employeeStatus
    }));
    onHandleNext();
  };

  const handleStatusClick = (status: StatusEnum) => {
    if (selectedStatus !== status) {
      setSelectedStatus(status);
    }
  };

  const isActivateSelected = selectedStatus === StatusEnum.Activate;
  const isHasContractSelected = selectedStatus === StatusEnum.HasContract;

  return (
    <ThemedContainer>
      <Container data-testid={'Status component'}>
        <Typography variant='displayMedium'>Status & Price</Typography>
        <Decsription>
          Your rate will be displayed on employees profile and in search
          results. Additionally, there are two status options: the first allows
          your employees to be visible for hiring, while the second option keeps
          them hidden.
        </Decsription>
        <HeadWrapper>
          <Logo>
            {employeeData.firstName?.charAt(0)}
            {employeeData.lastName?.charAt(0)}
          </Logo>

          <HeadWrapperRight>
            <Name>
              {employeeData.firstName} {employeeData.lastName}
            </Name>
            <CredWrapper
              mainInfo={{
                specialization: employeeData.employeeSpecializations?.name,
                seniority: employeeData.seniority?.name,
                location: employeeData.employeeCountries?.name,
                fontSize: '14px'
              }}
            />
          </HeadWrapperRight>
        </HeadWrapper>
        <SubtitleMain>Price</SubtitleMain>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box mt='15px' width='100%'>
            <PriceInput
              errors={!!errors['price']}
              label='Price, USD per Hour'
              name='price'
              register={control.register}
            />
            <HelperText text={errors.price?.message} />
          </Box>

          <Subtitle>Status</Subtitle>
          <CardsWrapper>
            <CardsWrapperLeftCard>
              <Card
                onClick={() => handleStatusClick(StatusEnum.Activate)}
                selected={isActivateSelected}
              >
                <CardTitle selected={isActivateSelected}>
                  Active search for new contract
                </CardTitle>
                <CardSubtitle selected={isActivateSelected}>
                  Your candidate will appear in the search for a client and can
                  be hired. As soon as he is hired, the status changes.
                </CardSubtitle>
              </Card>
            </CardsWrapperLeftCard>
            <CardsWrapperRightCard>
              <Card
                onClick={() => handleStatusClick(StatusEnum.HasContract)}
                selected={isHasContractSelected}
              >
                <CardTitle selected={isHasContractSelected}>
                  Has Contract
                </CardTitle>
                <CardSubtitle selected={isHasContractSelected}>
                  Your candidate will not appear in the client&#39;s search
                  results, but you can always transfer them to the bench.
                </CardSubtitle>
              </Card>
            </CardsWrapperRightCard>
          </CardsWrapper>
          <ButtonWrapperSB>
            <Button
              onClick={() => onHandleBack()}
              text='Previous Step'
              variant='prev'
            />
            <Button
              size='medium'
              text='Next Step'
              type='submit'
              variant='next'
            />
          </ButtonWrapperSB>
        </Form>
      </Container>
    </ThemedContainer>
  );
};

export default Status;

import { FC } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Header } from '@/components';

import { ChangeForm } from './changeForm';

export const ChangePassword: FC = () => {
  const [searchParams] = useSearchParams();

  return (
    <Header title='Change Password'>
      <ChangeForm token={searchParams.get('token') || ''} />
    </Header>
  );
};
